import LandingPage from '../LandingPage/LandingPage';
import Dapp from 'Views/Dapp/Dapp';
import Raffle from 'Views/Raffle/Raffle';
// import Game from "Views/Game/Game";
import ExploreNFTS from 'Views/Explore/ExploreNFTS';
import { Routes, Route } from 'react-router-dom';
import UserNFTS from 'Views/UserNFTs/UserNFTs';
import CollectionDapp from 'Views/MintCollection/CollectionDap';
// import GameMode from "Views/Game/components/GameMode";
import ExplorePage from 'Views/ExploreGrid/page/view/ExplorePage';
import ExploreP2PCards from 'Views/Explore/ExploreP2PCards';
import ExploreUnReveledP2PCards from 'Views/Reveal/ExploreUnReveledP2PCards';

const Main = () => {
  return (
    <Routes>
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/mint" element={<CollectionDapp />}></Route>
      <Route path="/dapp" element={<Dapp />}></Route>
      {/* <Route path="/dapp2" element={<Dapp2 />}></Route> */}
      <Route path="/raffle" element={<Raffle />}></Route>
      {/* <Route path="/game/home" element={<Game />}></Route>
      <Route path="/game/home/modes" element={<GameMode />}></Route>
      <Route path="/game/selection" element={<Game />}></Route>
      <Route path="/game/arena" element={<Game />}></Route> */}
      {/* <Route path="/explore" element={<ExploreNFTS />}></Route>
      <Route path="/explore2" element={<ExplorePage />}></Route>
      <Route path="/explore3" element={<ExploreP2PCards />}></Route> */}

      <Route path="/explore" element={<ExploreP2PCards />}></Route>
      <Route path="/reveal" element={<ExploreUnReveledP2PCards />}></Route>
      <Route path="/mycards" element={<UserNFTS />}></Route>
    </Routes>
  );
};

export default Main;
