import React, { useEffect, useState, useRef } from 'react';
import './cardSelection.scss';
import { GameDetails } from 'types/NFT/Battle';
import { Boost, NFT } from 'types/NFT/Minter';
import Search from 'components/Card/P2PCard/Search';
import BoosterSelection from './BoosterSelection';
import toast from 'react-hot-toast';

export interface Card {
  id: number;
  imageUrl: string;
  title: string;
  opponent: string;
  stats: {
    A: number;
    B: number;
    C: number;
  };
  rarity: string;
  tier: string;
}

interface CardSelectionProps {
  nftCards: NFT[];
  nftBoostCards: Boost[];
  gameMode: GameDetails;
  onCardSelect: (selectedCards: SelectedGameCard[]) => void;
  onBack: () => void;
}
export interface SelectedGameCard {
  card: Card;
  boosters?: number[];
  guess?: number;
}

export const mapNFT = (nfts: NFT[]): Card[] => {
  return nfts.map((nft) => {
    const tierNumber = Math.floor(nft.tier / 10) * 10; // Extracts the tier value
    const rarityNumber = nft.tier % 10; // Extracts the rarity value

    let rarity = '';
    switch (rarityNumber) {
      case 0:
        rarity = 'ULTRA RARE';
        break;
      case 1:
        rarity = 'VERY RARE';
        break;
      case 2:
        rarity = 'STANDARD RARE';
        break;
      case 3:
        rarity = 'COMMON';
        break;
      default:
        rarity = 'UNKNOWN';
    }
    const opponentAttribute =
      nft.ipfs?.attributes?.find((attr) => attr.trait_type === 'Opponent')
        ?.value || null;

    return {
      id: nft.tokenId,
      imageUrl: nft.ipfs.image,
      title: nft.ipfs?.name,
      opponent: opponentAttribute ? `${opponentAttribute}` : null,
      stats: {
        A: nft.a,
        B: nft.b,
        C: nft.c,
      },
      rarity: rarity,
      tier: `TIER ${tierNumber}`,
    };
  });
};

const CardSelection: React.FC<CardSelectionProps> = ({
  nftCards,
  nftBoostCards,
  gameMode,
  onCardSelect,
  onBack,
}) => {
  const cards = mapNFT(nftCards);

  const [currentIndex, setCurrentIndex] = useState(
    Math.floor(nftCards.length / 2),
  );
  const [selectedCards, setSelectedCards] = useState<SelectedGameCard[]>([]);
  const [currentCard, setCurrentCard] = useState<Card | null>(null);
  const [filteredCards, setFilteredCards] = useState<Card[]>(cards);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCardOptionsModal, setShowCardOptionsModal] = useState(false);
  const [boostAttribute, setBoostAttribute] = useState<number>();
  const [showBoostOptionsModal, setShowBoostOptionsModal] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const startPositionRef = useRef<number>(0);
  const currentTranslateRef = useRef<number>(0);
  const isDraggingRef = useRef<boolean>(false);

  const handleLeftScroll = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + filteredCards.length) % filteredCards.length,
    );
    // Add a slight delay to allow the transition to start
    if (containerRef.current) {
      containerRef.current.style.transition = 'transform 0.5s ease-out';
    }
  };

  const handleRightScroll = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredCards.length);
    // Add a slight delay to allow the transition to start
    if (containerRef.current) {
      containerRef.current.style.transition = 'transform 0.5s ease-out';
    }
  };

  const handleAddCard = (cardId: number) => {
    if (
      !selectedCards.find((s) => s.card.id === currentCard.id) &&
      selectedCards.length === Number(gameMode.cardsPerPlayer)
    ) {
      toast.error('Set the current cards in play or remove a Card!');
      return;
    }
    const selectedCard = filteredCards.find((x) => x.id === cardId);
    if (!selectedCard) return;

    // Set the current card and bring up the modal for confirmation
    setCurrentCard(selectedCard);
    setShowCardOptionsModal(true);
  };

  const handleRemoveCard = (cardId: number) => {
    setSelectedCards((prevSelected) => {
      const existingIndex = prevSelected.findIndex(
        (playCard) => playCard.card.id === cardId,
      );

      if (existingIndex > -1) {
        // Remove the card if it exists in the selection
        const updatedSelectedCards = [...prevSelected];
        updatedSelectedCards.splice(existingIndex, 1);

        // Deselect the current card if it was the one being shown in the modal
        if (currentCard && currentCard.id === cardId) {
          setCurrentCard(null);
          setShowCardOptionsModal(false);
        }
        return updatedSelectedCards;
      }

      return prevSelected; // No changes if the card was not found
    });
  };

  // Modify the use of `selectedCards` to access the `card` property when comparing.
  const handleConfirmSelection = () => {
    if (currentCard) {
      setSelectedCards((prevSelected) => {
        if (
          !prevSelected.find((playCard) => playCard.card.id === currentCard.id)
        ) {
          return [...prevSelected, { card: currentCard }];
        }
        return prevSelected;
      });
      setShowCardOptionsModal(false);
    }
  };

  const handleCancelSelection = () => {
    setCurrentCard(null);
    setShowCardOptionsModal(false);
  };
  const handleDragStart = (event: React.MouseEvent | React.TouchEvent) => {
    isDraggingRef.current = true;
    startPositionRef.current =
      'touches' in event ? event.touches[0].clientX : event.clientX;

    // Prevent default behavior for touch events to avoid page scrolling
    if ('touches' in event) {
      event.preventDefault();
    }
  };

  const handleDragMove = (event: React.MouseEvent | React.TouchEvent) => {
    if (!isDraggingRef.current) return;

    const currentPosition =
      'touches' in event ? event.touches[0].clientX : event.clientX;

    const delta = currentPosition - startPositionRef.current;
    currentTranslateRef.current = delta;

    // Move slider visually based on delta
    if (containerRef.current) {
      containerRef.current.style.transform = `translateX(${delta}px)`;
    }
  };

  const handleDragEnd = () => {
    isDraggingRef.current = false;

    let newIndex = currentIndex;

    // Determine if the slide should snap back or change position
    if (currentTranslateRef.current > 50) {
      // Trigger left scroll (decrease index)
      newIndex =
        (currentIndex - 1 + filteredCards.length) % filteredCards.length;
    } else if (currentTranslateRef.current < -50) {
      // Trigger right scroll (increase index)
      newIndex = (currentIndex + 1) % filteredCards.length;
    }

    // Set the new index
    setCurrentIndex(newIndex);

    // Reset the transform style
    if (containerRef.current) {
      containerRef.current.style.transform = 'translateX(0px)';
    }

    // Reset the current translation value
    currentTranslateRef.current = 0;

    // Update the current card to reflect the new card in view
    if (filteredCards.length > 0) {
      console.log(`current Card Drag:`, filteredCards[newIndex]);
      setCurrentCard(filteredCards[newIndex]);
    }
  };

  const handleAddBoost = (currentCard: Card, boost: Boost) => {
    if (!boost) {
      console.warn('Boost not provided');
      return;
    }

    setSelectedCards((prevSelectedCards) => {
      // Find the card that needs to be updated
      const cardIndex = prevSelectedCards.findIndex(
        (x) => x.card.id === currentCard.id,
      );

      if (cardIndex === -1) {
        console.warn('Card not found in selected cards');
        return prevSelectedCards;
      }

      // Create a copy of the selected card with updated boosters
      const updatedCard = { ...prevSelectedCards[cardIndex] };

      if (!updatedCard.boosters) {
        updatedCard.boosters = [Number(boost.tokenId)];
      } else if (!updatedCard.boosters.includes(Number(boost.tokenId))) {
        updatedCard.boosters = [...updatedCard.boosters, Number(boost.tokenId)];
      } else {
        console.warn('Boost already set for this card');
        return prevSelectedCards;
      }

      // Replace the updated card in the array
      const updatedSelectedCards = [...prevSelectedCards];
      updatedSelectedCards[cardIndex] = updatedCard;

      return updatedSelectedCards;
    });
  };

  const handleRemoveBoost = (currentCard: Card, boost: Boost) => {
    if (!boost) {
      console.warn('Boost not provided');
      return;
    }

    setSelectedCards((prevSelectedCards) => {
      // Find the card that needs to be updated
      const cardIndex = prevSelectedCards.findIndex(
        (x) => x.card.id === currentCard.id,
      );

      if (cardIndex === -1) {
        console.warn('Card not found in selected cards');
        return prevSelectedCards;
      }

      // Create a copy of the selected card with updated boosters
      const updatedCard = { ...prevSelectedCards[cardIndex] };

      if (
        !updatedCard.boosters ||
        !updatedCard.boosters.includes(Number(boost.tokenId))
      ) {
        console.warn('Boost not found for this card');
        return prevSelectedCards;
      }

      // Remove the boost
      updatedCard.boosters = updatedCard.boosters.filter(
        (tokenId) => tokenId !== Number(boost.tokenId),
      );

      // Replace the updated card in the array
      const updatedSelectedCards = [...prevSelectedCards];
      updatedSelectedCards[cardIndex] = updatedCard;

      return updatedSelectedCards;
    });
  };

  const handleBoostModalConfig = (
    boostAttribute: number,
    condition: string,
  ) => {
    if (!currentCard) {
      return;
    }

    // Find the current card in the selectedCards list if it has been confirmed
    const currentSelectedCard = selectedCards.find(
      (selected) => selected.card.id === currentCard.id,
    );

    console.log('current Selected', currentSelectedCard);

    // Configuration for a new card + Booster
    if (condition === '+' && !currentSelectedCard) {
      // If the card hasn't been confirmed yet, allow the addition and open the modal
      setBoostAttribute(boostAttribute);
      setShowBoostOptionsModal(true);
      return;
    }

    // Current Card not Found (when attempting to remove a booster)
    if (!currentSelectedCard) {
      toast.error('Card not found in selected cards.');
      return;
    }

    // Map token IDs in currentSelectedCard.boosters to their boost attributes
    const boosterAttributes = (currentSelectedCard.boosters ?? []).map(
      (tokenId) => {
        const booster = nftBoostCards.find(
          (boost) => boost.tokenId === tokenId,
        );
        return booster?.targetAttribute;
      },
    );

    // Handle addition or removal
    if (condition === '+') {
      // Check if maximum boosters allowed have been reached
      if (
        currentSelectedCard.boosters?.length >=
        Number(gameMode.maxBoostersPerCard)
      ) {
        toast.error('Maximum number of boosters reached for this card.');
        return;
      }

      // Show boost options modal for selecting the booster
      setBoostAttribute(boostAttribute);
      setShowBoostOptionsModal(true);
    } else if (condition === '-') {
      // Check if there is a booster of the given attribute to remove
      const hasAttributeToRemove =
        boosterAttributes.filter((attr) => attr === boostAttribute).length > 0;

      if (hasAttributeToRemove) {
        console.log('currentSelectedCard', currentSelectedCard);

        // Remove the last booster with the given attribute
        setSelectedCards((prevSelectedCards) => {
          // Create a copy of the selected card
          const updatedSelectedCard = { ...currentSelectedCard };

          // Find the index of the last occurrence of a booster with the given attribute
          const lastIndex = updatedSelectedCard.boosters
            ?.map((tokenId) => {
              const booster = nftBoostCards.find(
                (boost) => boost.tokenId === tokenId,
              );
              return booster?.targetAttribute;
            })
            .lastIndexOf(boostAttribute);

          if (lastIndex !== -1) {
            // Remove the last occurrence of the booster with the given attribute
            updatedSelectedCard.boosters = updatedSelectedCard.boosters?.filter(
              (attr, index) => index !== lastIndex,
            );
          }

          // Replace the card in the array
          const updatedSelectedCards = prevSelectedCards.map((selected) =>
            selected.card.id === currentCard.id
              ? updatedSelectedCard
              : selected,
          );

          console.log('remove last booster', updatedSelectedCard);
          return updatedSelectedCards;
        });
      } else {
        toast.error('No boosters to remove for this attribute.');
      }
    }
  };
  const handleBoosterSelection = (boosters: Boost[]) => {
    if (currentCard && boosters.length > 0) {
      const selectedBoostTokenIds = boosters.map((boost) =>
        Number(boost.tokenId),
      );
      setSelectedCards((prevSelectedCards) => {
        // Find the current card in the selected cards array
        const cardIndex = prevSelectedCards.findIndex(
          (selected) => selected.card.id === currentCard.id,
        );

        if (cardIndex === -1) {
          console.log('New card');
          // If card is not found in the selection, add it with the boosters
          return [
            ...prevSelectedCards,
            { card: currentCard, boosters: selectedBoostTokenIds },
          ];
        }
        console.log('updating card');

        // Update the existing card's boosters
        const updatedSelectedCards = [...prevSelectedCards];
        updatedSelectedCards[cardIndex] = {
          ...updatedSelectedCards[cardIndex],
          boosters: [
            ...(updatedSelectedCards[cardIndex].boosters || []),
            ...selectedBoostTokenIds,
          ],
        };

        return updatedSelectedCards;
      });
    }

    // Close the booster modal after selection
    setShowBoostOptionsModal(false);
  };
  const handleAddGuessSelection = (card: Card, guess: number) => {
    setSelectedCards((prevSelected) => {
      const existingCardIndex = prevSelected.findIndex(
        (selected) => selected.card.id === card.id,
      );

      if (existingCardIndex === -1) {
        // Add new card with the guess value
        return [...prevSelected, { card, guess }];
      } else {
        // Update existing card's guess value
        const updatedSelectedCards = [...prevSelected];
        updatedSelectedCards[existingCardIndex] = {
          ...updatedSelectedCards[existingCardIndex],
          guess,
        };
        return updatedSelectedCards;
      }
    });
  };
  const handlePlayNow = () => {
    console.log('Play Now with the selected options!', selectedCards);
    onCardSelect(selectedCards);
    // Trigger the appropriate action with the selected nftCards and options
  };

  useEffect(() => {
    // Set current index to a valid position in the filtered list
    if (filteredCards.length > 0) {
      setCurrentIndex(Math.min(currentIndex, filteredCards.length - 1));
    } else {
      setCurrentIndex(0); // Set to 0 if no cards are available
    }
  }, [nftCards, filteredCards]);

  return (
    <div id="card_selection">
      {/* Header Section with Back and Burger Button */}
      {!showBoostOptionsModal && (
        <div className="slider-title">
          <button className="back-button" onClick={onBack}>
            <i className="fa fa-angle-left"></i> Back
          </button>
          {!showCardOptionsModal && (
            <button
              className="burger-button"
              onClick={() => setShowFilterModal(!showFilterModal)}
            >
              <i className="fa fa-bars"></i>
            </button>
          )}
        </div>
      )}

      {/* Filter Modal */}
      {showFilterModal && (
        <div className="slider-title">
          <div className="filter-modal">
            <div className="filter-modal-content">
              {/* <button
                className="close-button"
                onClick={() => setShowFilterModal(false)}
              >
                X
              </button>
              <div className="filter-section">
                <label>
                  Rarity:
                  <select
                    value={filters.rarity || ''}
                    onChange={(e) =>
                      handleFilterChange('rarity', e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="ULTRA RARE">Ultra Rare</option>
                    <option value="VERY RARE">Very Rare</option>
                    <option value="STANDARD RARE">Standard Rare</option>
                    <option value="COMMON">Common</option>
                  </select>
                </label>

                <label>
                  Tier:
                  <select
                    value={filters.tier || ''}
                    onChange={(e) => handleFilterChange('tier', e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="TIER 10">Tier 10</option>
                    <option value="TIER 20">Tier 20</option>
                    <option value="TIER 30">Tier 30</option>
                    <option value="TIER 40">Tier 40</option>
                  </select>
                </label>
              </div> */}
              <Search
                nfts={nftCards}
                onNFTFiltered={(filteredNFTs) =>
                  setFilteredCards(mapNFT(filteredNFTs as NFT[]))
                }
              />
            </div>
          </div>
        </div>
      )}

      {/* Cards Options Modal */}
      {showCardOptionsModal && currentCard && !showBoostOptionsModal && (
        <div className="confirmation-modal">
          <div className="image-name-container">
            <div className="name-container">
              <div className="fighter-name name">{currentCard.title}</div>
              {gameMode.canUseBoost && (
                <div className="boost-section">
                  <div className="fighter-name">Add Boosters</div>
                  <div className="stats-table">
                    <table>
                      <tbody>
                        <tr>
                          <td>Win/Loss %</td>
                          <td>{currentCard.stats.A}</td>
                          <td>
                            {nftBoostCards.length > 0 ? (
                              <>
                                {' '}
                                <button
                                  onClick={() => handleBoostModalConfig(0, '-')}
                                >
                                  -
                                </button>
                                <span>
                                  {selectedCards
                                    .find(
                                      (item) =>
                                        item.card.id === currentCard?.id,
                                    ) // Find the current card in selected cards
                                    ?.boosters?.filter((tokenId) => {
                                      // Find the boost in the nftBoostCards that matches the tokenId
                                      const booster = nftBoostCards.find(
                                        (x) => x.tokenId === tokenId,
                                      );

                                      // Ensure the booster exists and matches the attribute type we care about
                                      return (
                                        booster &&
                                        Number(booster.targetAttribute) === 0
                                      );
                                    }).length ?? 0}
                                </span>
                                <button
                                  onClick={() => handleBoostModalConfig(0, '+')}
                                >
                                  +
                                </button>
                              </>
                            ) : (
                              <div>N/A</div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Champion Ships</td>
                          <td>{currentCard.stats.B}</td>
                          <td>
                            {nftBoostCards.length > 0 ? (
                              <>
                                {' '}
                                <button
                                  onClick={() => handleBoostModalConfig(1, '-')}
                                >
                                  -
                                </button>
                                <span>
                                  {selectedCards
                                    .find(
                                      (item) => item.card.id === currentCard.id,
                                    )
                                    ?.boosters?.filter(
                                      (tokenId) =>
                                        nftBoostCards.find(
                                          (x) => x.tokenId === tokenId,
                                        ).targetAttribute === 1,
                                    ).length ?? 0}
                                </span>
                                <button
                                  onClick={() => handleBoostModalConfig(1, '+')}
                                >
                                  +
                                </button>
                              </>
                            ) : (
                              <div>N/A</div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Win Streak</td>
                          <td>{currentCard.stats.C}</td>
                          <td>
                            {nftBoostCards.length > 0 ? (
                              <>
                                {' '}
                                <button
                                  onClick={() => handleBoostModalConfig(2, '-')}
                                >
                                  -
                                </button>
                                <span>
                                  {selectedCards
                                    .find(
                                      (item) =>
                                        item.card.id === currentCard?.id,
                                    ) // Find the current card in selected cards
                                    ?.boosters?.filter((tokenId) => {
                                      // Find the boost in the nftBoostCards that matches the tokenId
                                      const booster = nftBoostCards.find(
                                        (x) => x.tokenId === tokenId,
                                      );

                                      // Ensure the booster exists and matches the attribute type we care about
                                      return (
                                        booster &&
                                        Number(booster.targetAttribute) === 2
                                      );
                                    }).length ?? 0}{' '}
                                </span>
                                <button
                                  onClick={() => handleBoostModalConfig(2, '+')}
                                >
                                  +
                                </button>
                              </>
                            ) : (
                              <div>N/A</div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Random</td>
                          <td>
                            <i className="fa-solid fa-shuffle"></i>
                          </td>
                          <td>
                            {nftBoostCards.length > 0 ? (
                              <>
                                {' '}
                                <button
                                  onClick={() => handleBoostModalConfig(3, '-')}
                                >
                                  -
                                </button>
                                <span>
                                  {selectedCards
                                    .find(
                                      (item) => item.card.id === currentCard.id,
                                    )
                                    ?.boosters?.filter(
                                      (tokenId) =>
                                        nftBoostCards.find(
                                          (x) => x.tokenId === tokenId,
                                        ).targetAttribute === 3,
                                    ).length ?? 0}
                                </span>
                                <button
                                  onClick={() => handleBoostModalConfig(3, '+')}
                                >
                                  +
                                </button>
                              </>
                            ) : (
                              <div>N/A</div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {gameMode.attributeGuessOption && (
                <div className="guess-section">
                  <div className="fighter-name">Guess The Stat</div>
                  <div className="stats-table select">
                    <select
                      onChange={(e) =>
                        handleAddGuessSelection(
                          currentCard,
                          parseInt(e.target.value, 10),
                        )
                      }
                      value={
                        selectedCards.find((s) => s.card.id === currentCard?.id)
                          ?.guess ?? 0
                      }
                    >
                      <option value={0}>Win/Loss %</option>
                      <option value={1}>Championships</option>
                      <option value={2}>Win Streak</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card-footer">
            <button
              className="rarity ULTRA RARE"
              onClick={handleCancelSelection}
            >
              Cancel
            </button>
            <button className="tier TIER1" onClick={handleConfirmSelection}>
              Confirm
            </button>
          </div>
        </div>
      )}

      {/* Boost Modal Section */}
      {showBoostOptionsModal && (
        <BoosterSelection
          nftBoostCards={nftBoostCards.filter((x) => {
            // Check if the boost has the targetAttribute we're interested in
            const isMatchingAttribute = x.targetAttribute === boostAttribute;

            // Check if this token has not already been assigned to the currentCard
            const currentSelectedCard = selectedCards.find(
              (s) => s.card.id === currentCard?.id,
            );

            const isNotAssigned = !currentSelectedCard?.boosters?.includes(
              Number(x.tokenId),
            );

            // Return true only if both conditions are satisfied
            return isMatchingAttribute && isNotAssigned;
          })}
          gameMode={gameMode}
          onBoostSelect={handleBoosterSelection}
          selected={
            selectedCards.find((s) => s.card.id === currentCard.id)?.boosters
              ?.length ?? 0
          }
          onBack={() => setShowBoostOptionsModal(false)}
        />
      )}

      {/* Carousel Container */}
      {!showCardOptionsModal && (
        <div className="slider-container">
          <div className="left-arrow" onClick={handleLeftScroll}>
            <i className="fa fa-angle-left" style={{ fontSize: '24px' }}></i>
          </div>
          <div
            className="slider-content"
            ref={containerRef}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            onMouseMove={handleDragMove}
            onTouchMove={handleDragMove}
            onMouseUp={handleDragEnd}
            onTouchEnd={handleDragEnd}
          >
            {filteredCards.map((card, index) => (
              <div
                key={card.id}
                className={`slide ${selectedCards.some((selected) => selected.card.id === card.id) ? 'selected-card' : ''} ${
                  currentIndex === index
                    ? 'position-3'
                    : currentIndex === (index + 1) % filteredCards.length
                      ? 'position-4'
                      : currentIndex ===
                          (index - 1 + filteredCards.length) %
                            filteredCards.length
                        ? 'position-2'
                        : 'position-none'
                }`}
              >
                <div
                  className="card-container"
                  onClick={() => handleAddCard(card.id)}
                >
                  <div className={`selection_card`}>
                    <div className="card-front">
                      <div className="image-name-container">
                        <div className="image-container">
                          <img src={card.imageUrl} alt={card.title} />
                        </div>
                        <div className="name-container">
                          <div className="fighter-name">{card.title}</div>
                          <div className="vs">VS</div>
                          <div className="opponent-name">{card.opponent}</div>
                        </div>
                      </div>
                      <div className="stats-table">
                        <table>
                          <tbody>
                            <tr>
                              <td>Win/Loss %</td>
                              <td>{card.stats.A}%</td>
                            </tr>
                            <tr>
                              <td>Championships</td>
                              <td>{card.stats.B}</td>
                            </tr>
                            <tr>
                              <td>Win Streak</td>
                              <td>{card.stats.C}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="card-footer">
                        <div className={`rarity ${card.rarity}`}>
                          {card.rarity}
                        </div>
                        <div
                          className={`tier TIER${card.tier.replace(/\D/g, '')[0]}`}
                        >
                          {card.tier}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="right-arrow" onClick={handleRightScroll}>
            <i className="fa fa-angle-right" style={{ fontSize: '24px' }}></i>
          </div>
        </div>
      )}

      {/* Footer Selection */}
      {!showBoostOptionsModal && (
        <div className="choose-card-container">
          <section>Selected: {selectedCards.length}</section>
          {/* "Remove" Button to remove the current card */}
          {currentCard &&
            selectedCards.some((x) => x.card.id === currentCard.id) && (
              <button
                id="remove-card-button"
                onClick={() => handleRemoveCard(currentCard.id)}
              >
                Remove
              </button>
            )}
          <button
            id="choose-card-button"
            disabled={selectedCards.length !== Number(gameMode.cardsPerPlayer)}
            onClick={() => {
              handlePlayNow();
            }}
          >
            play
          </button>
        </div>
      )}
    </div>
  );
};

export default CardSelection;
