import React, { useState, useEffect, useRef, useCallback } from 'react';
import './exploreUnReveledP2PCards.scss';
import { Card, mapNFT } from 'Views/Dapp/Components/Game/CardSelection';
import { MinterException, MinterHelper } from 'Helpers/MinterContractHelper';
import P2PCard from 'components/Card/P2PCard/P2PCard';
import { throttle } from 'lodash';
import Search from 'components/Card/P2PCard/Search';
import { Boost, NFT } from 'types/NFT/Minter';
import toast from 'react-hot-toast';
import { NFTS } from 'components/Card/P2PCard/NFTs';

const ExploreUnReveledP2PCards: React.FC = () => {
  // const [cards, setCards] = useState<Card[]>([]);
  const [filteredCards, setFilteredCards] = useState<Card[]>([]);
  const [nfts, setNFTs] = useState<NFT[]>([]);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [isRevealing, setIsRevealing] = useState(false);
  const [canReveal, setCanReveal] = useState(false);
  const [showCards, setShowCards] = useState(true);
  const [showBoosters, setShowBoosters] = useState(true);

  const previousPositionRef = useRef({ x: 0, y: 0 });

  const modalRef = useRef<HTMLDivElement | null>(null);
  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const rotatableCardRef = useRef<HTMLDivElement | null>(null);

  const toggleCardsVisibility = () => setShowCards((prev) => !prev);
  const toggleBoostersVisibility = () => setShowBoosters((prev) => !prev);

  const toggleHighlight = (event: React.MouseEvent<HTMLTableCellElement>) => {
    const target = event.currentTarget;
    target.classList.toggle('highlighted');
  };

  const openModal = (card: Card) => {
    setSelectedCard(card);
    console.log('Selected:', card);
    if (modalRef.current) {
      modalRef.current.style.display = 'block';
    }
    lockScroll(); // Prevent background scroll
  };

  const closeModal = () => {
    setSelectedCard(null);
    if (modalRef.current) {
      modalRef.current.style.display = 'none';
    }
    unlockScroll(); // Restore background scroll
  };

  const Reveal = async () => {
    if (!selectedCard) return;
    //animation spin the card
    setIsRevealing(true);
    const contract = new MinterHelper();

    const revealed = await contract.reveal(selectedCard.id);
    if (revealed) {
      //fecth the new card
      const newNFT: NFT = await contract.getUserToken(selectedCard.id);

      //remove current nft from list
      setNFTs(nfts.filter((n) => n.tokenId !== selectedCard.id));

      setTimeout(() => {
        //remove current nft from list
        setNFTs(nfts.filter((n) => n.tokenId !== selectedCard.id));
        //Map new nft to selectedCard
        const newCard = mapNFT([newNFT])[0];
        setSelectedCard(newCard);
        setIsRevealing(false);
      }, 3000);
    } else {
      setIsRevealing(false);
    }
  };
  const lockScroll = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    cardContainerRef.current.style.overflowY = 'hidden';
  };

  const unlockScroll = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.overflow = 'auto';
    cardContainerRef.current.style.overflowY = 'auto';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  const handleFilter = (nfts: (NFT | Boost)[]) => {
    setFilteredCards(mapNFT(nfts as NFT[]));
  };

  const startDrag = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setIsDragging(true);
    const clientX =
      'touches' in event ? event.touches[0].clientX : event.clientX;
    const clientY =
      'touches' in event ? event.touches[0].clientY : event.clientY;
    //setPreviousPosition({ x: clientX, y: clientY });
    previousPositionRef.current = { x: clientX, y: clientY };
  };

  const handleDragging = (event: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;

    requestAnimationFrame(() => {
      const clientX =
        'touches' in event ? event.touches[0].clientX : event.clientX;
      const clientY =
        'touches' in event ? event.touches[0].clientY : event.clientY;

      const deltaX = clientX - previousPositionRef.current.x;
      const deltaY = clientY - previousPositionRef.current.y;

      setRotation((prevRotation) => ({
        x: prevRotation.x - deltaY * 0.3,
        y: prevRotation.y + deltaX * 0.3,
      }));

      previousPositionRef.current = { x: clientX, y: clientY };
    });
  };

  const endDrag = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    // Mock data to populate cards
    (async () => {
      setLoading(true);

      try {
        const contract = new MinterHelper();

        //set can reveal
        // setCanReveal(await contract.isRevealOpen());
        setCanReveal(true);
        const nfts = await contract.getMyUnRevealedTokens();

        if (nfts && nfts.length > 0) {
          setNFTs(nfts);
          const parsedCards = mapNFT(nfts);
          // setCards(parsedCards);
          setFilteredCards(parsedCards);
        }
      } catch (error) {
        if (error instanceof MinterException) {
          toast.error(`${error.message}`);
        } else {
          console.log('Error:', error);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const parsedCards = mapNFT(nfts);
    setFilteredCards(parsedCards);
  }, [nfts]);

  // Use effect for rotating animation during reveal
  useEffect(() => {
    let animationFrameId: number;

    if (isRevealing) {
      const rotate = () => {
        setRotation((prevRotation) => ({
          x: prevRotation.x + 1,
          y: prevRotation.y + 2, // Adjust rotation speed as desired
        }));
        animationFrameId = requestAnimationFrame(rotate);
      };
      rotate();
    } else {
      cancelAnimationFrame(animationFrameId);
      setRotation({ x: 0, y: 0 });
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isRevealing]);

  return (
    <div id="ExploreUnReveledP2PCards">
      {loading ? (
        <div className="loader">
          <i className="fas fa-spinner fa-spin"></i> Loading NFTs...
        </div>
      ) : (
        <>
          {/* Search Bar */}
          <Search nfts={nfts} onNFTFiltered={handleFilter} />
          {/* Toggle Visibility Buttons */}
          <div className="visibility-toggle-buttons">
            <button onClick={toggleCardsVisibility} className="toggle-button">
              {showCards ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
              <span>Cards</span>
              {/* Count Badge */}
              <span className="item-count">{filteredCards.length}</span>
            </button>
            <button
              onClick={toggleBoostersVisibility}
              className="toggle-button"
            >
              {showBoosters ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
              Reveal
            </button>
          </div>
          <div className="perspective-container" ref={cardContainerRef}>
            {/* Conditionally Render Card Collection */}
            {showCards && (
              <div className="card-collection">
                {filteredCards.map((card) => (
                  <div
                    key={card.id}
                    className="card-container"
                    onClick={() => openModal(card)}
                  >
                    <P2PCard card={card} />
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Modal for Full Screen Card Display */}
          <div id="modal" ref={modalRef} onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <input
                className="close"
                type="button"
                value="Exit"
                onClick={closeModal}
                disabled={isRevealing}
              />

              {selectedCard && (
                <div
                  className="rotatable-card-container P2PCard"
                  onMouseDown={startDrag}
                  onMouseMove={handleDragging}
                  onMouseUp={endDrag}
                  onMouseLeave={endDrag}
                  onTouchStart={startDrag}
                  onTouchMove={handleDragging}
                  onTouchEnd={endDrag}
                >
                  <div
                    className="rotatable-card"
                    ref={rotatableCardRef}
                    style={{
                      transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
                    }}
                  >
                    <div
                      className={`p2pcard TIER${selectedCard.tier.replace(/\D/g, '')[0]}`}
                    >
                      {/* Front Side of Card */}
                      <div className="card-face card-front">
                        <div className="image-name-container">
                          <div className="image-container">
                            <img
                              src={selectedCard.imageUrl}
                              alt="Fighter Image"
                            />
                          </div>
                          <div className="name-container">
                            {(!selectedCard.opponent || !selectedCard.title) &&
                              !isRevealing && (
                                <>
                                  <input
                                    className="close"
                                    type="button"
                                    value={
                                      canReveal ? 'Reveal' : 'Reveal Paused'
                                    }
                                    onClick={Reveal}
                                    disabled={!canReveal}
                                  />
                                </>
                              )}
                            {selectedCard.title && (
                              <>
                                <div className="fighter-name">
                                  {selectedCard.title}
                                </div>
                                {selectedCard.opponent && (
                                  <>
                                    {' '}
                                    <div className="vs">VS</div>
                                    <div className="opponent-name">
                                      {selectedCard.opponent}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="stats-table">
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  className="stat-box"
                                  onClick={toggleHighlight}
                                >
                                  Win/Loss %
                                </td>
                                <td
                                  className="stat-box"
                                  onClick={toggleHighlight}
                                >
                                  {selectedCard.stats.A}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="stat-box"
                                  onClick={toggleHighlight}
                                >
                                  Championships
                                </td>
                                <td
                                  className="stat-box"
                                  onClick={toggleHighlight}
                                >
                                  {selectedCard.stats.B}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="stat-box"
                                  onClick={toggleHighlight}
                                >
                                  Win Streak
                                </td>
                                <td
                                  className="stat-box"
                                  onClick={toggleHighlight}
                                >
                                  {selectedCard.stats.C}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="card-footer">
                          <div className={`rarity ${selectedCard.rarity}`}>
                            {selectedCard.rarity}
                          </div>
                          <div
                            className={`tier ${selectedCard.tier.replace(/\s/g, '')}`}
                          >
                            {selectedCard.tier}
                          </div>
                        </div>
                      </div>
                      {/* Back Side of Card */}
                      <div className="card-face card-back">
                        <div className="back-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExploreUnReveledP2PCards;
