import React from 'react';
import './p2pBooster.scss';
import { Boost } from 'types/NFT/Minter';

interface P2PBoosterProps {
  booster: Boost;
  selectedAmount?: number;
  isSelected: boolean;
  availableQuantity: number;
  onClick: () => void;
}

export const getBoostType = (attribute: number) => {
  switch (attribute) {
    case 0:
      return 'win_loss';
    case 1:
      return 'championships';
    case 2:
      return 'win_streak';
    case 3:
      return 'random';
    default:
      return '';
  }
};

const P2PBooster: React.FC<P2PBoosterProps> = ({
  booster,
  selectedAmount,
  isSelected,
  availableQuantity,
  onClick,
}) => {
  const boostType = getBoostType(Number(booster.targetAttribute));

  return (
    <div
      key={booster.tokenId}
      className={`booster-card ${isSelected ? 'selected-booster' : ''}`}
      data-booster={boostType}
      data-quantity={availableQuantity}
      onClick={onClick}
    >
      {selectedAmount > 0 && (
        <span className="booster-selected-quantity">
          {selectedAmount}
        </span>
      )}

      <span className="booster-quantity">{availableQuantity}</span>
      <div className="booster-icon">
        {boostType === 'win_loss' && <i className="fas fa-chart-line"></i>}
        {boostType === 'championships' && <i className="fas fa-trophy"></i>}
        {boostType === 'win_streak' && <i className="fas fa-fire"></i>}
        {boostType === 'random' && <i className="fas fa-random"></i>}
      </div>
      <div className="booster-name">
        {boostType === 'win_loss' && 'Win/Loss Booster'}
        {boostType === 'championships' && 'Championships Booster'}
        {boostType === 'win_streak' && 'Win Streak Booster'}
        {boostType === 'random' && 'Random Booster'}
      </div>
      <div className="booster-description">
        {boostType === 'win_loss' &&
          `Adds ${booster.boostPoint}% to the current Win/Loss stat.`}
        {boostType === 'championships' &&
          `Adds ${booster.boostPoint} championships to the current Championships stat.`}
        {boostType === 'win_streak' &&
          `Adds ${booster.boostPoint} wins to the current Win Streak stat.`}
        {boostType === 'random' &&
          `Boosts any 3 core stats by ${booster.boostPoint} randomly.`}
      </div>
    </div>
  );
};

export default P2PBooster;
