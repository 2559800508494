import React, { useState, useEffect } from 'react';
import './packagesCart.scss'; // Include your CSS styles here
import { PackDetail } from './Packages';

export type PackagesProps = {
  packDetails: PackDetail[];
  onPurchase: (packs: PackDetail[]) => Promise<void>;
  onUpdateCart: (cartItems?: PackDetail[]) => void;
};
const PackagesCart = ({
  packDetails,
  onPurchase,
  onUpdateCart,
}: PackagesProps) => {
  const [cart, setCart] = useState<PackDetail[]>(packDetails);
  const [products, setProducts] = useState<PackDetail[]>();
  const [isCartVisible, setCartVisible] = useState(false);

  const updateCart = (pack: PackDetail) => {
    const existingProduct = cart.find((item) => item.name === pack.name);
    let updatedCart;

    if (existingProduct) {
      updatedCart = cart.map((item) =>
        item.name === pack.name ? { ...item, qty: item.qty + 1 } : item,
      );
    } else {
      updatedCart = [...cart, { ...pack, qty: 1 }];
    }

    setCart(updatedCart);
    onUpdateCart(updatedCart); // Notify the parent component about the cart update
    setCartVisible(true);
  };

  const removeFromCart = (product: PackDetail) => {
    const updatedCart = cart.filter((item) => item.name !== product.name);

    // Notify the parent component about the cart update and include the removed item with qty: 0
    onUpdateCart([...updatedCart, { ...product, qty: 0 }]);

    // Update the local cart state
    setCart(updatedCart);
  };

  const updateCartQty = (product: PackDetail, qty: number) => {
    const updatedCart = cart.map((item) =>
      item.name === product.name ? { ...item, qty } : item,
    );
    setCart(updatedCart);
    onUpdateCart(updatedCart); // Notify the parent component about the cart update
  };

  const clearCart = () => {
    // Create a new array where each item's quantity is set to 0
    const updatedCart = cart.map((item) => ({
      ...item,
      qty: 0,
    }));

    // Notify the parent component about the cart update with qty set to 0
    onUpdateCart(updatedCart);

    // Clear the local cart state
    setCart([]);
  };

  const totalCost = cart.reduce(
    (total, item) => total + item.price * item.qty,
    0,
  );

  useEffect(() => {
    setProducts(packDetails);
  }, [packDetails]);
  return (
    <div className="app">
      <div className="banner">
        <div className="main-cart">
          {products &&
            products.map((product, index) => (
              <Product key={index} product={product} onPurchase={onPurchase} />
            ))}

          {!products ||
            !packDetails ||
            (packDetails.length == 0 && (
              <div className="message">Nothing to see here folks</div>
            ))}
        </div>

        <div className="nav">
          <button onClick={() => setCartVisible(!isCartVisible)}>
            <i
              className="fas fa-shopping-cart"
              style={{ fontSize: '2rem' }}
            ></i>
          </button>
          <span className="total-qty">{cart.length}</span>
        </div>

        {isCartVisible && (
          <Cart
            cart={cart}
            onPurchase={onPurchase}
            onRemoveFromCart={removeFromCart}
            onUpdateCartQty={updateCartQty}
            onClearCart={clearCart}
            onCloseCart={() => setCartVisible(false)}
            totalCost={totalCost}
          />
        )}
      </div>
    </div>
  );
};

type ProductProps = {
  product: PackDetail;
  onPurchase: (packs: PackDetail[]) => Promise<void>;
};
const Product = ({ product, onPurchase }: ProductProps) => {
  return (
    <div className="dapp_card">
      <div className="top-bar">
        <i className="fab fa-apple"></i>
        <em className="stocks">{`x${product.qty}`}</em>
      </div>
      <div className="img-container">
        <img
          className="product-img"
          src={product.imageUrl}
          alt={product.name}
        />
      </div>
      <div className="details">
        <div className="name-fav">
          <strong className="product-name">{product.name}</strong>
          <button className="heart">
            <i className="fas fa-heart"></i>
          </button>
        </div>
        <div className="wrapper">
          <h5>{product.heading}</h5>
          <p>{product.description}</p>
        </div>
        <div className="purchase">
          <p className="product-price">
            <i className="fab fa-ethereum"></i> {product.fee}
          </p>
          {product.qty > 0 ? (
            <button
              className="add-btn"
              onClick={async () => await onPurchase([product])}
            >
              Add <i className="fas fa-chevron-right"></i>
            </button>
          ) : (
            <span>Out of Stock</span>
          )}
        </div>
      </div>
    </div>
  );
};

type CartProps = {
  cart: PackDetail[];
  onPurchase: (packs: PackDetail[]) => Promise<void>;
  onRemoveFromCart: (pack: PackDetail) => void;
  onUpdateCartQty: (pack: PackDetail, qty: number) => void;
  onClearCart: () => void;
  onCloseCart: () => void;
  totalCost: number;
};
const Cart = ({
  cart,
  onPurchase,
  onRemoveFromCart,
  onUpdateCartQty,
  onClearCart,
  onCloseCart,
  totalCost,
}: CartProps) => {
  return (
    <div className="cart">
      <div className="side-nav" style={{ right: '0px' }}>
        <button onClick={() => onCloseCart()}>
          <i className="fas fa-times"></i>
        </button>
        <h2>Cart</h2>
        {cart.length > 0 ? (
          <div className="cart-items">
            {cart.map((item, index) => (
              <div className="cart-item">
                <div className="cart-img">
                  <img src={item.imageUrl} alt={item.name} />
                </div>
                <strong className="name">{item.name}</strong>
                <span className="qty-change">
                  <div>
                    <button
                      className="btn-qty"
                      onClick={() => onUpdateCartQty(item, item.qty - 1)}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>
                    <p className="qty">{item.qty}</p>
                    <button
                      className="btn-qty"
                      onClick={() => onUpdateCartQty(item, item.qty + 1)}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </span>
                {/* eth symbol or local price? */}
                <p className="price">
                  <i className="fab fa-ethereum"></i> {item.fee * item.qty}
                </p>
                <button onClick={() => onRemoveFromCart(item)}>
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            ))}
            <div className="final">
              <strong>
                Total: <i className="fab fa-ethereum"></i>
                <span className="total">
                  {cart.reduce(
                    (acc, pack) => acc + (pack.fee || 0) * pack.qty,
                    0,
                  )}
                </span>
              </strong>
              <div className="action">
                <button
                  onClick={async () => await onPurchase(cart)}
                  className="btn buy"
                >
                  Purchase{' '}
                  <i
                    className="fas fa-credit-card"
                    style={{ color: '#6665dd' }}
                  ></i>
                </button>
                <button onClick={() => onClearCart()} className="btn clear">
                  Clear Cart{' '}
                  <i className="fas fa-trash" style={{ color: '#bb342f' }}></i>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="empty-cart">Your cart is empty</div>
        )}
      </div>
    </div>
  );
};

export default PackagesCart;
