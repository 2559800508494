import { Contract, ethers, BigNumberish } from 'ethers';

export const ContractAddress = '0xd9145CCE52D386f254917e481eB44e9943F39138';

export interface NFT {
  tokenId?: number;
  ipfs: MetaDataInfo1;
  tier: number;
  a: number;
  b: number;
  c: number;
  collection: number;
  disintegrate?: boolean;
  vortex?: boolean; 
}

export type MetaDataInfo1 = {
  name: string;
  description?: string;
  image: string;
  external_url?: string;
  attributes?: Attributes[];
};

interface Attributes {
  trait_type: string;
  value: MetaDataAttributeValueProps;
}

export type MetaDataAttributeValueProps = string | number | boolean | null;

export interface NFTData {
  tokenId?: number;
  uriHash: string;
  tier: number;
  attributeA: number;
  attributeB: number;
  attributeC: number;
  collection: number;
  available?: boolean;
}

export interface Pack {
  packId: number;
  tiers: number[];
  defaultUriHash: string;
  fee: number;
}

export interface NFTAttributes {
  tier: number;
  attributeA: number;
  attributeB: number;
  attributeC: number;
  collectionType: number;
}

export interface Boost {
  tokenId: number;
  targetAttribute: number;
  boostPoint: number;
}

export type ISmartContract = Contract &{
  // Public Variables as Functions
  _numberOfPacks(): Promise<number>;
  maxMintAmount(): Promise<number>;
  nftPerAddressLimit(): Promise<number>;
  paused(): Promise<boolean>;
  onlyWhitelisted(): Promise<boolean>;
  whitelistedAddresses(index: number): Promise<string>;
  addressMintedBalance(address: string): Promise<number>;
  defaultUriHash(packId: number): Promise<string>;
  numberOfPurchases(address: string): Promise<number>;

  // Public Functions
  _getPackPurchaseNftIds(_purchaseIndex: number): Promise<number[]>;
  _getPack(_packType: number): Promise<Pack>;

  setPackFee(_packId: number, _newFeeInEther: number): Promise<void>;
  _assignAvaialableTier(
    _tierId: number,
    _Ids: number[],
    _attributes: number[][],
  ): Promise<void>;
  unsetTier(_tierId: number): Promise<void>;
  _assignPoolDistributions(poolIds: number[], tokenIds: number[][]): Promise<void>;
  _assignPoolDistribution(_poolId: number, _tokenIds: number[]): Promise<void>;

  setBoostToken(
    tokenId: number,
    boostAttribute: number,
    boostPoint: number,
  ): Promise<void>;
  setBoostTokens(
    tokenIds: number[],
    boostAttributes: number[],
    boostPoints: number[],
  ): Promise<void>;
  removeBoostTokens(tokenIds: number[]): Promise<void>;
  removeBoostToken(tokenId: number): Promise<void>;
  _assignCurrentCollection(_currentCollection: number): Promise<void>;

  getAvailableTokensPerTier(_tier: number): Promise<number>;

  _setNumberOfPacks(_packCount: number): Promise<void>;
  _setPack(
    _packId: number,
    _fee: number | BigNumberish,
    _tiers: number[],
    _defaultUriHash: string,
  ): Promise<void>;

  mint(
    _mintAmount: number,
    _packType: number,
    overrides?: ethers.Overrides, // Updated to include PayableOverrides
  ): Promise<ethers.ContractTransaction>;

  bulkMint(
    _packTypes: number[],
    _mintAmounts: number[],
    overrides?: ethers.Overrides, // Updated to include PayableOverrides
  ): Promise<ethers.ContractTransaction>;

  assignAttributes(tokenId: number): Promise<void>;

  isWhitelisted(_user: string): Promise<boolean>;

  tokenURI(tokenId: number): Promise<string>;

  reveal(tokenId: number): Promise<void>;

  setmaxMintAmount(_newmaxMintAmount: number): Promise<void>;
  setBaseURI(_newBaseURI: string): Promise<void>;
  setBaseExtension(_newBaseExtension: string): Promise<void>;
  pause(_state: boolean): Promise<void>;
  setOnlyWhitelisted(_state: boolean): Promise<void>;
  whitelistUsers(_users: string[]): Promise<void>;

  withdraw(): Promise<void>;

  // Inherited from BaseContract721Enumerable
  name(): Promise<string>;
  symbol(): Promise<string>;
  raffle(): Promise<string>;
  game(): Promise<string>;
  walletOfOwner(_owner: string): Promise<number[]>;
  getNFTAttributes(tokenId: number): Promise<NFTAttributes>;
  getBooster(tokenId: number): Promise<Boost>;
  getAvailableTokensPerPool(_tier: number): Promise<number[]>;
  owner(): Promise<string>;
  tiers(tokenId: number): Promise<number>;
  defaultTokenURI(tokenId: number): Promise<string>;
  attributeA(tokenId: number): Promise<number>;
  attributeB(tokenId: number): Promise<number>;
  attributeC(tokenId: number): Promise<number>;
  collectionTypes(tokenId: number): Promise<number>;
  isBoostToken(tokenId: number): Promise<boolean>;

  // ERC721 Standard Methods
  baseURI(): Promise<string>;
  approve(to: string, tokenId: number): Promise<void>;
  setApprovalForAll(operator: string, approved: boolean): Promise<void>;
  transferFrom(from: string, to: string, tokenId: number): Promise<void>;
  getApproved(tokenId: number): Promise<string>;
  isApprovedForAll(owner: string, operator: string): Promise<boolean>;
  safeTransferFrom(from: string, to: string, tokenId: number): Promise<void>;
  safeTransferFrom(
    from: string,
    to: string,
    tokenId: number,
    data: any,
  ): Promise<void>;
}
