import React, { useEffect, useState } from 'react';
import './GameSelection.scss';
import logo from 'assets/logos/P2PLogo.png';
import Leaderboard from 'assets/Game/Leaderboard.png';
import Staking from 'assets/Game/P2PCoin.gif';
import TutorialsImage from 'assets/Game/Tutorial.png';
import PunchersChance from 'assets/GGGvsBrook.png';
import { GameDetails } from 'types/NFT/Battle';
import { ethers } from 'ethers';

const Marketplace = require('assets/Lawrence_AJ.mp4');

interface Props {
  onLogoClick: () => void;
  onGameSelect: (mode: GameDetails) => void;
  gameDetails: GameDetails[];
}

const GameSelection: React.FC<Props> = ({
  onLogoClick,
  onGameSelect,
  gameDetails,
}) => {
  const [ecosystemItems, setEcosystemItems] = useState<
    { title: string; image: string; path: string }[]
  >([
    { title: 'Staking Pages', image: Staking, path: 'staking' },
    { title: 'Leaderboards', image: Leaderboard, path: 'leaderboards' },
    { title: 'Tutorials', image: TutorialsImage, path: 'tutorials' },
  ]);

  const randomNum = (max: number) => {
    return Math.floor(Math.random() * max);
  };
  useEffect(() => {}, []);

  return (
    <div className="game-selection-page">
      <header className="game-selection-header">
        <div className="header-container">
          <div className="logo" onClick={onLogoClick}>
            <img src={logo} alt="Logo" />
          </div>
          <div className="header-text">
            <h1>P2P GAMING</h1>
            <p>PVP Player V Player Mode</p>
          </div>
        </div>
      </header>

      {/* Ecosystem Section */}
      <section className="ecosystem">
        <div className="container">
          <h2 className="section-header">
            <i className="fa fa-leaf" aria-hidden="true"></i>
            Ecosystem
          </h2>
          <div className="ecosystem-grid">
            {ecosystemItems.map((item, index) => (
              <div
                key={index}
                className="ecosystem-card"
                onClick={() => alert(`Ecosystem: ${item.title}`)}
              >
                <img src={item.image} alt={item.title} />
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Enter Game */}
      <section className="game-modes">
        <div className="container">
          <h2 className="section-header">
            <i className={`fa fa-gamepad`}></i>
            Game Modes
          </h2>
          {gameDetails.length > 0 ? (
            <div className="game-modes-grid">
              {gameDetails.map((game, index) => (
                <div
                  key={index}
                  className="game-mode-card"
                  onClick={() => onGameSelect(game)}
                >
                  {!game.gameIPFS.includes('.mp4') ? (
                    <img src={game.gameIPFS} alt={game.gameIPFS} />
                  ) : (
                    <video
                      src={game.gameIPFS}
                      itemType="video/mp4"
                      autoPlay
                      muted
                      loop
                    />
                  )}

                  <div className="game-info">
                    <h3>Game Details</h3>
                    <div className="game-attributes-scrollable">
                      <div className="game-attributes">
                        <div className="attribute-item">
                          <strong>Participant Size:</strong>{' '}
                          {`${game.participantSize}`}
                        </div>
                        <div className="attribute-item">
                          <strong>Game Fee:</strong>{' '}
                          {`${ethers.formatEther(game.gameFee.toString())}`} ETH
                        </div>
                        <div className="attribute-item">
                          <strong>Cards Per Player:</strong>{' '}
                          {`${game.cardsPerPlayer}`}
                        </div>
                        <div className="attribute-item">
                          <strong>Can Use Boost:</strong>{' '}
                          {game.canUseBoost ? 'Yes' : 'No'}
                        </div>
                        <div className="attribute-item">
                          <strong>Max Boosters Per Card:</strong>{' '}
                          {`${game.maxBoostersPerCard}`}
                        </div>
                        <div className="attribute-item">
                          <strong>Attribute Guess Option:</strong>{' '}
                          {game.attributeGuessOption ? 'Enabled' : 'Disabled'}
                        </div>
                        <div className="attribute-item">
                          <strong>Tier:</strong>{' '}
                          {game.tier === 0
                            ? 'All Tiers (10-30)'
                            : `${game.tier}`}
                        </div>
                      </div>
                    </div>{' '}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="game-modes-grid">
              <div className="game-mode-card">
                <video
                  src={Marketplace}
                  itemType="video/mp4"
                  autoPlay
                  muted
                  loop
                />
                <h3>Games Are Comming Soon!</h3>
                <p>
                  No games available at the moment. Please check back later!
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default GameSelection;
