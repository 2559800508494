import React, { useEffect, useState } from 'react';
import './boosterSelection.scss';
import { GameDetails } from 'types/NFT/Battle';
import { Boost } from 'types/NFT/Minter';
import Search from 'components/Card/P2PCard/Search';
import P2PBooster, {
  getBoostType,
} from 'components/Card/P2PBooster/P2PBooster';
import toast from 'react-hot-toast';

interface BoosterSelectionProps {
  nftBoostCards: Boost[];
  gameMode: GameDetails;
  selected: number;
  onBoostSelect: (selectedBoosters: Boost[]) => void;
  onBack: () => void;
}
// Helper function to group boosters by targetAttribute and boostPoint, aggregating quantities
export const groupBoosters = (boosters: Boost[]) => {
  return boosters.reduce(
    (acc, boost) => {
      const groupKey = `${boost.targetAttribute}-${boost.boostPoint}`;
      if (!acc[groupKey]) {
        acc[groupKey] = {
          boost,
          quantity: 1,
        };
      } else {
        acc[groupKey].quantity += 1;
      }
      return acc;
    },
    {} as { [key: string]: { boost: Boost; quantity: number } },
  );
};
const BoosterSelection: React.FC<BoosterSelectionProps> = ({
  nftBoostCards,
  gameMode,
  selected,
  onBoostSelect,
  onBack,
}) => {
  const [selectedBoosters, setSelectedBoosters] = useState<Boost[]>([]);
  const [currentBooster, setCurrentBooster] = useState<Boost | null>(null);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const groupedBoosters = groupBoosters(nftBoostCards);

  const handleBoosterClick = (boost: Boost) => {
    if (
      currentBooster &&
      currentBooster.targetAttribute === boost.targetAttribute &&
      currentBooster.boostPoint === boost.boostPoint
    ) {
      setCurrentBooster(null);
    } else {
      setCurrentBooster(boost);
    }
  };

  const handleAddBoost = (boost: Boost) => {
    const selectedAttributeCount = selectedBoosters.filter(
      (x) =>
        x.targetAttribute === boost.targetAttribute &&
        x.boostPoint === boost.boostPoint,
    ).length;

    //handle Full selection notifify user and pump icon
    if (selected + selectedBoosters.length === Number(gameMode.maxBoostersPerCard)) {
      toast.error('Max Boosters Per Card Selected');
      return;
    }
    // Check if there are still enough boosters available
    const groupKey = `${boost.targetAttribute}-${boost.boostPoint}`;
    const availableQuantity = groupedBoosters[groupKey]?.quantity ?? 0;
    if (selectedAttributeCount < availableQuantity) {
      console.log(
        `Adding boost for attribute ${boost.targetAttribute} with boostPoint ${boost.boostPoint} for boostToken ID: ${boost.tokenId}`,
      );
      setSelectedBoosters([...selectedBoosters, boost]);
    } else {
      console.warn('No more boosters of this type are available');
    }
  };

  const handleRemoveBoost = (boost: Boost) => {
    const indexToRemove = selectedBoosters.findIndex(
      (x) =>
        x.targetAttribute === boost.targetAttribute &&
        x.boostPoint === boost.boostPoint,
    );
    if (indexToRemove > -1) {
      console.log(
        `Removing boost for attribute ${boost.targetAttribute} with boostPoint ${boost.boostPoint} for boostToken ID: ${boost.tokenId}`,
      );
      const updatedSelectedCards = [...selectedBoosters];
      updatedSelectedCards.splice(indexToRemove, 1);
      setSelectedBoosters(updatedSelectedCards);
    } else {
      console.warn('Boost not found');
    }
  };

  const handlePlayNow = () => {
    onBoostSelect(selectedBoosters);
  };

  return (
    <div id="boost_selection">
      {/* Header Section with Back and Burger Button */}
      <div className="slider-title">
        <button className="back-button" onClick={onBack}>
          <i className="fa fa-angle-left"></i> Back
        </button>
        <button
          className="burger-button"
          onClick={() => setShowFilterModal(!showFilterModal)}
        >
          <i className="fa fa-bars"></i>
        </button>
      </div>

      {/* Filter Modal */}
      {showFilterModal && (
        <div className="slider-title">
          <div className="filter-modal">
            <div className="filter-modal-content">
              <Search
                boosters={nftBoostCards}
                onBoostFiltered={(f) => console.log('filter:', f)}
              />
            </div>
          </div>
        </div>
      )}
      {/* Booster Selection Section */}
      <section className="booster-selection-section">
        <h2 className="section-header">
          <i className="fas fa-rocket me-2"></i> AVAILABLE BOOSTERS
        </h2>
        <div className="booster-deck">
          {Object.entries(groupedBoosters).map(
            ([groupKey, { boost, quantity }]) => {
              const selectedAttributeCount = selectedBoosters.filter(
                (selected) =>
                  selected.targetAttribute === boost.targetAttribute &&
                  selected.boostPoint === boost.boostPoint,
              ).length;

              return (
                <P2PBooster
                  key={groupKey}
                  booster={boost}
                  selectedAmount={selectedAttributeCount}
                  isSelected={
                    currentBooster?.targetAttribute === boost.targetAttribute &&
                    currentBooster.boostPoint === boost.boostPoint
                  }
                  availableQuantity={quantity - selectedAttributeCount}
                  onClick={() => handleBoosterClick(boost)}
                />
              );
            },
          )}
        </div>

        <div className="confirm-booster-container">
          <button
            id="confirm-booster-button"
            disabled={
              !currentBooster ||
              selected + selectedBoosters.length === Number(gameMode.maxBoostersPerCard)
            }
            onClick={() => currentBooster && handleAddBoost(currentBooster)}
          >
            Confirm Booster
          </button>
        </div>
      </section>

      {/* Footer Selection */}
      <div className="choose-card-container">
        <div className="info-section">
          <section>Max: {Number(gameMode.maxBoostersPerCard)}</section>
          <section>Selected: {selected + selectedBoosters.length}</section>
        </div>
        {currentBooster &&
          selectedBoosters.some(
            (x) =>
              x.targetAttribute === currentBooster.targetAttribute &&
              x.boostPoint === currentBooster.boostPoint,
          ) && (
            <button
              id="remove-card-button"
              onClick={() => handleRemoveBoost(currentBooster)}
            >
              Remove
            </button>
          )}
        <button
          id="choose-card-button"
          disabled={selectedBoosters.length === 0}
          onClick={handlePlayNow}
        >
          Play
        </button>
      </div>
    </div>
  );
};

export default BoosterSelection;
