import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import toast from 'react-hot-toast';
import { MinterHelper } from 'Helpers/MinterContractHelper';

// declare global {
//   interface Window {
//     ethereum: ethers.BrowserProvider;
//   }
// }
const Web3Context = createContext(null);

export const Web3Provider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState<string>(null);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [web3Modal, setWeb3Modal] = useState(null);

  useEffect(() => {
    const initWeb3Modal = () => {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              84532:
                'https://chain-proxy.wallet.coinbase.com?targetName=base-sepolia',
            },
          },
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: 'My App',
            rpc: 'https://chain-proxy.wallet.coinbase.com?targetName=base-sepolia',
            chainId: 84532,
          },
        },
      };

      const modal = new Web3Modal({
        cacheProvider: true, // optional
        providerOptions, // required
      });

      setWeb3Modal(modal);
    };

    initWeb3Modal();
  }, []);

  // Auto-connect wallet if a cached provider is present
  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      connectWallet();
    }
  }, [web3Modal]);

  const connectWallet = async () => {
    const baseSepoliaChainId = 84532;
    const baseSepoliaNetwork = {
      chainId: `0x${baseSepoliaChainId.toString(16)}`, // Hexadecimal format
      chainName: 'Base Sepolia',
      nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://chain-proxy.wallet.coinbase.com?targetName=base-sepolia',
      ],
      blockExplorerUrls: ['https://sepolia.basescan.org'],
    };
    try {
      const instance = await web3Modal.connect();
      const ethersProvider = new ethers.BrowserProvider(instance); // For ethers v6, use BrowserProvider

      const network = await ethersProvider.getNetwork();

      console.log('ethersProvider', ethersProvider);
      console.log('network', network);
      if (Number(network.chainId) !== baseSepoliaChainId) {
        toast.success('Switching to the Base Sepolia network.');
        try {
          await ethersProvider.send('wallet_addEthereumChain', [
            baseSepoliaNetwork,
          ]);
        } catch (error) {
          console.error('Failed to switch to the Base Sepolia network', error);
          alert(
            'Please switch to the Base Sepolia network manually in your wallet.',
          );
          return;
        }
        return;
      }

      const signer = await ethersProvider.getSigner();
      const address = await signer.getAddress();

      setProvider(ethersProvider);
      setAccount(address);

      const isOwner = await checkIsOwner(ethersProvider);
      setIsOwner(isOwner);

      instance.on('accountsChanged', (accounts) => {
        setAccount(accounts[0]);
      });

      instance.on('chainChanged', () => {
        window.location.reload(); // Optional: Reload on network change
      });
    } catch (error) {
      console.error('Could not connect wallet:', error);
    }
  };

  const checkIsOwner = async (ethersProvider) => {
    try {
      // const contract = new ethers.Contract(
      //   ContractAddress,
      //   base.abi,
      //   ethersProvider.getSigner(),
      // );
      const contract = new MinterHelper();

      const isOwner = await contract.isOwner();
      return isOwner;
    } catch (error) {
      console.error('Error checking owner:', error);
      return false;
    }
  };

  return (
    <Web3Context.Provider value={{ provider, account, isOwner, connectWallet }}>
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3 = () => {
  const context = useContext(Web3Context);
  if (!context) {
    throw new Error('useWeb3 must be used within a Web3Provider');
  }
  return context;
};
