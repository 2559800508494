import React, { useState, useCallback, useRef, useEffect } from 'react';
import './search.scss';
import { Boost } from 'types/NFT/Minter';

export interface NFT {
  tokenId?: number;
  ipfs: MetaDataInfo;
  tier: number;
  a: number;
  b: number;
  c: number;
  collection: number;
}

export type MetaDataInfo = {
  name: string;
  description?: string;
  image: string;
  external_url?: string;
  attributes?: Attributes[];
};

interface Attributes {
  trait_type: string;
  value: MetaDataAttributeValueProps;
}

export type MetaDataAttributeValueProps = string | number | boolean | null;

interface SearchProps {
  nfts?: NFT[];
  boosters?: Boost[];
  onNFTFiltered?: (filteredCards: NFT[]) => void;
  onBoostFiltered?: (filteredCards: Boost[]) => void;
}

const Search: React.FC<SearchProps> = ({
  nfts,
  boosters,
  onNFTFiltered,
  onBoostFiltered,
}) => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [isFilterDropdownActive, setIsFilterDropdownActive] =
    useState<boolean>(false);
  const [isSettingsDropdownActive, setIsSettingsDropdownActive] =
    useState<boolean>(false);
  const [visibleFields, setVisibleFields] = useState<Set<string>>(new Set());
  const [rangeEnabledFields, setRangeEnabledFields] = useState<Set<string>>(
    new Set(),
  );
  const [showNfts, setShowNfts] = useState<boolean>(true); // Toggle between NFTs and Boosters

  const filterContentRef = useRef<HTMLDivElement | null>(null);
  const filterDropdownRef = useRef<HTMLButtonElement | null>(null);
  const settingsContentRef = useRef<HTMLDivElement | null>(null);
  const settingsDropdownRef = useRef<HTMLButtonElement | null>(null);

  // Extract unique fields from nfts or boosters for filtering dynamically
  const getAllCardFields = (cards: NFT[] | Boost[]): string[] => {
    const allKeys = new Set<string>();

    cards.forEach((card) => {
      Object.keys(card).forEach((key) => {
        if (typeof card[key] !== 'object' || Array.isArray(card[key])) {
          allKeys.add(key);
        }
      });

      if ('ipfs' in card) {
        Object.keys((card as NFT).ipfs).forEach((key) => {
          if (
            key !== 'attributes' &&
            typeof (card as NFT).ipfs[key] !== 'object'
          ) {
            allKeys.add(`ipfs.${key}`);
          }
        });

        (card as NFT).ipfs.attributes?.forEach((attribute) => {
          allKeys.add(`ipfs.attributes.${attribute.trait_type}`);
        });
      }
    });
    return Array.from(allKeys);
  };

  const cardFields = showNfts
    ? getAllCardFields(nfts || [])
    : getAllCardFields(boosters || []);
  const cards = showNfts ? nfts || [] : boosters || [];

  const handleFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { id, value } = e.target;
      setFilters((prevFilters) => ({ ...prevFilters, [id]: value }));
    },
    [],
  );

  const handleRangeFilterChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      field: string,
      bound: 'min' | 'max',
    ) => {
      const { value } = e.target;
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: {
          ...prevFilters[field],
          [bound]: value,
        },
      }));
    },
    [],
  );

  const handleSearch = () => {
    const allFilters = {
      ...filters,
    };

    console.log('Original Cards:', cards); // Log the original cards before filtering

    // Filtering logic based on filters

    if (showNfts) {
      const filteredCards = (cards as NFT[]).filter((card) => {
        let matchesFilters = true;

        // Type Narrowing: Check whether card is an NFT or Boost.
        if (showNfts && !('ipfs' in card)) {
          return false; // The card must be an NFT, so skip if it doesn't have 'ipfs'.
        }
        if (!showNfts && !('targetAttribute' in card)) {
          return false; // The card must be a Boost, so skip if it doesn't have 'targetAttribute'.
        }

        // Check if all the filters match
        for (let key in allFilters) {
          if (allFilters[key]) {
            const filterValue = getNestedValue(card, key);
            const filter = allFilters[key];

            if (
              typeof filter === 'object' &&
              'min' in filter &&
              'max' in filter
            ) {
              // Handle range filter
              if (filterValue < filter.min || filterValue > filter.max) {
                matchesFilters = false;
                break;
              }
            } else {
              // Handle single value filter
              if (String(filterValue) !== String(filter)) {
                matchesFilters = false;
                break;
              }
            }
          }
        }

        return matchesFilters;
      });

      console.log('Filtered NFT Cards:', filteredCards); // Log the filtered cards

      onNFTFiltered(filteredCards as NFT[]);
    } else {
      const filteredCards = (cards as Boost[]).filter((card) => {
        let matchesFilters = true;

        // Type Narrowing: Check whether card is an NFT or Boost.
        if (showNfts && !('ipfs' in card)) {
          return false; // The card must be an NFT, so skip if it doesn't have 'ipfs'.
        }
        if (!showNfts && !('targetAttribute' in card)) {
          return false; // The card must be a Boost, so skip if it doesn't have 'targetAttribute'.
        }

        // Check if all the filters match
        for (let key in allFilters) {
          if (allFilters[key]) {
            const filterValue = getNestedValue(card, key);
            const filter = allFilters[key];

            if (
              typeof filter === 'object' &&
              'min' in filter &&
              'max' in filter
            ) {
              // Handle range filter
              if (filterValue < filter.min || filterValue > filter.max) {
                matchesFilters = false;
                break;
              }
            } else {
              // Handle single value filter
              if (String(filterValue) !== String(filter)) {
                matchesFilters = false;
                break;
              }
            }
          }
        }

        return matchesFilters;
      });

      console.log('Filtered Boost Cards:', filteredCards); // Log the filtered cards

      onBoostFiltered(filteredCards as Boost[]);
    }
  };
  const toggleFilterDropdown = () => {
    setIsFilterDropdownActive((prev) => !prev);
  };

  const toggleSettingsDropdown = () => {
    setIsSettingsDropdownActive((prev) => !prev);
  };

  const toggleCardType = () => {
    setShowNfts((prev) => !prev);
    setFilters({}); // Reset filters when switching between NFTs and Boosters
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      filterContentRef.current &&
      !filterContentRef.current.contains(e.target as Node) &&
      filterDropdownRef.current !== e.target
    ) {
      setIsFilterDropdownActive(false);
    }
    if (
      settingsContentRef.current &&
      !settingsContentRef.current.contains(e.target as Node) &&
      settingsDropdownRef.current !== e.target
    ) {
      setIsSettingsDropdownActive(false);
    }
  };

  // Function to toggle select all or unselect all fields
  const toggleSelectAllFields = () => {
    if (visibleFields.size === cardFields.length) {
      setVisibleFields(new Set());
    } else {
      setVisibleFields(new Set(cardFields));
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    const newVisibleFields = new Set(cardFields);

    // Check if there are any differences between `visibleFields` and `newVisibleFields`
    let areFieldsDifferent =
      visibleFields.size !== newVisibleFields.size ||
      [...visibleFields].some((field) => !newVisibleFields.has(field));

    if (areFieldsDifferent) {
      setVisibleFields(newVisibleFields);
    }

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [cardFields]);

  const toggleFieldVisibility = (field: string) => {
    setVisibleFields((prevVisibleFields) => {
      const newVisibleFields = new Set(prevVisibleFields);
      if (newVisibleFields.has(field)) {
        newVisibleFields.delete(field);
      } else {
        newVisibleFields.add(field);
      }
      return newVisibleFields;
    });
  };

  const toggleRangeEnabled = (field: string) => {
    setRangeEnabledFields((prevRangeFields) => {
      const newRangeFields = new Set(prevRangeFields);
      if (newRangeFields.has(field)) {
        newRangeFields.delete(field);
      } else {
        newRangeFields.add(field);
      }
      return newRangeFields;
    });
  };

  useEffect(() => {
    //Auto Filter
    handleSearch();
  }, [filters]);

  return (
    <div id="Search">
      <div className="toolbar">
        <div className="search-container">
          <div className="dropdown">
            <div className="button-container">
              <button
                ref={filterDropdownRef}
                id="filterDropdown"
                aria-haspopup="true"
                aria-expanded={isFilterDropdownActive}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFilterDropdown();
                }}
              >
                Filters
              </button>
              <button
                ref={settingsDropdownRef}
                id="settingsButton"
                aria-haspopup="true"
                aria-expanded={isSettingsDropdownActive}
                className="settings-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSettingsDropdown();
                }}
              >
                ⚙️
              </button>
              {nfts && boosters && (
                <button
                  id="toggleCardTypeButton"
                  onClick={toggleCardType}
                  className="toggle-button"
                >
                  {showNfts ? 'Show Boosters' : 'Show NFTs'}
                </button>
              )}
            </div>
            <div
              ref={filterContentRef}
              id="filterContent"
              className={`dropdown-content ${isFilterDropdownActive ? 'active' : ''}`}
              aria-labelledby="filterDropdown"
            >
              {Array.from(visibleFields).map((field) => {
                if (typeof getSafePropertyValue(cards, field) !== 'object') {
                  return (
                    <div key={field} className="filter-group">
                      <label htmlFor={field}>
                        {field.split('.')[field.split('.').length - 1]}
                      </label>
                      <div className="field-container">
                        {rangeEnabledFields.has(field) ? (
                          <div className="range-inputs">
                            <input
                              type="number"
                              id={`${field}-min`}
                              placeholder={`Min ${field.split('.')[field.split('.').length - 1]}`}
                              value={filters[field]?.min || ''}
                              onChange={(e) =>
                                handleRangeFilterChange(e, field, 'min')
                              }
                            />
                            <input
                              type="number"
                              id={`${field}-max`}
                              placeholder={`Max ${field.split('.')[field.split('.').length - 1]}`}
                              value={filters[field]?.max || ''}
                              onChange={(e) =>
                                handleRangeFilterChange(e, field, 'max')
                              }
                            />
                          </div>
                        ) : (
                          renderInputFieldForFilter(
                            cards,
                            field,
                            filters[field] || '',
                            handleFilterChange,
                          )
                        )}
                        <button
                          type="button"
                          onClick={() => toggleRangeEnabled(field)}
                          className="toggle-range-button"
                        >
                          {rangeEnabledFields.has(field)
                            ? 'Single Value'
                            : 'Range'}
                        </button>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div
              ref={settingsContentRef}
              id="settingsContent"
              className={`dropdown-content ${isSettingsDropdownActive ? 'active' : ''}`}
              aria-labelledby="settingsButton"
            >
              <div className="select-all-toggle">
                <button
                  type="button"
                  onClick={toggleSelectAllFields}
                  className="select-all-button"
                >
                  {visibleFields.size === cardFields.length ? (
                    <>
                      <i className="fa fa-check-square" /> Unselect All
                    </>
                  ) : (
                    <>
                      <i className="fas fa-square" /> Select All
                    </>
                  )}
                </button>
              </div>
              {cardFields.map((field) => (
                <div key={field} className="filter-group">
                  <label htmlFor={field}>
                    <input
                      type="checkbox"
                      checked={visibleFields.has(field)}
                      onChange={() => toggleFieldVisibility(field)}
                    />
                    {field}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Utility function to safely get nested values from an object using a path
const getNestedValue = (obj: any, path: string) => {
  const parts = path.split('.');

  return parts.reduce((acc, part) => {
    if (acc === undefined || acc === null) {
      return undefined;
    }

    if (Array.isArray(acc)) {
      return acc.find((item: any) => item.trait_type === part)?.value;
    }

    return acc[part];
  }, obj);
};

// Function to dynamically render input fields based on the field type
const renderInputFieldForFilter = (
  cards: NFT[] | Boost[],
  field: string,
  value: any,
  handleFilterChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void,
) => {
  const exampleValue = getSafePropertyValue(cards, field);

  if (typeof exampleValue === 'string') {
    return (
      <input
        type="text"
        id={field}
        placeholder={field.split('.')[field.split('.').length - 1]}
        value={value}
        onChange={handleFilterChange}
      />
    );
  } else if (typeof exampleValue === 'number') {
    return (
      <input
        type="number"
        id={field}
        placeholder={field.split('.')[field.split('.').length - 1]}
        value={value}
        onChange={handleFilterChange}
      />
    );
  } else if (typeof exampleValue === 'boolean') {
    return (
      <input
        type="checkbox"
        id={field}
        placeholder={field.split('.')[field.split('.').length - 1]}
        checked={value}
        onChange={handleFilterChange}
      />
    );
  }

  return null; // Exclude rendering for objects or unsupported types
};

// Function to get safe property value for rendering
const getSafePropertyValue = (cards: any[], field: string) => {
  const uniqueValues = [
    ...new Set(
      cards
        .map((card) => getNestedValue(card, field))
        .filter((v) => v !== undefined && v !== null),
    ),
  ];

  return uniqueValues[0];
};

export default Search;
