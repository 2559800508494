import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import logo from 'assets/logos/Base_Symbol_Blue.svg';
import background from 'assets/Dapp/background.jpg';
import giff from 'assets/P2P_x_LL_ATOM1.gif';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { NFTComponent1 } from './NFTCard1';
import PackCarousel from './PackCarousel';
import PackagesCart from './PackagesCart';
import './packages.scss';
import EnterPage from './Game/EnterPage';
import Raffle from 'Views/Raffle/Raffle';

SwiperCore.use([EffectCoverflow, Pagination]);

export type PackDetail = {
  id: number;
  class?: string;
  available: number;
  name: string;
  fee?: number;
  price?: number;
  imageUrl: string;
  qty: number;
  heading: string;
  description: string;
  active: boolean;
};

export type PackagesProps = {
  packDetails: PackDetail[];
  cart: PackDetail[];
  onPurchasePacks: (packs: PackDetail[]) => Promise<void>;
  onAddToCart: (pack: PackDetail) => Promise<void>;
  onUpdateCart: (pack: PackDetail[]) => void;
  paused: boolean;
};

const Packages: React.FC<PackagesProps> = ({
  packDetails,
  cart,
  onPurchasePacks,
  onAddToCart,
  onUpdateCart,
  paused,
}) => {
  const [currentPack, setCurrentPack] = useState<PackDetail>(null);
  const [currentPage, setCurrentPage] = useState<Nav>(null);
  const rotatingImageRef = useRef<HTMLImageElement>(null);

  const navs: Nav[] = [
    { iconClass: 'fa fa-map', navName: 'Discover' },
    { iconClass: 'fa fa-arrow-trend-up', navName: 'Explore' },
    { iconClass: 'fas fa-shopping-basket', navName: 'Basket' },
    { iconClass: 'fa fa-gamepad', navName: 'Game' },
    { iconClass: 'fa fa-ticket', navName: 'Raffle' },
  ];

  const handlePage = (nav: Nav) => {
    setCurrentPage(nav);
  };

  const renderContent = () => {
    switch (currentPage?.navName) {
      case 'Discover': // Discover or null
      case null:
      default:
        return (
          <>
            <LeftContent
              packDetails={packDetails}
              onBuyPack={setPack}
              paused={paused}
            />
            <RightContent
              packs={packDetails}
              onBuyPack={setPack}
              paused={paused}
            />
          </>
        );
      case 'Explore':
        return (
          <PackCarousel
            packDetails={packDetails}
            onSetCurrentPack={setPack}
            paused={paused}
          />
        );
      case 'Game':
        return <EnterPage />;
      // case "Playlist":
      //   return <PlaylistComponent />;
      case 'Basket':
        return (
          <PackagesCart
            packDetails={cart}
            onPurchase={onPurchasePacks}
            onUpdateCart={onUpdateCart}
          />
        );
      case 'Raffle':
        return <Raffle />;
      // default:
      //   return <div>Select a page from the navigation</div>;
    }
  };
  const setPack = (pack: PackDetail) => {
    pack.class = `card${Math.floor(Math.random() * 4) + 1}`;
    setCurrentPack(pack);
  };

  useEffect(() => {
    //do we need to handle anything when the page switches??
  }, [currentPage]);

  useEffect(() => {}, [currentPack]);

  const addToCart = (pack: PackDetail) => {
    //callback to parent Packages to add to cart
    onAddToCart(pack);
    setCurrentPack(null);
  };

  const buyPack = async (pack: PackDetail) => {
    //callback to parent dapp to purchase the pack
    await onPurchasePacks([pack]);
  };

  return (
    <div
      id="packages"
      className="packages"
      translate="no"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Modal
        currentPack={currentPack}
        onBuyPack={buyPack}
        onAddToCart={addToCart}
        onClose={() => setCurrentPack(null)}
        rotatingImageRef={rotatingImageRef}
      />
      <main className="main">
        <Nav navItems={navs} onNavigate={handlePage} />
        <section className={`content ${currentPage?.navName ?? null}`}>
          {renderContent()}
        </section>
      </main>
    </div>
  );
};

type ModalProps = {
  currentPack: PackDetail;
  onBuyPack: (pack: PackDetail) => Promise<void>;
  onAddToCart: (pack: PackDetail) => void;
  onClose: () => void;
  rotatingImageRef: any;
};
const Modal = ({
  currentPack,
  onBuyPack,
  onAddToCart,
  onClose,
  rotatingImageRef,
}: ModalProps) => {
  if (!currentPack) return null;

  return (
    <div className="music-player">
      <i
        onClick={onClose}
        className="fa fa-window-close"
        aria-hidden="true"
      ></i>

      {/* <div className="album-cover">
          <img
            src={currentPack.imageUrl}
            ref={rotatingImageRef}
            alt={currentPack.name}
          />
          <span className="point"></span>
        </div>
        <h2>{currentPack.name}</h2>
        <p>{currentPack.heading}</p>
        <p>{currentPack.description}</p>
        <div className="controls">
          <button onClick={() => onBuyPack(currentPack)}>
            <i className="fa-solid fa-backward"></i>
          </button>
          <button onClick={() => onAddToCart(currentPack)}>
            <i className="fa-solid fa-play"></i>
          </button>
          <button onClick={() => onBuyPack(currentPack)}>
            <i className="fa-solid fa-forward"></i>
          </button>
        </div> */}
      <NFTComponent1
        className={currentPack.class}
        price={currentPack.price}
        fee={currentPack.fee}
        leftAvater={currentPack.name}
        leftHeader={'header'}
        leftValue={'name'}
        rightAvater={currentPack.available.toString()}
        rightHeader={currentPack.available > 0 ? 'Instock' : 'N/A'}
        rightValue={'Available'}
        img={currentPack.imageUrl}
        name={currentPack.name}
        footer="Lawrence Lustig X"
        setQuantity={(e) => (currentPack.qty = e)}
        handlePurchase={async () => {
          await onAddToCart(currentPack);
        }}
      />
    </div>
  );
};

type Nav = {
  iconClass: string;
  navName: string;
};
type Navprops = {
  navItems: Nav[];
  onNavigate: (nav: Nav) => void;
  activeNav?: string;
};

const Nav = ({ navItems, activeNav, onNavigate }: Navprops) => {
  const [activeIcon, setActiveIcon] = useState<string>(
    activeNav ?? navItems[0].navName,
  );

  const handleNavClick = (nav: Nav) => {
    setActiveIcon(nav.navName);
    onNavigate(nav);
  };

  return (
    <nav className="main-menu">
      <div>
        <div className="user-info">
          <img src={logo} alt="user" />
          <p style={{ color: '#3552ff' }}>Base</p>
        </div>
        <ul>
          {navItems.map((nav, index) => (
            <li
              key={index}
              onClick={() => handleNavClick(nav)}
              className={`nav-item ${
                activeIcon === nav.navName ? 'active' : null
              }`}
            >
              <a href="#" onClick={(event) => event.preventDefault()}>
                <i className={`${nav.iconClass} nav-icon`}></i>
                <span className="nav-text">{nav.navName}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <ul>
        <li className="nav-item">
          <a href="#">
            <i className="fa fa-user nav-icon"></i>
            <span className="nav-text">Profile</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#">
            <i className="fa fa-gear nav-icon"></i>
            <span className="nav-text">Settings</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#">
            <i className="fa fa-right-from-bracket nav-icon"></i>
            <span className="nav-text">Logout</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

type LeftContentProps = {
  packDetails: PackDetail[];
  onBuyPack: (pack: PackDetail) => void;
  paused: boolean;
};
const LeftContent = ({ packDetails, onBuyPack, paused }: LeftContentProps) => {
  const [isSwiperReady, setIsSwiperReady] = useState(false);
  useEffect(() => {
    if (packDetails.length > 0) {
      // Only initialize Swiper once packDetails are available
      setIsSwiperReady(true);
    }
  }, [packDetails]);
  return (
    <div className="left-content">
      <div className="slider-container">
        <h1>Popular</h1>
        {isSwiperReady ? (
          <Swiper
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            speed={600}
            slidesPerView="auto"
            coverflowEffect={{
              rotate: 10,
              stretch: 120,
              depth: 200,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={{ clickable: true }}
            className="swiper-container"
          >
            {packDetails.length === 0 && <div>no packs to see</div>}
            {packDetails.map((pack, index) => (
              <SwiperSlide key={index}>
                <img
                  src={pack.imageUrl}
                  alt={pack.name}
                  loading="lazy"
                  className="packItem"
                />
                <div className="slide-overlay">
                  <h2>{pack.name}</h2>
                  <button onClick={() => onBuyPack(pack)}>
                    Buy{' '}
                    <i className="fab fa-ethereum">
                      <span></span> {pack.fee}
                    </i>{' '}
                    <i className="fa-solid fa-basket-shopping"></i>
                  </button>
                </div>
                {(paused || !pack.active) && (
                  <div className="paused-overlay">
                    <i className="fa fa-pause-circle" aria-hidden="true"></i>
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        ) : packDetails.length > 0 ? (
          <p>oading Packs...</p>
        ) : (
          <p> Stay Tuned...</p>
        )}
        <div className="swiper-pagination"></div>
      </div>
      <div className="artists">
        <h1>Featured</h1>
        <div className="artist-container containers">
          {packDetails.map((pack, index) => (
            <div className="artist" key={index}>
              <div className="image-wrapper">
                <img
                  src={pack.imageUrl}
                  alt={pack.name}
                  onClick={() => onBuyPack(pack)}
                />
                {(paused || !pack.active) && (
                  <div className="paused-overlay">
                    <i className="fa fa-pause-circle" aria-hidden="true"></i>
                  </div>
                )}
              </div>
              <p>{pack.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="albums">
        <h1>Recommended Albums</h1>
        <div className="album-container containers">
          {/* Example Albums */}
          {packDetails.length === 0 && <p>Stay Tuned</p>}
          {packDetails.map((pack, index) => (
            <div className="album" key={index}>
              <div className="album-frame">
                <img
                  src={pack.imageUrl}
                  alt={pack.name}
                  onClick={() => onBuyPack(pack)}
                />
                {(paused || !pack.active) && (
                  <div className="paused-overlay">
                    <i className="fa fa-pause-circle" aria-hidden="true"></i>
                  </div>
                )}
              </div>
              <div>
                <h2>{pack.name}</h2>
                <p>
                  <i className="fab fa-ethereum"></i> {pack.fee}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type RightContentProps = {
  packs: PackDetail[];
  onBuyPack: (pack: PackDetail) => void;
  paused: boolean;
};
const RightContent = ({ packs, onBuyPack, paused }: RightContentProps) => {
  return (
    <div className="right-content">
      <div className="recommended-packs">
        <h1>Recommended</h1>
        <div className="pack-container">
          {packs.map((pack, index) => (
            <div className="pack" key={index}>
              <div className="pack-img">
                <img src={pack.imageUrl} alt={pack.name} />
                {(paused || !pack.active) && (
                  <div className="paused-overlay">
                    <i className="fa fa-pause-circle" aria-hidden="true"></i>
                  </div>
                )}

                <div className="overlay" onClick={() => onBuyPack(pack)}>
                  <i className="fa-solid fa-play"></i>
                </div>
              </div>
              <div className="pack-title">
                <h2>{pack.heading}</h2>
                <p>{pack.name}</p>
              </div>
              <span>
                <i className="fab fa-ethereum"></i> {pack.fee}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TrendingComponent = () => {
  return <div></div>;
};
export default Packages;
