import { createPublicClient, createClient, http, Client} from "viem";
import { baseSepolia } from "viem/chains";
import { ENTRYPOINT_ADDRESS_V06 } from "permissionless";
import { paymasterActionsEip7677 } from "permissionless/experimental";
import { createConfig } from 'wagmi'
import { mainnet, sepolia } from 'wagmi/chains'

// Define the baseSepolia chain configuration properly

// Paymaster service URL
const paymasterService = process.env.PAYMASTER_SERVICE_URL!;

// Create a standard client for baseSepolia
export const client = createPublicClient({
  chain: baseSepolia,
  transport: http(paymasterService),
});

// Create the paymaster client
const basePaymasterClient = createClient({
  chain: baseSepolia,
  transport: http(paymasterService),
});

// Explicitly type the paymaster client and extend it with the paymaster actions
export const paymasterClient = (basePaymasterClient as any).extend(
  paymasterActionsEip7677(ENTRYPOINT_ADDRESS_V06)
);

export const config = createConfig({
  chains: [mainnet, sepolia],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
})