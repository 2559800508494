import { ImageContainer, Item, ItemNFT, Name, Position } from "./styledTags";
import "./styles.css";
import GGGpicture from "assets/GGGvsBrook.png"; //default pic
import { useEffect, useState } from "react";
import tier1 from "assets/Tierage/tier1.png";
import tier2 from "assets/Tierage/tier2.png";
import tier3 from "assets/Tierage/tier3.png";

interface BuyProps {
  img: string;
  name: string;
  footer: string;
  className: string;
  leftHeader: string;
  leftValue: string;
  rightHeader: string;
  rightValue: string;
  price?: number;
  fee: number;
  leftAvater: string;
  rightAvater: string;
  handlePurchase: () => void;
  setQuantity: (e: number) => void;
}

export const NFTComponent1 = ({
  img,
  name,
  footer,
  className,
  leftHeader,
  leftValue,
  rightHeader,
  rightValue,
  price,
  fee,
  leftAvater,
  rightAvater,
  handlePurchase,
  setQuantity,
}: BuyProps) => {
  const [q, setQ] = useState<number>(1);

  const handleQuantity = (q: number) => {
    setQ(q);
    setQuantity(q);
  };

  useEffect(() => {
    //handle setting of Quantity
  }, [q]);

  return (
    <ItemNFT className={`card ${className}`}>
      <div className="card__body">
        <div className="card__top">
          {/* <div className="card__owner">
            <div className="card__avatar">{leftAvater}</div>
            <div className="card__user">
              <span className="card__user__title">
                {leftHeader ?? "Owned by"}
              </span>
              <span className="card__user__code">{leftValue ?? "2304RC"}</span>
            </div>
          </div>
          <div className="card__creator">
            <div className="card__avatar">{rightAvater}</div>
            <div className="card__user">
              <span className="card__user__title">
                {rightHeader ?? "Created by"}
              </span>
              <span className="card__user__code">{rightValue ?? "20AR02"}</span>
            </div>
          </div> */}
          <div className="card_name">
            {name}
          </div>
        </div>
        <div className="card__image">
          <ImageContainer>
            <img src={img ?? GGGpicture} alt="" />
          </ImageContainer>
        </div>
      </div>
      <div className="card__info">
        <p>
          <b>Fee:</b> <i className="fab fa-ethereum"></i> {fee}
        </p>
        <p><b>Avaialble:</b> ({rightAvater})</p>
      </div>
      <div className="card__footer">
        <input
          type="number"
          max={20}
          min={1}
          placeholder="Quantity"
          className="card__btn card__btn--secondary"
          maxLength={20}
          onChange={(e) => {
            handleQuantity(Number(e.target.value));
          }}
          value={q}
        />
        <input
          disabled={Number.parseInt(rightAvater) === 0}
          value="Add To Cart"
          type="button"
          className="card__btn card__btn--primary"
          onClick={() => handlePurchase()}
        />
      </div>
      <Position>{footer ?? "Lawrence Lustig X"}</Position>
    </ItemNFT>
  );
};
