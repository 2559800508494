import React from 'react';
import './ChooseGameType.scss';
import { GameType } from './EnterPage';

interface Props {
  onGameTypeSelect: (gameType: GameType) => void;
  onBack: () => void;
}

const ChooseGameType: React.FC<Props> = ({ onGameTypeSelect, onBack }) => (
  <div className="choose-game-type-page">
    <h1 className="header">CHOOSE PVP OR PVC</h1>
    <div className="buttons-container">
      <button className="game-type-button" onClick={() => onGameTypeSelect(GameType.pvp)}>PLAYER VS PLAYER (PVP)</button>
      <button className="game-type-button" onClick={() => onGameTypeSelect(GameType.pvc)}>PLAYER VS COMPUTER (PVC)</button>
    </div>
    <button className="back-button" onClick={onBack}>Back</button>
  </div>
);

export default ChooseGameType;