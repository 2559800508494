import React, { useState, useEffect } from 'react';
import './packCarousel.scss';
import { PackDetail } from './Packages';

export type PackCarouselProps = {
  packDetails: PackDetail[];
  onSetCurrentPack: (pack: PackDetail) => void;
  paused: boolean;
};

const PackCarousel = ({
  packDetails,
  onSetCurrentPack,
  paused,
}: PackCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // Handle next button click
  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % packDetails.length;
    setCurrentIndex(nextIndex);
  };

  // Handle previous button click
  const handlePrev = () => {
    const prevIndex =
      currentIndex === 0 ? packDetails.length - 1 : currentIndex - 1;
    setCurrentIndex(prevIndex);
  };

  useEffect(() => {
    console.log('Current Pack:', currentIndex);
    document.documentElement.style.setProperty(
      '--x-off',
      `-${currentIndex * 100}px`,
    );
  }, [currentIndex]);

  return (
    <div id="packCarousel" translate="no">
      <svg width="48px" height="48px" style={{ display: 'none' }}>
        <path
          id="cart"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="48"
          d="M160 288h256l32-128H128l32 128zM160 288l-32 96h320l-32-96M256 416a32 32 0 1132 32 32 32 0 01-32-32zM160 416a32 32 0 1132 32 32 32 0 01-32-32z"
        ></path>
        <path
          id="dollar"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="48"
          d="M256 112v16m0 320v16m64-304a96 96 0 00-128 128v64a96 96 0 00128-128v-64M256 64v16m0 320v16"
        ></path>
      </svg>
      <main>
        <ul className="slider">
          {packDetails.map((pack, index) => {
            let className = '';
            if (index === currentIndex) {
              className = 'active';
            } else if (index === (currentIndex + 1) % packDetails.length) {
              className = 'next';
            } else if (index === (currentIndex + 2) % packDetails.length) {
              className = 'proceeding';
            } else if (
              index ===
              (currentIndex - 1 + packDetails.length) % packDetails.length
            ) {
              className = 'prev';
            }
            return (
              <li
                key={index}
                className={`slide ${className}`}
                data-shader-index={index}
                style={{ backgroundImage: `url(${pack.imageUrl})` }}
                onClick={() => setCurrentIndex(index)}
              >
                <div className="visual"></div>
                <div className="content">
                  <h2 className="title">{pack.heading}</h2>
                  <p className="description">{pack.description}</p>
                  <button
                    onClick={() => {
                      if (!paused && !!pack.active) {
                        onSetCurrentPack(pack);
                      }
                    }}
                    className="more ripple"
                    disabled={paused || !pack.active}
                  >
                    <div className="icon" role="button">
                      {paused || !pack.active ? (
                        <i
                          className="fa fa-pause-circle"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <svg viewBox="0 0 512 512">
                          <use xlinkHref="#cart"></use>
                        </svg>
                      )}
                    </div>
                  </button>{' '}
                </div>
              </li>
            );
          })}
        </ul>
        <nav className="nav">
          <div className="btn ripple prev" onClick={handlePrev}>
            <div className="icon" role="button">
              <svg viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M244 400L100 256l144-144M120 256h292"
                ></path>
              </svg>
            </div>
          </div>
          <div className="btn ripple next" onClick={handleNext}>
            <div className="icon" role="button">
              <svg viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M268 112l144 144-144 144M392 256H100"
                ></path>
              </svg>
            </div>
          </div>
        </nav>
      </main>
    </div>
  );
};

export default PackCarousel;
