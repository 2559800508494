// DistributorHelper.ts

import {
  ethers,
  keccak256,
  getBytes,
  AbiCoder,
  Overrides,
  TransactionResponse,
  ContractTransaction,
  BigNumberish,
} from 'ethers';

import abi from 'contracts/Distributor.json';
import { willSponsor } from './Paymaster/utils';
import { UserOperation } from 'permissionless';
import { baseSepolia } from 'viem/chains';
import {
  IDistributorContract,
  DistributorContractAddress,
} from 'types/NFT/Distributor';

export type Pack = {
  packId: number;
  tiers: number[];
  defaultUriHash: string;
  fee: number;
  collection: number;
  active: boolean;
}

export class DistributorHelper {
  private contract: IDistributorContract;
  public signer: ethers.JsonRpcSigner;
  private writeContracts?: any; // Adjust the type based on your implementation

  constructor(writeContracts?: any) {
    this.writeContracts = writeContracts;
  }

  private async initializeContract(): Promise<void> {
    if (this.contract && this.signer) {
      // Contract and signer are already initialized
      return;
    }

    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.BrowserProvider(window.ethereum);
      this.signer = await provider.getSigner();
      this.contract = new ethers.Contract(
        DistributorContractAddress,
        abi,
        this.signer,
      ) as IDistributorContract;
    } else {
      throw new Error('Ethereum wallet is not connected');
    }
  }

  private async ensureContractReady(): Promise<void> {
    if (!this.contract || !this.signer) {
      await this.initializeContract();
    }
  }

  // Paymaster Integration
  async canSponsor(
    method: string,
    params: any[],
    types: string[],
  ): Promise<boolean> {
    await this.ensureContractReady();

    const sender = await this.signer.getAddress();

    const iface = new ethers.Interface(abi);
    const callData: `0x${string}` = iface.encodeFunctionData(
      method,
      params,
    ) as `0x${string}`;

    const paymasterAddress = '0x...'; // Replace with your Paymaster contract address
    const abiCoder = new AbiCoder();
    const extraData = abiCoder.encode(types, params);
    const paymasterAndData: `0x${string}` =
      `${paymasterAddress}${extraData.slice(2)}` as `0x${string}`;

    const messageHash = keccak256(callData);
    const signature = await this.signer.signMessage(getBytes(messageHash));

    const userOp: UserOperation<'v0.6'> = {
      sender: sender as `0x${string}`,
      nonce: BigInt(1), // Replace with actual nonce retrieval logic
      initCode: '0x',
      callData,
      callGasLimit: BigInt(100000), // Estimate appropriately
      verificationGasLimit: BigInt(21000),
      preVerificationGas: BigInt(21000),
      maxFeePerGas: BigInt(20000000000),
      maxPriorityFeePerGas: BigInt(1000000000),
      paymasterAndData,
      signature: signature as `0x${string}`,
    };

    const canSponsor = await willSponsor({
      chainId: baseSepolia.id,
      entrypoint: '0x123456789', // Replace with your actual entry point address
      userOp,
    });

    return canSponsor;
  }

  // Payable Methods with Paymaster Integration

  async calculatePackCosts(
    _packIds: number[],
    _quantities: BigNumberish[],
  ): Promise<BigNumberish> {
    try {
      // Ensure that the contract is ready
      await this.ensureContractReady();

      // Validate that the input arrays are of the same length
      if (_packIds.length !== _quantities.length) {
        throw new Error(
          'Pack IDs and quantities arrays must have the same length.',
        );
      }

      // Initialize the total cost in Wei as a BigInt
      let totalCostInWei = 0n;

      // Iterate over the distinct packs to calculate the total cost
      for (let i = 0; i < _packIds.length; i++) {
        const packId = _packIds[i];
        const quantity = BigInt(_quantities[i]);

        // Assume getPack is a method that fetches PackDetail using the packId
        const pack: Pack = await this.getPack(packId);

        if (!pack) {
          throw new Error(`Pack with ID ${packId} not found`);
        }

        if (quantity > 0) {
          // Since `pack.fee` is in Wei, directly multiply it by the quantity
          const packCostInWei = BigInt(pack.fee) * quantity;

          // Accumulate the total cost in Wei
          totalCostInWei += packCostInWei;
        } else {
          throw new Error(`Invalid quantity for pack ID ${packId}`);
        }
      }

      return totalCostInWei;
    } catch (error) {
      console.error('Error calculating pack costs:', error);
      throw error; // Re-throw the error for better error management
    }
  }

  async buyPack(
    _mintAmount: ethers.BigNumberish,
    _packType: number,
    _poolId: ethers.BigNumberish,
    _user: string,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
    capabilities?: any,
  ): Promise<TransactionResponse> {
    await this.ensureContractReady();
    const totalCost = await this.calculatePackCosts([_packType], [_mintAmount]);

    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
        value: totalCost,
      };
    } else {
      overrides.value = totalCost;
    }

    if (
      capabilities &&
      (await this.canSponsor(
        'buyPack',
        [_mintAmount, _packType, _poolId],
        ['uint256', 'uint8', 'uint256'],
      ))
    ) {
      try {
        const transaction = (await this.writeContracts({
          contracts: [
            {
              address: this.contract.address,
              abi: abi,
              functionName: 'buyPack',
              args: [_mintAmount, _packType, _poolId, _user],
              overrides,
            },
          ],
          capabilities,
        })) as TransactionResponse;

        return transaction;
      } catch (error) {
        console.error('Error in buyPack:', error);
        throw error;
      }
    } else {
      return (await this.contract.buyPack(
        _mintAmount,
        _packType,
        _poolId,
        _user,
        {
          ...overrides,
          value: overrides?.value ?? 0,
        },
      )) as TransactionResponse;
    }
  }

  async buyPacks(
    _packIds: number[],
    _quantities: ethers.BigNumberish[],
    _poolId: ethers.BigNumberish,
    _user: string,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
    capabilities?: any,
  ): Promise<boolean> {
    await this.ensureContractReady();

    const totalCost = await this.calculatePackCosts(_packIds, _quantities);

    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
        value: totalCost,
      };
    } else {
      overrides.value = totalCost;
    }

    if (
      capabilities &&
      (await this.canSponsor(
        'buyPacks',
        [_packIds, _quantities, _poolId],
        ['uint8[]', 'uint256[]', 'uint256'],
      ))
    ) {
      try {
        const transaction = (await this.writeContracts({
          contracts: [
            {
              address: this.contract.address,
              abi: abi,
              functionName: 'buyPacks',
              args: [_packIds, _quantities, _poolId, _user],
              overrides,
            },
          ],
          capabilities,
        })) as ContractTransaction;

        const receipt = await (transaction as any).wait();
        if (receipt.status === 1) {
          console.log('BuyPacks successfull');
          return true;
        }
      } catch (error) {
        console.error('Error in buyPacks:', error);
        throw error;
      }
    } else {
      console.log(`Ids: ${_packIds}, qs: ${_quantities}`);
      const tx = await this.contract.buyPacks(
        _packIds,
        _quantities,
        _poolId,
        _user,
        overrides,
      );

      const receipt = await (tx as any).wait();
      if (receipt.status === 1) {
        console.log('BuyPacks called successfully');
        return true;
      }
    }

    console.error('Transaction failed');
    return false;
  }

  async allocateGameFunds(
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
    capabilities?: any,
  ): Promise<TransactionResponse> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }
    if (capabilities && (await this.canSponsor('allocateGameFunds', [], []))) {
      try {
        const transaction = (await this.writeContracts({
          contracts: [
            {
              address: this.contract.address,
              abi: abi,
              functionName: 'allocateGameFunds',
              args: [],
              overrides,
            },
          ],
          capabilities,
        })) as TransactionResponse;

        return transaction;
      } catch (error) {
        console.error('Error in allocateGameFunds:', error);
        throw error;
      }
    } else {
      return (await this.contract.allocateGameFunds(
        overrides,
      )) as TransactionResponse;
    }
  }

  async lockNFT(
    player: string,
    id: ethers.BigNumberish,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
    capabilities?: any,
  ): Promise<TransactionResponse> {
    await this.ensureContractReady();

    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }
    if (
      capabilities &&
      (await this.canSponsor('lockNFT', [player, id], ['address', 'uint256']))
    ) {
      try {
        const transaction = (await this.writeContracts({
          contracts: [
            {
              address: this.contract.address,
              abi: abi,
              functionName: 'lockNFT',
              args: [player, id],
              overrides,
            },
          ],
          capabilities,
        })) as TransactionResponse;

        return transaction;
      } catch (error) {
        console.error('Error in lockNFT:', error);
        throw error;
      }
    } else {
      return (await this.contract.lockNFT(
        player,
        id,
        overrides,
      )) as TransactionResponse;
    }
  }

  // Non-payable methods

  async numberOfPacks(): Promise<number> {
    await this.ensureContractReady();
    const result = await this.contract.numberOfPacks();
    return Number(result);
  }

  async raffle(): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.raffle();
  }

  async game(): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.game();
  }

  async onlyWhitelisted(): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.onlyWhitelisted();
  }

  async paused(): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.paused();
  }

  async maxMintAmount(): Promise<ethers.BigNumberish> {
    await this.ensureContractReady();
    return await this.contract.maxMintAmount();
  }

  async stakingRewardsPool(): Promise<ethers.BigNumberish> {
    await this.ensureContractReady();
    return await this.contract.stakingRewardsPool();
  }

  async activePools(index: number): Promise<ethers.BigNumberish> {
    await this.ensureContractReady();
    return await this.contract.activePools(index);
  }

  async collectionPaused(collectionId: number): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.collectionPaused(collectionId);
  }

  async whitelistedAddresses(index: number): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.whitelistedAddresses(index);
  }

  async authorizedContracts(address: string): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.authorizedContracts(address);
  }

  async setStakingContract(_stakingContract: string): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setStakingContract(_stakingContract);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setAuthorizedContracts(
    contracts: string[],
    authorized: boolean,
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setAuthorizedContracts(
      contracts,
      authorized,
    );
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setNFTClaimer(
    claimer: string,
    tokenId: ethers.BigNumberish,
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setNFTClaimer(claimer, tokenId);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setOnlyWhitelisted(_state: boolean): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setOnlyWhitelisted(_state);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setMinter(_minter: string): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setMinter(_minter);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setmaxMintAmount(
    _newmaxMintAmount: ethers.BigNumberish,
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setmaxMintAmount(_newmaxMintAmount);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async isWhiteListed(_user: string): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.isWhiteListed(_user);
  }

  async setDistributorPaused(_state: boolean): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setDistributorPaused(_state);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setCollectionPaused(
    collectionId: ethers.BigNumberish,
    _state: boolean,
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setCollectionPaused(collectionId, _state);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async whitelistUsers(_users: string[]): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.whitelistUsers(_users);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setPack(
    _packId: number,
    _fee: ethers.BigNumberish,
    _tiers: number[],
    _defaultUriHash: string,
    _collection: number,
  ): Promise<boolean> {
    try {
      await this.ensureContractReady();
      console.log('setpack', {
        _packId,
        _fee,
        _tiers,
        _defaultUriHash,
        _collection,
      });
      const tx = await this.contract.setPack(
        _packId,
        ethers.parseUnits(_fee.toString(), 'ether'),
        _tiers,
        _defaultUriHash,
        _collection,
      );

      const receipt = await (tx as any).wait();
      if (receipt.status === 1) {
        console.log('Tokens added successfully');
        return true;
      }

      console.error('Transaction failed');
      return false;
    } catch (error) {
      console.log(`Failed to call ${this.setPack.name}\n Error: ${error}`);
    }
  }

  async setNumberOfPacks(_packCount: ethers.BigNumberish): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setNumberOfPacks(_packCount);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async distributeRewardToStaker(
    _user: string,
    reward: ethers.BigNumberish,
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.distributeRewardToStaker(_user, reward);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async returnTokenToPool(
    tokenId: ethers.BigNumberish,
    poolId: ethers.BigNumberish,
    tierId: number,
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.returnTokenToPool(tokenId, poolId, tierId);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async getAvailableTokensPerTier(
    _tier: number,
  ): Promise<ethers.BigNumberish[]> {
    await this.ensureContractReady();
    return await this.contract.getAvailableTokensPerTier(_tier);
  }

  async getNextAvailableTokenId(
    _poolId: ethers.BigNumberish,
    _tierId: number,
  ): Promise<ethers.BigNumberish> {
    await this.ensureContractReady();
    return await this.contract.getNextAvailableTokenId(_poolId, _tierId);
  }

  async assignAvailableTokensToPoolAndTier(
    _poolId: ethers.BigNumberish,
    _tierId: number,
    _tokenIds: ethers.BigNumberish[],
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.assignAvailableTokensToPoolAndTier(
      _poolId,
      _tierId,
      _tokenIds,
    );

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setAvailableTokensToPoolAndTier(
    _poolId: ethers.BigNumberish,
    _tierId: number,
    _tokenIds: ethers.BigNumberish[],
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setAvailableTokensToPoolAndTier(
      _poolId,
      _tierId,
      _tokenIds,
    );
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async getAvailableTokensByPoolAndTier(
    _poolId: ethers.BigNumberish,
    _tierId: number,
  ): Promise<ethers.BigNumberish[]> {
    await this.ensureContractReady();
    return await this.contract.getAvailableTokensByPoolAndTier(
      _poolId,
      _tierId,
    );
  }

  async claimNFT(claimer: string): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.claimNFT(claimer);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async getRandomTier(): Promise<number> {
    await this.ensureContractReady();
    const result = await this.contract.getRandomTier();
    return Number(result);
  }

  async randomNum(
    _boundary: ethers.BigNumberish,
  ): Promise<ethers.BigNumberish> {
    await this.ensureContractReady();
    return await this.contract.randomNum(_boundary);
  }

  async walletOfOwner(_owner: string): Promise<ethers.BigNumberish[]> {
    await this.ensureContractReady();
    return await this.contract.walletOfOwner(_owner);
  }

  async getPack(_packType: number): Promise<Pack> {
    await this.ensureContractReady();
    const pack = await this.contract.getPack(_packType);
    return {
      packId: Number(pack.packId),
      tiers: pack.tiers.map((tier: ethers.BigNumberish) => Number(tier)),
      defaultUriHash: pack.defaultUriHash,
      fee: Number(pack.fee),
      collection: Number(pack.collection),
      active: pack.active,
    };
  }

  async setPackFee(
    _packId: number,
    _newFeeInEther: ethers.BigNumberish,
  ): Promise<boolean> {
    await this.ensureContractReady();
    const tx = await this.contract.setPackFee(_packId, _newFeeInEther);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  // Implement other event listeners as needed
  //Custom Methods
  async getAllPacks(): Promise<Pack[]> {
    await this.ensureContractReady();
    // Fetch and return all available packs

    try {
      const availablePacks = Number(await this.contract.numberOfPacks());

      if (!availablePacks || availablePacks === 0) return [];
      let packs: Pack[] = [];

      for (let index = 0; index <= availablePacks - 1; index++) {
        try {
          let pack = await this.contract.getPack(index);
          if (!pack || pack.tiers.length == 0) {
            // console.log(
            //   `Error: PackID: ${index} does not have an available pack`,
            // );
          } else {
            packs.push({
              packId: Number(pack.packId),
              tiers: pack.tiers.map((t) => Number(t)),
              defaultUriHash: pack.defaultUriHash,
              fee: parseFloat(ethers.formatEther(pack.fee)),
              collection: Number(pack.collection),
              active: pack.active,
            });
          }
        } catch (error) {
          //console.error(`Failed to fetch pack with index ${index}:`, error);
        }
      }
      // console.log('packs:', packs);
      if (packs && packs.length > 0) {
        return packs;
      }
    } catch (error) {
      //console.error(`Failed to fetch packs:`, error);
    }

    return [];
  }

  async getSigner(): Promise<ethers.JsonRpcSigner> {
    await this.ensureContractReady();
    return this.signer;
  }
}
