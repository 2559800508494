import { BigNumberish, Contract, ethers } from 'ethers';

export const RaffleContractAddress =
  '0x8388B9864cDf48369f4f225cC926AD13Fe6c8A7d';

// State definition matching the contract's State struct
export interface State {
  raffleId: BigNumberish;
  size: BigNumberish;
  bought: BigNumberish;
  maxWinners: number;
  price: BigNumberish;
  isClosed: boolean;
  packId: BigNumberish;
  participants: string[];
}

export type IRaffleContract = Contract & {
  // Public Variables as Functions
  distributorAddress(): Promise<string>;
  claims(): Promise<BigNumberish>;
  winners(address: string): Promise<BigNumberish>;
  getRaffleState(id: number): Promise<State>;
  numberOfRaffles(): Promise<BigNumberish>;
  states(id: number): Promise<State>;
  raffles(id: BigNumberish, index: number): Promise<string>;

  // Interactable Functions
  addRaffle(
    size: BigNumberish,
    maxWinners: BigNumberish,
    price: BigNumberish,
    packId: BigNumberish,
  ): Promise<ethers.ContractTransaction>;
  buyRaffle(
    id: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ethers.ContractTransaction>;
  claimRewards(): Promise<ethers.ContractTransaction>;
  setPrice(
    id: BigNumberish,
    price: BigNumberish,
  ): Promise<ethers.ContractTransaction>;
  setSize(
    id: BigNumberish,
    size: BigNumberish,
  ): Promise<ethers.ContractTransaction>;
  setDistributorContract(
    _distributorAddress: string,
  ): Promise<ethers.ContractTransaction>;
  endRaffleManually(
    id: BigNumberish,
    force: boolean,
  ): Promise<ethers.ContractTransaction>;
  setRaffleClosedState(
    id: BigNumberish,
    closed: boolean,
  ): Promise<ethers.ContractTransaction>;

  // Events
  on(
    event: 'AnnounceRaffleWinner',
    listener: (winner: string) => void,
  ): IRaffleContract;

  on(
    event: 'AnnounceRaffleWinners',
    listener: (winners: string[]) => void,
  ): IRaffleContract;

  // Add other events as needed
};
