import { ethers } from 'ethers';
import BattleHelper from 'Helpers/BattleHelper';
import { DistributorHelper } from 'Helpers/DistributorContractHelper';
import { MinterHelper } from 'Helpers/MinterContractHelper';
import toast from 'react-hot-toast';

export type Nav = {
  iconClass: string;
  navName: string;
  type: string;
  args?: { name: string; type: string }[];
  method: (nav: Nav) => Promise<boolean | string | number>;
  contractType: 'minter' | 'distributor' | 'battle'; // New property added to specify contract type
};

const executeContractMethod = async (
  nav: Nav,
): Promise<boolean | string | number> => {
  try {
    console.log('methodArgs', nav.navName);
    console.log('args', nav.args);

    const parsedArgs = parseArguments(nav);
    console.log('parseArguments', parsedArgs);

    const parsedArgsArray = convertParsedArgsToArray(parsedArgs);
    console.log('parsedArgsArray', parsedArgsArray);
    return await handleContractCall(parsedArgsArray, nav);
  } catch (error: any) {
    toast.error(`Error executing ${nav.navName}: ${error.message}`);
  } finally {
  }
};

const handleContractCall = async (
  args: any[],
  nav: Nav,
): Promise<boolean | string | number> => {
  let contract;
  switch (nav.contractType) {
    case 'minter':
      contract = new MinterHelper();
      break;
    case 'distributor':
      contract = new DistributorHelper();
      break;
    case 'battle':
      contract = new BattleHelper();
      break;
    default:
      throw new Error('Invalid contract type specified');
  }

  //ui needs work to show each step
  if (nav.navName === 'setPack') {
    for (const parsedArgs of args) {
      try {
        const convertedArgs = convertParsedArgsToArray(parsedArgs);
        console.log('parsedArgs => Array', convertedArgs);

        // Await each call and check for the transaction response
        const transactionResponse = await contract[nav.navName](
          ...convertedArgs,
        );
        console.log(
          `Transaction submitted successfully. Waiting for confirmation for args:`,
          convertedArgs,
        );

        // Await the transaction receipt to confirm it's mined
        const receipt = await transactionResponse.wait();
        if (receipt && receipt.status === 1) {
          console.log(
            `Method ${nav.navName} executed successfully for args:`,
            convertedArgs,
          );
        } else {
          console.error(`Transaction failed for args:`, convertedArgs);
          toast.error(
            `Transaction failed during execution of ${nav.navName} for args: ${convertedArgs}`,
          );
          // Optional: Add retry logic or break if required
        }
      } catch (error) {
        console.error(
          `Failed to call ${nav.navName} for args: ${parsedArgs}`,
          error,
        );
        toast.error(`Error executing ${nav.navName}: ${error}`);
        // You could also decide to stop further calls here or continue with the next one
      }
    }
  }

  //maybe one for setting games in bulk

  try {
    const response: boolean | string | number = await contract[nav.navName](
      ...args,
    );

    if (typeof response === 'boolean' && response === true) {
      toast.success(`Method ${nav.navName} executed successfully`);
    } else if (typeof response === 'string' && response.trim() !== '') {
      toast.success(
        `Method ${nav.navName} executed successfully. Response: ${response}`,
      );
    } else if (typeof response === 'number') {
      toast.success(
        `Method ${nav.navName} executed successfully. Response number: ${response}`,
      );
    } else {
      toast.error(
        `Unexpected response type or value for method ${nav.navName}: ${response}`,
      );
    }
    return response;
  } catch (error) {
    console.error(`Failed to execute method ${nav.navName}`, error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};

// Function to convert parsed arguments into an array dynamically
const convertParsedArgsToArray = (parsedArgs: any): any[] => {
  if (Array.isArray(parsedArgs)) {
    // If parsedArgs is already an array, return as is
    return parsedArgs;
  } else if (typeof parsedArgs === 'object' && parsedArgs !== null) {
    // If parsedArgs is an object, extract values into an array
    return Object.values(parsedArgs);
  } else {
    // If parsedArgs is a single value, wrap it in an array
    return [parsedArgs];
  }
};

const parseArguments = (nav: Nav) => {
  let parsedArgs: any;

  switch (nav.navName) {
    case 'addTokensToPoolByTier':
      parsedArgs = parseaddTokensToPoolByTier(nav.args);
      break;

    case 'assignPoolDistributions':
      parsedArgs = parseAssignPoolDistributions(nav.args);
      break;

    case 'setBoostToken':
      parsedArgs = parseSetBoostToken(nav.args);
      break;

    case 'setBoostTokens':
      parsedArgs = parseSetBoostTokens(nav.args);
      break;

    case 'removeBoostToken':
      parsedArgs = parseRemoveBoostToken(nav.args);
      break;

    case 'removeBoostTokens':
      parsedArgs = parseRemoveBoostTokens(nav.args);
      break;

    case 'unsetTier':
      parsedArgs = parseUnsetTier(nav.args);
      break;

    case 'setNumberOfPacks':
      parsedArgs = parseSetNumberOfPacks(nav.args);
      break;

    case 'setPack':
      parsedArgs = parseSetPack(nav.args);
      break;

    case 'setmaxMintAmount':
      parsedArgs = parseSetMaxMintAmount(nav.args);
      break;

    case 'setBaseURI':
      parsedArgs = parseSetBaseURI(nav.args);
      break;

    case 'setBaseExtension':
      parsedArgs = parseSetBaseExtension(nav.args);
      break;

    case 'setOnlyWhitelisted':
      parsedArgs = parseSetOnlyWhitelisted(nav.args);
      break;

    case 'whitelistUsers':
      parsedArgs = parseWhitelistUsers(nav.args);
      break;

    case 'assignCurrentCollection':
      parsedArgs = parseAssignCurrentCollection(nav.args);
      break;

    case 'setPackFee':
      parsedArgs = parseSetPackFee(nav.args);
      break;

    case 'setDistributorPaused':
      parsedArgs = parsePause(nav.args);
      break;
    case 'setCollectionPaused':
      parsedArgs = parseSetCollectionPaused(nav.args);
      break;
    case 'withdraw':
      parsedArgs = parseWithdraw(nav.args);
      break;

    case 'createGameMode':
      parsedArgs = parseCreateGameMode(nav.args);
      break;

    case 'updateGameMode':
      parsedArgs = parseUpdateGameMode(nav.args);
      break;

    case 'setDistributorContract':
    case 'setMinterContract':
      parsedArgs = parseSetContractAddress(nav.args);
      break;

    default:
      console.warn(`No parsing function defined for ${nav.navName}`);
      parsedArgs = nav.args;
      break;
  }

  return parsedArgs;
};

const parseaddTokensToPoolByTier = (
  args: any[],
): {
  poolId: number;
  tierId: number;
  ids: number[];
  attributes: number[][];
} => {
  // Define the structure of the parsed result
  const parsedResult: {
    poolId: number;
    tierId: number;
    ids: number[];
    attributes: number[][];
  } = {
    poolId: null,
    tierId: null,
    ids: [],
    attributes: [],
  };

  try {
    // Loop through each argument to parse them
    for (const arg of args) {
      let argArray;

      // If `arg` is a string, split it by comma
      if (typeof arg === 'string') {
        argArray = arg.split(',').map((value) => value.trim());
      } else if (Array.isArray(arg)) {
        argArray = arg;
      } else {
        console.error('Unexpected argument type:', arg);
        continue;
      }

      // Extract individual values from the array
      const [_poolId, _tierId, _Id, a, b, c] = argArray;

      // Parse individual arguments to convert them to numbers
      const parsedPoolId = Number(_poolId);
      const parsedTierId = Number(_tierId);
      const parsedId = Number(_Id);
      const parsedAttributes = [Number(a), Number(b), Number(c)];

      // Ensure the parsed values are numbers
      if (isNaN(parsedPoolId) || isNaN(parsedTierId) || isNaN(parsedId)) {
        console.error('Error parsing numbers for poolId, tierId, or id:', {
          _poolId,
          _tierId,
          _Id,
        });
        continue; // Skip this iteration if parsing fails
      }

      parsedAttributes.forEach((value, index) => {
        if (isNaN(value)) {
          console.error(
            `Attribute at index ${index} is NaN for argument:`,
            arg,
          );
        }
      });

      // Ensure all poolIds are the same for consistency
      if (parsedResult.poolId === null) {
        parsedResult.poolId = parsedPoolId;
      } else if (parsedResult.poolId !== parsedPoolId) {
        throw new Error(
          `Inconsistent poolId found: ${parsedPoolId}. Expected: ${parsedResult.poolId}`,
        );
      }

      // Ensure all tierIds are the same for consistency
      if (parsedResult.tierId === null) {
        parsedResult.tierId = parsedTierId;
      } else if (parsedResult.tierId !== parsedTierId) {
        throw new Error(
          `Inconsistent tierId found: ${parsedTierId}. Expected: ${parsedResult.tierId}`,
        );
      }

      // Add the parsed tokenId and attributes to the respective arrays
      parsedResult.ids.push(parsedId);
      parsedResult.attributes.push(parsedAttributes);
    }
    return parsedResult;
  } catch (error) {
    console.log(`error: ${error}`);
    return null;
  }
};

const parseAssignPoolDistributions = (
  args: any[],
): { poolIds: number[]; whiteListedAddresss: number[][] } => {
  const parsedResult: { poolIds: number[]; whiteListedAddresss: number[][] } = {
    poolIds: [],
    whiteListedAddresss: [],
  };

  // Create a map to track whiteListedAddresss by their poolId
  const poolMap: { [key: number]: number[] } = {};

  for (const arg of args) {
    const [address, poolId] = arg;

    const parsedTokenId = Number(address);
    const parsedPoolId = Number(poolId);

    // Ensure poolMap contains an entry for the current poolId
    if (!poolMap[parsedPoolId]) {
      poolMap[parsedPoolId] = [];
    }

    // Add the address to the corresponding poolId array
    poolMap[parsedPoolId].push(parsedTokenId);
  }

  // Convert the poolMap to the desired structure
  for (const [poolId, whiteListedAddresss] of Object.entries(poolMap)) {
    parsedResult.poolIds.push(Number(poolId));
    parsedResult.whiteListedAddresss.push(whiteListedAddresss);
  }

  return parsedResult;
};

const parseSetBoostToken = (args: any[]) => {
  const parsedResult: {
    address: number;
    boostAttribute: number;
    boostPoint: number;
  } = {
    address: 0,
    boostAttribute: 0,
    boostPoint: 0,
  };

  parsedResult.address = args[0];
  parsedResult.boostAttribute = args[1];
  parsedResult.boostPoint = args[2];

  Object.entries(parsedResult).forEach((entry) => {
    if (entry[1] === 0) return null;
  });

  return parsedResult;
};

const parseSetBoostTokens = (
  args: any[],
): {
  tokenIds: number[];
  boostAttributes: number[];
  boostPoints: number[];
} => {
  const parsedResult: {
    tokenIds: number[];
    boostAttributes: number[];
    boostPoints: number[];
  } = {
    tokenIds: [],
    boostAttributes: [],
    boostPoints: [],
  };

  // Loop through each argument and add them to respective arrays directly
  for (const arg of args) {
    const { tokenIds, boostAttributes, boostPoints } = arg;

    parsedResult.tokenIds.push(Number(tokenIds));
    parsedResult.boostAttributes.push(Number(boostAttributes));
    parsedResult.boostPoints.push(Number(boostPoints));
  }

  return parsedResult;
};

const parseRemoveBoostToken = (args: any[]) => {
  const parsedResult: {
    address: number;
  } = {
    address: 0,
  };

  for (const arg of args) {
    const [address] = arg;
    parsedResult.address = Number(address);
  }

  if (parsedResult.address === 0) return null;
  return parsedResult;
};

const parseRemoveBoostTokens = (args: any[]) => {
  const parsedResult: {
    tokenIds: number[];
  } = {
    tokenIds: [],
  };

  for (const arg of args) {
    const [address] = arg;
    if (parsedResult.tokenIds.includes(address)) return null;
    parsedResult.tokenIds.push(Number(address));
  }

  return parsedResult;
};

const parseUnsetTier = (args: any[]) => {
  const parsedResult: {
    address: number;
  } = {
    address: 0,
  };

  for (const arg of args) {
    const [address] = arg;
    parsedResult.address = Number(address);
  }

  if (parsedResult.address === 0) return null;
  return parsedResult;
};

const parseSetNumberOfPacks = (args: any[]) => {
  const parsedResult: {
    packCount: number;
  } = {
    packCount: 0,
  };

  for (const arg of args) {
    const [address] = arg;
    parsedResult.packCount = Number(address);
  }

  if (parsedResult.packCount === 0) return null;
  return parsedResult;
};

const parseSetPack = (
  args: any[],
): Array<{
  packId: number;
  fee: number;
  tiers: number[];
  baseUri: string;
  collection: number;
}> => {
  // Array to hold parsed results for each line
  const parsedResults: Array<{
    packId: number;
    fee: number;
    tiers: number[];
    baseUri: string;
    collection: number;
  }> = [];

  for (const arg of args) {
    const [_packId, _fee, _tiers, _baseUri, _collection] = arg;

    const parsedPackId = Number(_packId);
    const parsedFee = parseFloat(_fee);
    const parsedTiers = JSON.parse(_tiers);
    const parsedBaseUri = String(_baseUri);
    const parsedCollection = Number(_collection);

    parsedResults.push({
      packId: parsedPackId,
      fee: parsedFee,
      tiers: parsedTiers,
      baseUri: parsedBaseUri,
      collection: parsedCollection,
    });
  }

  return parsedResults;
};

const parseSetMaxMintAmount = (args: any[]) => {
  const parsedResult: {
    maxMint: number;
  } = {
    maxMint: 0,
  };

  const [mintAmount] = args;

  const parsedMaxMint = Number(mintAmount);
  if (parsedMaxMint === 0) return;
  parsedResult.maxMint = parsedMaxMint;

  return parsedResult;
};

const parseSetBaseURI = (args: any[]) => {
  const parsedResult: {
    baseUri: string;
  } = {
    baseUri: '',
  };

  const [baseUri] = args;

  const parsedBaseUri = String(baseUri);

  parsedResult.baseUri = parsedBaseUri;

  return parsedResult;
};

const parseSetBaseExtension = (args: any[]) => {
  const parsedResult: {
    baseExtension: string;
  } = {
    baseExtension: '',
  };

  const [baseExtension] = args;
  console.log('baseExtension', baseExtension);
  const parsedBaseUriExtension = String(baseExtension);

  parsedResult.baseExtension = parsedBaseUriExtension;

  return parsedResult;
};

const parsePause = (args: any[]) => {
  const parsedResult: {
    pause: boolean;
  } = {
    pause: false,
  };

  const [pause] = args;

  const parsedBool = Boolean(pause);

  parsedResult.pause = parsedBool;

  return parsedResult;
};

const parseSetOnlyWhitelisted = (args: any[]) => {
  const parsedResult: {
    whiteListOnly: string;
  } = {
    whiteListOnly: '',
  };

  const [whiteListOnly] = args;

  const parsedWhiteListOnly = String(whiteListOnly);

  parsedResult.whiteListOnly = parsedWhiteListOnly;

  return parsedResult;
};

const parseWhitelistUsers = (args: any[]) => {
  const parsedResult: {
    whiteListedAddresss: string[];
  } = {
    whiteListedAddresss: [],
  };

  for (const arg of args) {
    const [address] = arg;
    if (parsedResult.whiteListedAddresss.includes(address)) return null;
    parsedResult.whiteListedAddresss.push(String(address));
  }

  return parsedResult;
};

const parseAssignCurrentCollection = (args: any[]) => {
  const parsedResult: {
    currentCollectionNum: number;
  } = {
    currentCollectionNum: 0,
  };

  const [collectionNum] = args;
  parsedResult.currentCollectionNum = Number(collectionNum);

  return parsedResult;
};

const parseSetPackFee = (args: any[]) => {
  const parsedResult: {
    packId: number;
    feeInEther: ethers.BigNumberish;
  } = {
    packId: 0,
    feeInEther: '0',
  };

  const [packId, feeInEth] = args;
  parsedResult.packId = Number(packId);
  parsedResult.feeInEther = ethers.parseUnits(feeInEth.toString(), 'ether');

  return parsedResult;
};

const parseWithdraw = (args: any[]) => {
  /* logic here */
};

// Parsing for createGameMode
const parseCreateGameMode = (args: any[]): any => {
  const parsedResult: {
    participantSize: ethers.BigNumberish;
    gameFee: ethers.BigNumberish;
    cardsPerPlayer: ethers.BigNumberish;
    canUseBoost: boolean;
    maxBoostersPerCard: ethers.BigNumberish;
    gameIPFS: string;
    attributeGuessOption: boolean;
    guessBonus: ethers.BigNumberish;
    tier: ethers.BigNumberish;
    pvc: boolean;
  } = {
    participantSize: Number(args[0]),
    gameFee: ethers.parseUnits(args[1], 'ether'), // Ensure `gameFee` is properly parsed as ether value
    cardsPerPlayer: Number(args[2]),
    canUseBoost: Boolean(args[3]),
    maxBoostersPerCard: Number(args[4]),
    gameIPFS: String(args[5]),
    attributeGuessOption: Boolean(args[6]),
    guessBonus: Number(args[7]),
    tier: Number(args[8]),
    pvc: Boolean(args[10]),
  };

  const [
    participantSize,
    gameFee,
    cardsPerPlayer,
    canUseBoost,
    maxBoostersPerCard,
    gameIPFS,
    attributeGuessOption,
    guessBonus,
    tier,
    pvc,
  ] = args;

  parsedResult.participantSize = Number(participantSize);
  parsedResult.gameFee = ethers.parseUnits(gameFee.toString(), 'ether');
  parsedResult.cardsPerPlayer = Number(cardsPerPlayer);
  parsedResult.canUseBoost = Boolean(canUseBoost);
  parsedResult.maxBoostersPerCard = Number(maxBoostersPerCard);
  parsedResult.gameIPFS = gameIPFS.toString();
  parsedResult.attributeGuessOption = Boolean(attributeGuessOption);
  parsedResult.guessBonus = Number(guessBonus);
  parsedResult.tier = Number(tier);
  parsedResult.pvc = Boolean(pvc);

  return parsedResult;
};

// Parsing for updateGameMode (same structure as createGameMode but with `gameId`)
const parseUpdateGameMode = (args: any[]): any => {
  const parsedResult: {
    gameId: ethers.BigNumberish;
    participantSize: ethers.BigNumberish;
    gameFee: ethers.BigNumberish;
    cardsPerPlayer: ethers.BigNumberish;
    canUseBoost: boolean;
    maxBoostersPerCard: ethers.BigNumberish;
    gameIPFS: string;
    attributeGuessOption: boolean;
    guessBonus: ethers.BigNumberish;
    tier: ethers.BigNumberish;
    pvc: boolean;
  } = {
    gameId: Number(args[0]),
    participantSize: Number(args[1]),
    gameFee: ethers.parseUnits(args[2], 'ether'), // Ensure `gameFee` is properly parsed as ether value
    cardsPerPlayer: Number(args[3]),
    canUseBoost: Boolean(args[4]),
    maxBoostersPerCard: Number(args[5]),
    gameIPFS: String(args[6]),
    attributeGuessOption: Boolean(args[7]),
    guessBonus: Number(args[8]),
    tier: Number(args[9]),
    pvc: Boolean(args[10]),
  };

  const [
    gameId,
    participantSize,
    gameFee,
    cardsPerPlayer,
    canUseBoost,
    maxBoostersPerCard,
    gameIPFS,
    attributeGuessOption,
    guessBonus,
    tier,
    pvc,
  ] = args;

  parsedResult.gameId = Number(gameId);
  parsedResult.participantSize = Number(participantSize);
  parsedResult.gameFee = ethers.parseUnits(gameFee.toString(), 'ether');
  parsedResult.cardsPerPlayer = Number(cardsPerPlayer);
  parsedResult.canUseBoost = Boolean(canUseBoost);
  parsedResult.maxBoostersPerCard = Number(maxBoostersPerCard);
  parsedResult.gameIPFS = gameIPFS.toString();
  parsedResult.attributeGuessOption = Boolean(attributeGuessOption);
  parsedResult.guessBonus = Number(guessBonus);
  parsedResult.tier = Number(tier);
  parsedResult.pvc = Boolean(pvc);
  return parsedResult;
};

const parseSetCollectionPaused = (args: any[]): any => {
  const parsedResult: {
    collectionId: ethers.BigNumberish;
    paused: boolean;
  } = {
    collectionId: 0,
    paused: true,
  };

  const [collectionId, paused] = args;

  parsedResult.collectionId = Number(collectionId);
  parsedResult.paused = Boolean(paused);

  return parsedResult;
};
// Parsing for setDistributorContract and setMinterContract
const parseSetContractAddress = (args: any[]): any => {
  return {
    newContract: String(args[0]),
  };
};

const navs: Nav[] = [
  {
    iconClass: 'fa fa-edit',
    navName: 'baseURI',
    type: 'form',
    args: [],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-file-import',
    navName: 'Discover',
    type: '',
    method: null,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-plus-circle',
    navName: 'addTokensToPoolByTier',
    type: 'import',
    args: [
      { name: '_poolId', type: 'number' },
      { name: '_tierId', type: 'number' },
      { name: '_Id', type: 'number' },
      { name: 'a', type: 'number' },
      { name: 'b', type: 'number' },
      { name: 'c', type: 'number' },
    ],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa-regular fa-folder',
    navName: 'addTokensToPoolByTier',
    type: 'import-bulk',
    args: [
      { name: '_poolId', type: 'number' },
      { name: '_tierId', type: 'number' },
      { name: '_Id', type: 'number' },
      { name: 'a', type: 'number' },
      { name: 'b', type: 'number' },
      { name: 'c', type: 'number' },
    ],
    method: executeContractMethod,
    contractType: 'minter',
  },
  // {
  //   iconClass: 'fa fa-file-import',
  //   navName: 'addAvailableTokensToPoolAndTier',
  //   type: 'import',
  //   args: [
  //     { name: 'poolId', type: 'number' },
  //     { name: '_tierId', type: 'number' },
  //     { name: '_tokenIds', type: 'number[]' },
  //   ],
  //   method: executeContractMethod,
  //   contractType: 'distributor',
  // },
  {
    iconClass: 'fa fa-edit',
    navName: 'setBoostToken',
    type: 'form',
    args: [
      { name: 'tokenId', type: 'number' },
      { name: 'boostAttribute', type: 'number' },
      { name: 'boostPoint', type: 'number' },
    ],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-file-import',
    navName: 'setBoostTokens',
    type: 'import',
    args: [
      { name: 'tokenIds', type: 'number' },
      { name: 'boostAttributes', type: 'number' },
      { name: 'boostPoints', type: 'number' },
    ],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'removeBoostToken',
    type: 'form',
    args: [{ name: 'tokenId', type: 'number' }],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-file-import',
    navName: 'removeBoostTokens',
    type: 'import',
    args: [{ name: 'tokenIds', type: 'number[]' }],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'unsetPoolTier',
    type: 'form',
    args: [
      { name: '_poolId', type: 'number' },
      { name: '_tierId', type: 'number' },
      { name: '_tokenId', type: 'number' },
    ],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setNumberOfPacks',
    type: 'form',
    args: [{ name: '_packCount', type: 'number' }],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-file-import',
    navName: 'setPack',
    type: 'import',
    args: [
      { name: '_packId', type: 'number' },
      { name: '_fee', type: 'number' },
      { name: '_tiers', type: 'number[]' },
      { name: '_defaultUriHash', type: 'string' },
      { name: '_collection', type: 'number' },
    ],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-file-import',
    navName: 'setCollectionPaused',
    type: 'form',
    args: [
      { name: 'collectionId', type: 'number' },
      { name: '_state', type: 'checkbox' },
    ],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setmaxMintAmount',
    type: 'form',
    args: [{ name: '_newmaxMintAmount', type: 'number' }],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setBaseURI',
    type: 'form',
    args: [{ name: '_newBaseURI', type: 'string' }],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setBaseExtension',
    type: 'form',
    args: [{ name: '_newBaseExtension', type: 'string' }],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setDistributorPaused',
    type: 'form',
    args: [{ name: '_state', type: 'checkbox' }],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setOnlyWhitelisted',
    type: 'form',
    args: [{ name: '_state', type: 'checkbox' }],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-file-import',
    navName: 'whitelistUsers',
    type: 'import',
    args: [{ name: '_users', type: 'string[]' }],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setCollectionBaseURI',
    type: 'form',
    args: [
      { name: 'collectionId', type: 'number' },
      { name: '_collectionbaseURI', type: 'string' },
    ],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setPackFee',
    type: 'form',
    args: [
      { name: '_packId', type: 'number' },
      { name: '_newFeeInEther', type: 'number' },
    ],
    method: executeContractMethod,
    contractType: 'distributor',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'withdraw',
    type: 'form',
    args: [],
    method: executeContractMethod,
    contractType: 'minter',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'createGameMode',
    type: 'form',
    args: [
      { name: 'participantSize', type: 'number' },
      { name: 'gameFee', type: 'number' },
      { name: 'cardsPerPlayer', type: 'number' },
      { name: 'canUseBoost', type: 'checkbox' },
      { name: 'maxBoostersPerCard', type: 'number' },
      { name: 'gameIPFS', type: 'string' },
      { name: 'attributeGuessOption', type: 'checkbox' },
      { name: 'guessBonus', type: 'number' },
      { name: 'tier', type: 'number' },
      { name: 'pvc', type: 'checkbox' },
    ],
    method: executeContractMethod,
    contractType: 'battle',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'updateGameMode',
    type: 'form',
    args: [
      { name: 'gameId', type: 'number' },
      { name: 'participantSize', type: 'number' },
      { name: 'gameFee', type: 'number' },
      { name: 'cardsPerPlayer', type: 'number' },
      { name: 'canUseBoost', type: 'checkbox' },
      { name: 'maxBoostersPerCard', type: 'number' },
      { name: 'gameIPFS', type: 'string' },
      { name: 'attributeGuessOption', type: 'checkbox' },
      { name: 'guessBonus', type: 'number' },
      { name: 'tier', type: 'number' },
      { name: 'pvc', type: 'checkbox' },
    ],
    method: executeContractMethod,
    contractType: 'battle',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setDistributorContract',
    type: 'form',
    args: [{ name: 'newDistributor', type: 'string' }],
    method: executeContractMethod,
    contractType: 'battle',
  },
  {
    iconClass: 'fa fa-edit',
    navName: 'setMinterContract',
    type: 'form',
    args: [{ name: 'newMinter', type: 'string' }],
    method: executeContractMethod,
    contractType: 'battle',
  },
];

export default navs;
