import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import InfoPane from 'components/InformationPane/InfoPane';
import Showcase from 'components/ShowCase/Showcase';
import { Section } from './styledTags';
import { MinterHelper } from 'Helpers/MinterContractHelper';
import OwnerDapp, { PackDetail } from './OwnerDapp';
import { useWeb3 } from 'Web3Context';

const CollectionDapp = () => {
  const [isContractOwner, setIsContractOwner] = useState<boolean>(false);
  const [tokenAddress, setTokenAddress] = useState<string>(null);
  const { provider, account, capabilities, isOwner, connectWallet } = useWeb3();

  useEffect(() => {
    (async () => {
      if (typeof window.ethereum !== 'undefined') {
        // const contract = new MinterHelper();
        // connectWallet();
        // const owner = await contract.isOwner();
        setIsContractOwner(isOwner);
      }
    })();
  }, [isOwner]);

  const handleOwnerFunctions = async (action: Function, ...args: any[]) => {
    try {
      if (isContractOwner) {
        await action(...args);
      } else {
        toast.error(
          'Permission Denied: Only the contract owner can perform this action.',
        );
      }
    } catch (error: any) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const getAllTokens = async () => {
    await handleOwnerFunctions(async () => {
      // const contract = new MinterHelper();
      // const balance = await contract.totalSupply();
      // toast.success(`AllToken balance: ${balance.toString()}`);
    });
  };

  const getMyTokens = async () => {
    await handleOwnerFunctions(async () => {
      const contract = new MinterHelper();
      const balance = await contract.getMyTokens();
      toast.success(`MyToken balance: ${balance.length.toString()}`);
    });
  };

  const getTokenInfo = async () => {
    await handleOwnerFunctions(async () => {
      const contract = new MinterHelper();
      const balance = 'calling balance CollectionDap';
      toast.success(`TokenInfo: ${balance}`);
    });
  };

  return (
    <>
      {isContractOwner ? (
        // <Section id="dapp">
        //   <InfoPane
        //     tokenAddress={tokenAddress}
        //     getMyTokens={getMyTokens}
        //     getTokenInfo={getTokenInfo}
        //     getAllTokens={getAllTokens}
        //   />
        // </Section>
        <OwnerDapp
          packDetails={[]}
          cart={[]}
          onPurchasePacks={function (packs: PackDetail[]): Promise<void> {
            throw new Error('Function not implemented.');
          }}
          onAddToCart={function (pack: PackDetail): Promise<void> {
            throw new Error('Function not implemented.');
          }}
          onUpdateCart={function (pack: PackDetail[]): void {
            throw new Error('Function not implemented.');
          }}
        />
      ) : (
        // <Section id="dapp">
        //   <ControlImport />
        //   <ControlForm />
        // </Section>
        <div
          style={{
            margin: 'auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '100vh',
            position: 'absolute',
          }}
        >
          Permission Denied: Only the Owner of the Contract can Access
        </div>
      )}
      <Showcase />
    </>
  );
};

export default CollectionDapp;
