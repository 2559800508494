import { Container, Section } from "./styledTags";
import Tier4 from "assets/Tierage/tier4.png";
import T4 from "assets/About/Tier_4_Gif.gif";
import TimeDisplay from "components/Clock/BackToTheFuture";
import Slider, { DataItem } from "components/Carousel/Slider";
const addvert = require("../../assets/Video/Addvert.mp4");

const About = () => {
  //carousol slider data test
  const data: DataItem[] = [
    {
      name: "Tier 4 - Fighter Chronicles",
      information: {
        Created: "",
        Dimensions: "",
        Resolution: "",
        ColorSpace: "",
        ColorProfile: "",
      },
      description: "somthing something something",
      facts: [
      ],
      photo: T4,
      video: addvert,
      slider: Tier4,
      renderFrag: TimeDisplay,
    },
  ];

  return (
    <Section id="about">
      <Container>
        {/* <Box>
          <Suspense fallback={<Loading />}>
            <Carousel />
          </Suspense>
        </Box> */}
        {/* <SelectionPage {...selections} /> */}
        <Slider data={data} sliderClass="timecomp" contentClass="fullscreen"/>
        {/* <Carousel/> */}
      </Container>
    </Section>
  );
};

export default About;
