import { BigNumberish, Contract, ContractTransaction, ethers } from 'ethers';

export const DistributorContractAddress =
  '0x7C13750B3C1A435516F9de1de8eadF3880388198';

export interface Pack {
  packId: BigNumberish;
  tiers: BigNumberish[];
  defaultUriHash: string;
  fee: BigNumberish;
  collection: BigNumberish;
  active: boolean;
}

export type IDistributorContract = Contract & {
  // Public Variables as Functions
  numberOfPacks(): Promise<any>;
  raffleContract(): Promise<string>;
  battleContract(): Promise<string>;
  lastManstandingContract(): Promise<string>;
  onlyWhitelisted(): Promise<boolean>;
  paused(): Promise<boolean>;
  maxMintAmount(): Promise<BigNumberish>;
  stakingRewardsPool(): Promise<BigNumberish>;
  collectionPaused(collectionId: BigNumberish): Promise<boolean>;
  isWhiteListed(address: string): Promise<boolean>;
  isPoolActive(poolId: BigNumberish, tierId: BigNumberish): Promise<boolean>;
  tokenIndex(tokenId: BigNumberish): Promise<BigNumberish>;
  poolLocks(poolId: BigNumberish): Promise<boolean>;
  owner(): Promise<string>;

  // Interactable Functions
  setMinter(_minter: string): Promise<ContractTransaction>;
  setStakingContract(_stakingContract: string): Promise<ContractTransaction>;
  setRaffleContract(_raffleContract: string): Promise<ContractTransaction>;
  setBattleContract(_battleContract: string): Promise<ContractTransaction>;
  setLMSContract(_LMSContract: string): Promise<ContractTransaction>;
  setNumberOfPacks(_packCount: BigNumberish): Promise<ContractTransaction>;
  setPack(
    _packId: BigNumberish,
    _fee: BigNumberish,
    _tiers: BigNumberish[],
    _defaultUriHash: string,
    _collection: BigNumberish,
  ): Promise<ContractTransaction>;
  setPackFee(
    _packId: BigNumberish,
    _newFeeInEther: BigNumberish,
  ): Promise<ContractTransaction>;
  setNFTClaimer(
    claimer: string,
    tokenId: BigNumberish,
  ): Promise<ContractTransaction>;
  setOnlyWhitelisted(_state: boolean): Promise<ContractTransaction>;
  setmaxMintAmount(
    _newmaxMintAmount: BigNumberish,
  ): Promise<ContractTransaction>;
  setDistributorPaused(_state: boolean): Promise<ContractTransaction>;
  setCollectionPaused(
    collectionId: BigNumberish,
    _state: boolean,
  ): Promise<ContractTransaction>;

  getAvailableTokensByPoolAndTier(
    _poolId: BigNumberish,
    _tierId: BigNumberish,
  ): Promise<BigNumberish[]>;
  getPack(_packType: BigNumberish): Promise<Pack>;

  getTotalTokensByPool(poolId: BigNumberish): Promise<BigNumberish>;
  getRandomAvailableTierByPoolId(poolId: BigNumberish): Promise<BigNumberish>;

  airdropTokens(_recipients: string[]): Promise<ContractTransaction>;
  whitelistUsers(_users: string[]): Promise<ContractTransaction>;
  allocateGameFunds(overrides?: ethers.Overrides): Promise<ContractTransaction>;
  distributeRewardToStaker(
    _user: string,
    reward: BigNumberish,
  ): Promise<ContractTransaction>;
  returnTokenToPool(
    tokenId: BigNumberish,
    poolId: BigNumberish,
    tierId: BigNumberish,
  ): Promise<ContractTransaction>;

  addAvailableTokensToPoolAndTier(
    _poolId: BigNumberish,
    _tierId: BigNumberish,
    _tokenIds: BigNumberish[],
  ): Promise<ContractTransaction>;
  buyPack(
    _mintAmount: BigNumberish,
    _packType: BigNumberish,
    _poolId: BigNumberish,
    _user: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  buyPacks(
    _packIds: BigNumberish[],
    _quantities: BigNumberish[],
    _poolId: BigNumberish,
    _user: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  claimLockedNFT(claimer: string): Promise<ContractTransaction>;
  lockNFT(
    player: string,
    id: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;

  emergencyWithdrawStakingRewards(
    amount: BigNumberish,
  ): Promise<ContractTransaction>;
  // Events
  on(
    event: 'NFTsClaimed',
    listener: (claimer: string, tokenIds: BigNumberish[]) => void,
  ): IDistributorContract;
  on(
    event: 'TokenRevealed',
    listener: (owner: string, tokenId: BigNumberish) => void,
  ): IDistributorContract;
  on(
    event: 'TokensAssignedToPoolAndTier',
    listener: (
      poolId: BigNumberish,
      tierId: BigNumberish,
      tokenIds: BigNumberish[],
    ) => void,
  ): IDistributorContract;
  on(
    event: 'TokenRemovedFromPool',
    listener: (
      poolId: BigNumberish,
      tierId: BigNumberish,
      tokenId: BigNumberish,
    ) => void,
  ): IDistributorContract;
  on(
    event: 'EmergencyWithdraw',
    listener: (owner: string, amount: BigNumberish) => void,
  ): IDistributorContract;

  // Add other events as needed
};
