import React, { useEffect } from 'react';
import './p2pCard.scss';
import { Card } from 'Views/Dapp/Components/Game/CardSelection';

interface P2PCardProps {
  card: Card;
}

const P2PCard: React.FC<P2PCardProps> = ({ card }) => {
  // Toggle highlight for stats on click
  const toggleHighlight = (event: React.MouseEvent<HTMLTableCellElement>) => {
    const target = event.currentTarget;
    target.classList.toggle('highlighted');
  };

  return (
    <div className="P2PCard">
      <div className={`p2pcard `}>
        {/* Front Side of Card */}
        <div className="card-face card-front">
          <div className="image-name-container">
            <div className="image-container">
              <img src={card.imageUrl} alt="Fighter Image" />
            </div>
            <div className="name-container">
              <div className="fighter-name">{card.title}</div>
              {card.opponent && (
                <>
                  {' '}
                  <div className="vs">VS</div>
                  <div className="opponent-name">{card.opponent}</div>
                </>
              )}
            </div>
          </div>
          <div className="stats-table">
            <table>
              <tbody>
                <tr>
                  <td className="stat-box" onClick={toggleHighlight}>
                    Win/Loss %
                  </td>
                  <td className="stat-box" onClick={toggleHighlight}>
                    {card.stats.A}%
                  </td>
                </tr>
                <tr>
                  <td className="stat-box" onClick={toggleHighlight}>
                    Championships
                  </td>
                  <td className="stat-box" onClick={toggleHighlight}>
                    {card.stats.B}
                  </td>
                </tr>
                <tr>
                  <td className="stat-box" onClick={toggleHighlight}>
                    Win Streak
                  </td>
                  <td className="stat-box" onClick={toggleHighlight}>
                    {card.stats.C}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            <div className={`rarity ${card.rarity}`}>{card.rarity}</div>
            <div className={`tier ${card.tier.replace(/\s/g, '')}`}>
              {card.tier}
            </div>
          </div>
        </div>
        {/* Back Side of Card */}
        <div
          className="card-face card-back"
          style={{ backgroundImage: `url(${card.imageUrl})` }}
        >
          <div className="back-content"></div>
        </div>
      </div>
    </div>
  );
};

export default P2PCard;
