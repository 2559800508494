import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../Button';
import Logo from '../Logo';
import { HamburgerMenu, Menu, MenuItem, NavBar, Section } from './styledTags';
import AccountCounter from 'components/Counter/AccountCounter';
import { useWeb3 } from 'Web3Context';

const Navigation = () => {
  const [click, setClick] = useState(false);
  const { provider, account, connectWallet, isOwner } = useWeb3(); // Access context
  const navigate = useNavigate();

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      navigate('');
      setTimeout(() => scrollTo(id), 100);
    }
    setClick(!click);
  };

  return (
    <Section id="navigation">
      <NavBar>
        <Logo />
        <HamburgerMenu onClick={() => setClick(!click)} click={click}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={click}>
          <MenuItem onClick={() => scrollTo('home')}>Home</MenuItem>
          <MenuItem onClick={() => scrollTo('about')}>About</MenuItem>
          <MenuItem onClick={() => scrollTo('roadmap')}>Roadmap</MenuItem>
          <MenuItem onClick={() => scrollTo('team')}>Team</MenuItem>
          <MenuItem onClick={() => scrollTo('faq')}>FAQ</MenuItem>
          {isOwner && (
            <>
              <MenuItem onClick={() => navigate('mint')}>Mint</MenuItem>
              {/* <MenuItem onClick={() => navigate('explore2')}>Explore2</MenuItem>
              <MenuItem onClick={() => navigate('explore')}>Explore</MenuItem> */}
            </>
          )}
          <MenuItem onClick={() => navigate('explore')}>Explore</MenuItem>
          <MenuItem onClick={() => navigate('reveal')}>Reveal</MenuItem>

          <MenuItem onClick={() => navigate('dapp')}>Dapp</MenuItem>
          {/* <MenuItem onClick={() => navigate('raffle')}>Raffle</MenuItem>
          <MenuItem onClick={() => navigate('game/home')}>Game</MenuItem> */}
          <MenuItem>
            <div className="mobile">
              {account ? (
                <AccountCounter publicAddr={account} ethBalance="0" />
              ) : (
                <Button text="Connect Wallet" onClick={connectWallet} />
              )}
            </div>
          </MenuItem>
        </Menu>
        <div className="desktop">
          {account ? (
            <AccountCounter publicAddr={account} ethBalance="0" />
          ) : (
            <Button text="Connect Wallet" onClick={connectWallet} />
          )}
        </div>
      </NavBar>
    </Section>
  );
};

export default Navigation;
