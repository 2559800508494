import { useState } from 'react';
import { Copy } from 'lucide-react';
import { toast } from 'react-hot-toast';
import './contractAddress.scss';

const ContractAddress: React.FC<{ contractAddress?: string }> = ({
  contractAddress,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (contractAddress) {
      navigator.clipboard.writeText(contractAddress);
      setCopied(true);
      toast.success('Copied to clipboard!', {
        position: 'top-right',
      });
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <button className="contract-button" onClick={handleCopy}>
      <Copy className="w-5 h-5 text-yellow-600" />
      <p className="font-bold text-yellow-900 drop-shadow-lg truncate-text">
        {contractAddress}
      </p>
      {copied && <span className="text-green-600 text-xs">Copied!</span>}
    </button>
  );
};

export default ContractAddress;
