import React from 'react';
import './OnChainOffChainSelection.scss';
import { Mode } from './EnterPage';

interface Props {
  onModeSelect: (mode: Mode) => void;
  onBack: () => void;
}

const OnChainOffChainSelection: React.FC<Props> = ({ onModeSelect, onBack }) => (
  <div className="onchain-offchain-page">
    <h1 className="header">CHOOSE MODE</h1>
    <div className="buttons-container">
      <button className="mode-button" onClick={() => onModeSelect(Mode.onChain)}>ON-CHAIN MODE</button>
      <button className="mode-button" onClick={() => onModeSelect(Mode.offChain)}>OFF-CHAIN MODE</button>
    </div>
    <button className="back-button" onClick={onBack}>Back</button>
  </div>
);


export default OnChainOffChainSelection;