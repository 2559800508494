import { ethers } from 'ethers';
import { useInterval } from 'Helpers/UseInterval';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Section } from './styledTags';
import Packages, { PackDetail } from './Components/Packages';
import { useWeb3 } from 'Web3Context';
import { useWriteContracts } from 'wagmi/experimental';
import { DistributorHelper, Pack } from 'Helpers/DistributorContractHelper';
import { MinterHelper } from 'Helpers/MinterContractHelper';

type Packcost = {
  packId: number;
  packCost: number;
};

function Dapp() {
  const [tokenBalance, setTokenBalance] = useState<{
    t10: number;
    t11: number;
    t12: number;
    t13: number;
    t20: number;
    t21: number;
    t22: number;
    t23: number;
    t30: number;
    t31: number;
    t32: number;
    t33: number;
    t40: number;
    t41: number;
    t42: number;
    t43: number;
  }>({
    t10: 0,
    t11: 0,
    t12: 0,
    t13: 0,
    t20: 0,
    t21: 0,
    t22: 0,
    t23: 0,
    t30: 0,
    t31: 0,
    t32: 0,
    t33: 0,
    t40: 0,
    t41: 0,
    t42: 0,
    t43: 0,
  });

  const { provider, account, capabilities } = useWeb3();
  const { writeContracts } = useWriteContracts();

  const [packs, setPacks] = useState<Pack[]>([]);
  const [packDetails, setPackDetails] = useState<PackDetail[]>([]);
  const [cart, setCart] = useState<PackDetail[]>([]);
  const [isPaused, setIsPaused] = useState(true);

  const getAvailability = async (
    pool: number,
    tier: number,
  ): Promise<number> => {
    try {
      if (typeof window.ethereum !== 'undefined' && provider && account) {
        // console.log(`calling getavailability`);

        const contract = new DistributorHelper();

        const balance = await contract.getAvailableTokensByPoolAndTier(
          pool,
          tier,
        );
        console.log(`Tier ${tier} balance:`, balance);

        return Number(balance.length);
      }
      throw new Error('Failed to Get Token Availability');
    } catch (e: any) {
      console.log('Failed To Get Token Balance', e);
    }
  };

  const buyPack = async (pack: PackDetail) => {
    try {
      if (pack && pack.qty > 0) {
        if (typeof window.ethereum !== 'undefined') {
          console.log(`calling buypack`);

          const contract = new DistributorHelper(writeContracts);

          if (isPaused) {
            toast.error('The contract is currently paused.');
            return;
          }
          let walletAddress: string;
          if (await contract.onlyWhitelisted()) {
            walletAddress = await contract.signer.getAddress();
            const isWhitelisted = await contract.isWhiteListed(walletAddress);
            if (!isWhitelisted) {
              toast.error('Your wallet address is not whitelisted.');
              return;
            }
          }

          const totalCostInWei = ethers.parseEther(
            (pack.fee * pack.qty).toString(),
          );

          const transaction = await contract.buyPack(
            pack.qty,
            pack.id,
            0,
            walletAddress,
            {
              value: totalCostInWei,
            },
            capabilities,
          );

          const receipt = await transaction.wait();
          const confirmations = await receipt.confirmations();
          if (receipt && confirmations > 0) {
            toast.success(
              `Transaction confirmed with ${confirmations} confirmations.`,
            );
          } else {
            toast.error('Failed to Mint');
          }
        }
      } else {
        toast.error('Please select a valid quantity');
      }
    } catch (e: any) {
      console.log('Error:', e);
      toast.error(e.message || 'An unknown error occurred');
    }
  };

  const buyPacks = async (packs: PackDetail[]) => {
    try {
      if (!packs || packs.length === 0) {
        toast.error('No packs selected');
        return;
      }

      //handle single purchases
      if (packs.length === 1) return await buyPack(packs[0]);

      const ids = [];
      const qtys = [];
      let totalCostInWei = 0n;

      // Collect ids and quantities
      for (const pack of packs) {
        if (pack.qty > 0) {
          ids.push(pack.id); // Assuming pack.id exists in your PackDetail
          qtys.push(pack.qty);
          // Accumulate the total cost in Wei
          totalCostInWei += ethers.parseUnits(
            (pack.fee * pack.qty).toString(),
            'ether',
          );
        } else {
          toast.error(`Please select a quantity for ${pack.name}`);
          return;
        }
      }

      if (typeof window.ethereum !== 'undefined') {
        console.log(`calling buy packs`);
        console.log(`totalCost`, totalCostInWei);

        const contract = new DistributorHelper(writeContracts);

        // Check if the contract is paused before proceeding
        const isPaused = await contract.paused();
        if (isPaused) {
          toast.error(
            'The contract is currently paused. Please try again later.',
          );
          return;
        }
        if (await contract.onlyWhitelisted()) {
          const walletAddress = await contract.signer.getAddress();
          const isWhitelisted = await contract.isWhiteListed(walletAddress);
          if (!isWhitelisted) {
            toast.error('Your wallet address is not whitelisted.');
            return;
          }
        }
        const transaction = await contract.buyPacks(
          ids,
          qtys,
          0,
          null,
          capabilities,
        );

        console.log('transaction:', transaction);
        if (transaction) {
          toast.success(
            `Transaction confirmed with ${packDetails.length} packs.`,
          );
        } else {
          toast.error('Failed to Mint');
        }
      } else {
        toast.error('Ethereum wallet is not connected');
      }
    } catch (e: any) {
      console.log('error:', e);
      toast.error(e.message);
      if (e && e.data) toast.error(e.data.message);
    }
  };

  const addToCart = async (pack: PackDetail) => {
    try {
      if (!pack || pack.qty <= 0) {
        return;
      }

      setCart((prevCart) => {
        // Find the item in the cart
        const existingItemIndex = prevCart.findIndex(
          (item) => item.name === pack.name,
        );

        // If the item exists, update its quantity
        if (existingItemIndex >= 0) {
          const updatedCart = [...prevCart];
          const existingItem = updatedCart[existingItemIndex];

          // Check if the total quantity after addition exceeds the limit
          if (existingItem.qty + pack.qty > 15) {
            toast.error('Only 15 packs per purchase');
            return prevCart; // Return the previous cart state without changes
          }

          // Update the quantity
          updatedCart[existingItemIndex] = {
            ...existingItem,
            qty: existingItem.qty + pack.qty,
          };

          return updatedCart;
        }

        // If the item doesn't exist and the qty is valid, add the new item to the cart
        if (pack.qty <= 15) {
          return [...prevCart, pack];
        } else {
          toast.error('Only 15 packs per purchase');
          return prevCart; // Return the previous cart state without changes
        }
      });
    } catch (e: any) {
      console.log('error:', e);
      if (e && e.data) toast.error(e.data.message);
    }
  };

  const updateCart = async (packs: PackDetail[]) => {
    try {
      setCart((prevCart) => {
        let updatedCart = [...prevCart];

        packs.forEach((pack) => {
          if (pack.qty > 0) {
            const existingProduct = updatedCart.find(
              (item) => item.id === pack.id,
            );
            if (existingProduct) {
              updatedCart = updatedCart.map((item) =>
                item.id === pack.id ? { ...item, qty: pack.qty } : item,
              );
            } else {
              updatedCart.push(pack);
            }
          } else if (pack.qty === 0) {
            updatedCart = updatedCart.filter((item) => item.id !== pack.id);
          }
        });

        return updatedCart;
      });
    } catch (e: any) {
      console.log('error:', e);
      if (e && e.data) toast.error(e.data.message);
    }
  };

  async function GetAvailablePacks() {
    try {
      if (typeof window.ethereum !== 'undefined') {
        const contract = new DistributorHelper();
        const minter = new MinterHelper();
        const packs = await contract.getAllPacks();

        if (packs.length > 0) {
          setPacks(packs);
          const defaultURI = await minter.baseURI();
          const detailedPacks = await convertPacksToDetails(packs, defaultURI);
          setPackDetails(detailedPacks);
        }
      }
    } catch (e: any) {
      console.log('Error:', e);
      toast.error(e.message || 'Failed to fetch packs');
    }
  }

  const convertPacksToDetails = async (
    packs: Pack[],
    uri: string,
  ): Promise<PackDetail[]> => {
    if (packs.length === 0 || !packs) return;

    // const response = await axios.get(
    //   'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=gbp',
    // );
    // const currentPriceOfETHinPounds = response.data.ethereum.gbp;
    const contract = new DistributorHelper();

    const packDetails = await Promise.all(
      packs.map(async (pack) => {
        const available = Math.min(
          ...pack.tiers.map(
            (tier) =>
              tokenBalance[`t${tier}` as keyof typeof tokenBalance] || 0,
          ),
        );

        const isCollectionPaused = await contract.collectionPaused(
          pack.collection,
        );

        //console.log(`collection paused: ${isCollectionPaused}`);
        return {
          id: pack.packId,
          available,
          name: pack.defaultUriHash
            .split('/')[1]
            .split('.')[0]
            .replace(/([A-Z])/g, ' $1')
            .replace(/%20/g, '')
            .trim(),
          fee: Number(pack.fee),
          price: Number(pack.fee) * 1,
          imageUrl: `${uri}${pack.defaultUriHash}`,
          qty: 1,
          heading: `Pack ${pack.packId}`,
          description: `Pack contains \n${pack.tiers.map(
            (num) => `Tier #: ${num}\r`,
          )}`,
          active: !isCollectionPaused && pack.active,
        };
      }),
    );
    // console.log('packs', packs);

    return packDetails;
  };

  const handleTierQuantity = async () => {
    const newTokenBalance = { ...tokenBalance };

    try {
      const contract = new DistributorHelper();

      const packs = await contract.getAllPacks();

      console.log(packs);
      if (packs.length === 0 || !packs) return;

      for (let i = 1; i <= 4; i++) {
        for (let j = 0; j <= 4; j++) {
          const tierKey = `t${i}${j}` as keyof typeof tokenBalance;
          newTokenBalance[tierKey] = await getAvailability(
            0,
            parseInt(`${i}${j}`),
          );
        }
      }

      setTokenBalance(newTokenBalance);
    } catch (error) {
      console.log(`Failed to handle Tier Quantites \n error: ${error}`);
      toast.error('failed to check tier quantites on distro');
    }
  };

  const handlePausedContract = async () => {
    try {
      const contract = new DistributorHelper(writeContracts);

      const isPaused = await contract.paused();

      setIsPaused(isPaused);
    } catch (error) {
      console.log(`Failed to Check Contract Paused\n error: ${error}`);
      toast.error('failed to check pause on distro');
    }
  };

  useEffect(() => {
    (async () => {
      await GetAvailablePacks();
      await handleTierQuantity();
      await handlePausedContract();
    })();
  }, []);

  useInterval(() => {
    (async () => {
      await GetAvailablePacks();
      await handleTierQuantity();
      await handlePausedContract();
    })();
  }, 5000);

  return (
    <>
      <Section id="dapp">
        {/* {packs.length > 0 && (
          <Packages
            packDetails={packDetails}
            onPurchasePacks={buyPacks}
            onAddToCart={addToCart}
            cart={cart}
            onUpdateCart={updateCart}
          />
        )} */}
        <Packages
          packDetails={packDetails}
          onPurchasePacks={buyPacks}
          onAddToCart={addToCart}
          cart={cart}
          onUpdateCart={updateCart}
          paused={isPaused}
        />
      </Section>
    </>
  );
}

export default Dapp;
