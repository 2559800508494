import { ethers } from 'ethers';
import { useInterval } from 'Helpers/UseInterval';
import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { NFTComponent1 } from './Components/NFTCard1';

import { NFTContainer, Section } from './styledTags';
import WordShadow, { IWordShadowProps } from 'components/WordShadow/WordShadow';
import RaffleHelper, { RaffleStateProps } from 'Helpers/RaffleHelper';
import { IGBackgroundContainer } from 'Views/Raffle/styledTags';
import IGBackground from 'components/IGBackGround/IGBackground';
import './Components/countdown.css';
import axios from 'axios';

const Raffle = () => {
  // store user address in local state
  const [userAddress, setUserAddressValue] = useState<string>('');
  // store amount in local state
  const [amount, setAmountValue] = useState<number>(0);
  const [raffles, setRaffles] = useState<RaffleStateProps[]>([]);

  const pad = (n) => {
    return (n < 10 ? '0' : '') + n;
  };

  //Need a Buy Menthod
  const enterRaffle = async (raffleId: number) => {
    try {
      const raffle = raffles.find((r) => r.raffleId === raffleId);
      if (raffle.bought < raffle.participants.length) {
        if (typeof window.ethereum !== 'undefined') {
          const provider = new ethers.BrowserProvider(window.ethereum);

          const signer = await provider.getSigner();

          // request account from metamask
          const [account] = signer.address;
          console.log('Account:', account);

          // signer.getAddress
          console.log('signer: addr', signer.address);

          console.log('signer:', signer);

          const contract = new RaffleHelper();

          console.log('Contract:', contract);

          const transaction = await contract.buyRaffle(raffleId);

          console.log('transaction:', transaction);

          await transaction.wait();

          console.log('BuyRandom:', transaction);

          toast.success(`balance: ${transaction.confirmations}`);
        }
      } else {
        toast.error('Set the Quantity');
      }
    } catch (e: any) {
      console.log('error:', e);
      toast.error(e.data.message);
    }
  };

  //Need to update current Redeemable objects ??
  // call the smart contract, read the current greeting value
  const fetchGreeting = async () => {
    if (typeof window.ethereum !== 'undefined') {
      toast.success(`Greeting: ${'hello'}`);
    }
  };

  const checkRaffleAvailability = async () => {
    const raffleHelper = new RaffleHelper();

    //based on the raffles[] recheck the state by fetching from the blockchain
    // const intervalId = setInterval(() => {}, 1000);

    // return () => clearInterval(intervalId); // cleanup on unmount
  };

  const updateRaffles = async () => {
    const contract = new RaffleHelper();
    const states = await contract.getAllRaffles();

    if (states) {
      setRaffles(states);
    }
  };

  useEffect(() => {
    (async () => {
      await updateRaffles();
    })();
  }, []);

  useInterval(() => {
    (async () => {
      await updateRaffles();
    })();
  }, 5000);

  useEffect(() => {
    //update on State change to keep Track of all States
    if (raffles && raffles.length > 0) {
      (async () => {
        await checkRaffleAvailability();
      })();
    }
  }, [raffles]);

  return (
    <Section id="raffle">
      <IGBackgroundContainer>
        <IGBackground username="" />
      </IGBackgroundContainer>

      {raffles && raffles.length > 0 && (
        <NFTContainer>
          {/* <div>
            <NFTComponent1
              className="card card-red"
              leftAvater={`${raffles[0].size - raffles[0].bought}`}
              leftHeader="Available"
              rightAvater={`${raffles[0].bought}`}
              price={raffles[0].price}
              fee={raffles[0].price}
              rightHeader="Brought"
              footer={`${raffles[0].size}`}
              setQuantity={(num) => console.log('setQuantity:', num)}
              rightMethod={async () => {
                await enterRaffle(0);
              }}
              raffleNames={[
                'Napolean',
                'Medici',
                'Davinci',
                'Macron',
                'Keir',
                'Trump',
              ]}
              container="raffle1"
            />
          </div>
          <div>
            <NFTComponent1
              className="card2"
              leftAvater={`${raffles[1].size - raffles[1].bought}`}
              leftHeader="Available"
              rightAvater={`${raffles[1].bought}`}
              price={raffles[1].price}
              fee={raffles[1].price}
              rightHeader="Brought"
              footer={`${raffles[1].size}`}
              setQuantity={(num) => console.log('setQuantity:', num)}
              rightMethod={async () => {
                await enterRaffle(1);
              }}
              raffleNames={raffles[1].participants}
              container="raffle2"
            />
          </div>
          <div>
            <NFTComponent1
              className="card-gold"
              leftAvater={`${raffles[2].size - raffles[2].bought}`}
              leftHeader="Available"
              rightAvater={`${raffles[2].bought}`}
              price={raffles[2].price}
              fee={raffles[2].price}
              rightHeader="Brought"
              footer={`${raffles[2].size}`}
              setQuantity={(num) => console.log('setQuantity:', num)}
              rightMethod={async () => {
                await enterRaffle(2);
              }}
              raffleNames={raffles[2].participants}
              container="raffle3"
            />
          </div> */}

          {raffles.length > 0 ? (
            raffles.map((raffle, index) => (
              <NFTComponent1
                key={raffle.raffleId}
                className={`card ${index === 0 ? 'card-red' : index === 1 ? 'card2' : 'card-gold'}`}
                leftAvater={`${raffle.size - raffle.bought}`}
                leftHeader="Available"
                rightAvater={`${raffle.bought}`}
                price={raffle.price}
                fee={raffle.price}
                rightHeader="Brought"
                footer={`${raffle.size}`}
                setQuantity={(num) => console.log('setQuantity:', num)}
                rightMethod={async () => {
                  await enterRaffle(raffle.raffleId);
                }}
                raffleNames={raffle.participants}
                container={`raffle-${index}`}
              />
            ))
          ) : (
            <p>No raffles available</p>
          )}
        </NFTContainer>
      )}
    </Section>
  );
};

export default Raffle;
