import GlobalStyles from './styles/GlobalStyles';
import { light } from './styles/Themes';
import { ThemeProvider } from 'styled-components';
import Navigation from './components/Navigation/Navigation';
import Main from 'Views/Main/Main';
import { Web3Provider } from 'Web3Context';
import { Toaster } from 'react-hot-toast';
// import awsmobile from "./aws-exports";
// import { Amplify } from "aws-amplify";

// (Amplify as any).configure(awsmobile);

const App = () => {
  return (
    <main>
      <Toaster />
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <Web3Provider>
          <Navigation />
          <Main />
        </Web3Provider>
      </ThemeProvider>
    </main>
  );
};

export default App;
