import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import logo from 'assets/logos/Base_Symbol_Blue.svg';
import './ownerDapp.scss';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import toast from 'react-hot-toast';
import Papa from 'papaparse';
import navs, { Nav } from './navs';
import { groupByPoolAndTier } from 'Helpers/CSVCreate';

SwiperCore.use([EffectCoverflow, Pagination]);

export type PackDetail = {
  id: number;
  class?: string;
  available: number;
  name: string;
  fee?: number;
  price?: number;
  imageUrl: string;
  qty: number;
  heading: string;
  description: string;
};

export type PackagesProps = {
  packDetails: PackDetail[];
  cart: PackDetail[];
  onPurchasePacks: (packs: PackDetail[]) => Promise<void>;
  onAddToCart: (pack: PackDetail) => Promise<void>;
  onUpdateCart: (pack: PackDetail[]) => void;
};

const OwnerDapp: React.FC<PackagesProps> = ({
  packDetails,
  cart,
  onPurchasePacks,
  onAddToCart,
  onUpdateCart,
}) => {
  const [currentPack, setCurrentPack] = useState<PackDetail>(null);
  const [currentPage, setCurrentPage] = useState<Nav>(navs[0]);
  const rotatingImageRef = useRef<HTMLImageElement>(null);
  const [isNavActive, setIsNavActive] = useState(false);
  const navRef = useRef(null);
  const mainRef = useRef(null);

  useEffect(() => {
    const navElement = navRef.current;

    if (navElement) {
      // Add event listeners for scroll and touch events
      const handleUserInteraction = () => {
        setIsNavActive(true);
        setCurrentPage(null); // Deselect the current nav item when interacting with nav
      };
      const handleInteractionEnd = () => setIsNavActive(false);

      navElement.addEventListener('scroll', handleUserInteraction);
      navElement.addEventListener('touchstart', handleUserInteraction);

      // Remove active state after some delay when interaction ends
      navElement.addEventListener('touchend', () => {
        setTimeout(handleInteractionEnd, 500);
      });

      return () => {
        navElement.removeEventListener('scroll', handleUserInteraction);
        navElement.removeEventListener('touchstart', handleUserInteraction);
        navElement.removeEventListener('touchend', handleInteractionEnd);
      };
    }
  }, []);

  useEffect(() => {
    // Apply the CSS style based on interaction state and navigation selection
    if (mainRef.current) {
      if (!currentPage) {
        mainRef.current.style.gridTemplateColumns = '99% 1%';
      } else {
        mainRef.current.style.gridTemplateColumns = '10% 90%';
      }
    }
  }, [currentPage, isNavActive]);

  const handlePage = (nav: Nav) => {
    setCurrentPage(nav);
  };

  const renderContent = () => {
    if (!currentPage) {
      return (
        <>
          <LeftContent packDetails={packDetails} onBuyPack={setPack} />
          <RightContent packs={packDetails} onBuyPack={setPack} />
        </>
      );
    }
    switch (currentPage.navName) {
      case 'Discover': // Discover or null
      case null:
        return (
          <>
            <LeftContent packDetails={packDetails} onBuyPack={setPack} />
            <RightContent packs={packDetails} onBuyPack={setPack} />
          </>
        );
      default:
        return (
          <>
            {currentPage.type === 'import' && (
              <ControlImport nav={currentPage} />
            )}
            {currentPage.type === 'import-bulk' && (
              <ControlImportBulk nav={currentPage} />
            )}
            {currentPage.type === 'form' && <ControlForm nav={currentPage} />}
          </>
        );
    }
  };

  const setPack = (pack: PackDetail) => {
    pack.class = `card${Math.floor(Math.random() * 4) + 1}`;
    setCurrentPack(pack);
  };

  useEffect(() => {
    //do we need to handle anything when the page switches??
  }, [currentPage]);

  const addToCart = (pack: PackDetail) => {
    //callback to parent OwnerDapp to add to cart
    onAddToCart(pack);
    setCurrentPack(null);
  };

  const buyPack = async (pack: PackDetail) => {
    //callback to parent dapp to purchase the pack
    await onPurchasePacks([pack]);
  };

  return (
    <div
      id="onwerDapp"
      className="ownerDapp"
      translate="no"
      // style={{ backgroundImage: `url(${background})` }}
    >
      <Modal
        currentPack={currentPack}
        onBuyPack={buyPack}
        onAddToCart={addToCart}
        onClose={() => setCurrentPack(null)}
        rotatingImageRef={rotatingImageRef}
      />
      <main className="main" ref={mainRef}>
        <NavComponent navItems={navs} onNavigate={handlePage} navRef={navRef} />
        <section className="content">{renderContent()}</section>
      </main>
    </div>
  );
};

type ModalProps = {
  currentPack: PackDetail;
  onBuyPack: (pack: PackDetail) => Promise<void>;
  onAddToCart: (pack: PackDetail) => void;
  onClose: () => void;
  rotatingImageRef: any;
};
const Modal = ({
  currentPack,
  onBuyPack,
  onAddToCart,
  onClose,
  rotatingImageRef,
}: ModalProps) => {
  if (!currentPack) return null;

  return (
    <div className="music-player">
      <i
        onClick={onClose}
        className="fa fa-window-close"
        aria-hidden="true"
      ></i>

      <div className="album-cover">
        <img
          src={currentPack.imageUrl}
          ref={rotatingImageRef}
          alt={currentPack.name}
        />
        <span className="point"></span>
      </div>
      <h2>{currentPack.name}</h2>
      <p>{currentPack.heading}</p>
      <p>{currentPack.description}</p>
      <div className="controls">
        <button onClick={() => onBuyPack(currentPack)}>
          <i className="fa-solid fa-backward"></i>
        </button>
        <button onClick={() => onAddToCart(currentPack)}>
          <i className="fa-solid fa-play"></i>
        </button>
        <button onClick={() => onBuyPack(currentPack)}>
          <i className="fa-solid fa-forward"></i>
        </button>
      </div>
    </div>
  );
};

type Navprops = {
  navItems: Nav[];
  onNavigate: (nav: Nav) => void;
  activeNav?: string;
  navRef: React.MutableRefObject<any>;
};

const NavComponent = ({
  navItems,
  activeNav,
  onNavigate,
  navRef,
}: Navprops) => {
  const [activeIcon, setActiveIcon] = useState<string>(
    activeNav ?? navItems[0].navName,
  );

  const handleNavClick = (nav: Nav) => {
    setActiveIcon(nav.navName);
    onNavigate(nav);
  };

  return (
    <nav className="main-menu">
      <div>
        <div className="user-info">
          <img src={logo} alt="user" className={!activeNav ? 'max' : null} />
          <p style={{ color: '#3552ff' }}>Base</p>
        </div>
        <ul className="nav-container" ref={navRef}>
          {navItems.map((nav, index) => (
            <li
              key={index}
              onClick={() => handleNavClick(nav)}
              className={`nav-item ${
                activeIcon === nav.navName ? 'active' : null
              }`}
            >
              <a href="#" onClick={(event) => event.preventDefault()}>
                <i className={`${nav.iconClass} nav-icon`}></i>
                <span className="nav-text">{nav.navName}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <ul>
        <li className="nav-item">
          <a href="#">
            <i className="fa fa-user nav-icon"></i>
            <span className="nav-text">Profile</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#">
            <i className="fa fa-gear nav-icon"></i>
            <span className="nav-text">Settings</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#">
            <i className="fa fa-right-from-bracket nav-icon"></i>
            <span className="nav-text">Logout</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

type LeftContentProps = {
  packDetails: PackDetail[];
  onBuyPack: (pack: PackDetail) => void;
};
const LeftContent = ({ packDetails, onBuyPack }: LeftContentProps) => {
  const [isSwiperReady, setIsSwiperReady] = useState(false);
  useEffect(() => {
    if (packDetails.length > 0) {
      // Only initialize Swiper once packDetails are available
      setIsSwiperReady(true);
    }
  }, [packDetails]);
  return (
    <div className="left-content">
      <div className="slider-container">
        <h1>Popular</h1>
        {isSwiperReady ? (
          <Swiper
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            speed={600}
            slidesPerView="auto"
            coverflowEffect={{
              rotate: 10,
              stretch: 120,
              depth: 200,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={{ clickable: true }}
            className="swiper-container"
          >
            {packDetails.map((pack, index) => (
              <SwiperSlide key={index}>
                <img src={pack.imageUrl} alt={pack.name} loading="lazy" />
                <div className="slide-overlay">
                  <h2>{pack.name}</h2>
                  <button onClick={() => onBuyPack(pack)}>
                    Buy{' '}
                    <i className="fab fa-ethereum">
                      <span></span> {pack.fee}
                    </i>{' '}
                    <i className="fa-solid fa-circle-play"></i>
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p>Loading playlists...</p>
        )}
        <div className="swiper-pagination"></div>
      </div>
      <div className="artists">
        <h1>Featured</h1>
        <div className="artist-container containers">
          {packDetails.map((pack, index) => (
            <div className="artist" key={index}>
              <img
                src={pack.imageUrl}
                alt={pack.name}
                onClick={() => onBuyPack(pack)}
              />
              <p>{pack.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="albums">
        <h1>Recommended Albums</h1>
        <div className="album-container containers">
          {/* Example Albums */}
          {packDetails.map((pack, index) => (
            <div className="album" key={index}>
              <div className="album-frame">
                <img
                  src={pack.imageUrl}
                  alt={pack.name}
                  onClick={() => onBuyPack(pack)}
                />
              </div>
              <div>
                <h2>{pack.name}</h2>
                <p>
                  <i className="fab fa-ethereum"></i> {pack.fee}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type RightContentProps = {
  packs: PackDetail[];
  onBuyPack: (pack: PackDetail) => void;
};
const RightContent = ({ packs, onBuyPack }: RightContentProps) => {
  return (
    <div className="right-content">
      <div className="recommended-packs">
        <h1>Recommended</h1>
        <div className="pack-container">
          {packs.map((pack, index) => (
            <div className="pack" key={index}>
              <div className="pack-img">
                <img src={pack.imageUrl} alt={pack.name} />
                <div className="overlay" onClick={() => onBuyPack(pack)}>
                  <i className="fa-solid fa-play"></i>
                </div>
              </div>
              <div className="pack-title">
                <h2>{pack.heading}</h2>
                <p>{pack.name}</p>
              </div>
              <span>
                <i className="fab fa-ethereum"></i> {pack.fee}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TrendingComponent = () => {
  return <div></div>;
};

type ControlImportProps = {
  nav: Nav;
};

const ControlImportBulk: React.FC<ControlImportProps> = ({ nav }) => {
  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [failedFile, setFailedFile] = useState<string | null>(null);
  const [successFiles, setSuccessFiles] = useState<string[]>([]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setCsvFiles(Array.from(files)); // Store multiple CSV files
    }
  };

  const processCsv = async (file: File) => {
    return new Promise<void>((resolve, reject) => {
      Papa.parse(file, {
        complete: async (results) => {
          try {
            const parsedData = results.data;
            const args = parsedData.map((row) =>
              nav.args.map((arg) => row[arg.name]),
            );
            console.log('Processing file:', file.name, 'Args:', args);

            // Call smart contract method
            await nav.method({ ...nav, args });
            resolve(); // File processed successfully
          } catch (error: any) {
            console.error(`Error processing file ${file.name}:`, error);
            reject(file.name); // Pass the failed file name for error handling
          }
        },
        header: true,
      });
    });
  };

  const executeBulkImport = async () => {
    setIsProcessing(true);
    setFailedFile(null);
    setSuccessFiles([]);

    for (const file of csvFiles) {
      try {
        await processCsv(file);
        setSuccessFiles((prev) => [...prev, file.name]);
      } catch (error) {
        setFailedFile(error as string);
        toast.error(`Error processing file: ${error}`);
        break; // Stop further processing
      }
    }

    setIsProcessing(false);
  };

  const downloadFailedFile = () => {
    if (!failedFile) return;

    const failedBlob = new Blob([failedFile], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(failedBlob);
    link.setAttribute('download', `${failedFile}-failed.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="csv-import">
      <h2>{nav.navName}</h2>

      <input
        type="file"
        accept=".csv"
        multiple
        onChange={handleFileUpload}
        disabled={isProcessing}
      />

      <button
        onClick={executeBulkImport}
        disabled={isProcessing || csvFiles.length === 0}
      >
        {isProcessing ? 'Processing...' : 'Execute Bulk Import'}
      </button>

      {successFiles.length > 0 && (
        <div>
          <h3>Successfully Processed Files:</h3>
          <ul>
            {successFiles.map((file, index) => (
              <li key={index}>{file}</li>
            ))}
          </ul>
        </div>
      )}

      {failedFile && (
        <div>
          <h3>Error with File:</h3>
          <p>{failedFile}</p>
          <button onClick={downloadFailedFile}>Download Failed CSV</button>
        </div>
      )}
    </div>
  );
};
const ControlImport = ({ nav }: ControlImportProps) => {
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [response, setResponse] = useState<boolean | string | number>(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (results) => {
        const parsedData = results.data;
        validateCsvData(parsedData);
        setCsvData(parsedData);
      },
      header: true,
    });
    setResponse(null);
  };

  // function generateAttributes(numTokens: number): number[][] {
  //   return Array.from({ length: numTokens }, () => [
  //     Math.floor(Math.random() * 10), // Win/Loss ratio
  //     Math.floor(Math.random() * 10), // Championships
  //     Math.floor(Math.random() * 10), // Win Streak
  //   ]);
  // }
  // const handleFileUpload = (event) => {
  //   let configs: string[] = [];
  //   let configs2 = [];
  //   const file = event.target.files[0];
  //   const poolIds = {
  //     0: 'Open Market Pool',
  //     1: 'Raffle Pool',
  //     2: 'Game Pool',
  //     3: 'Airdrop Pool',
  //     4: 'Staking Pool',
  //     5: 'Private Pool',
  //   };
  //   const tierIds = [
  //     10, 11, 12, 13, 20, 21, 22, 23, 30, 31, 32, 33, 40, 41, 42, 43,
  //   ]; // Tier Ids

  //   // Track assigned tokens to ensure uniqueness across pools
  //   let assignedTokens = new Set();

  //   Papa.parse(file, {
  //     complete: (results) => {
  //       const parsedData = results.data;
  //       const tokenIds = parsedData.map((row) => row.TokenId).filter(Boolean); // Extract valid TokenIds

  //       const totalTiers = tierIds.length * Object.keys(poolIds).length;
  //       // Calculate even distribution of tokens across tiers
  //       const tokensPerTier = Math.ceil(tokenIds.length / totalTiers);

  //       let index = 0;

  //       // Loop through each pool
  //       Object.entries(poolIds).forEach(([poolId, poolName]) => {
  //         // Loop through each tier
  //         tierIds.forEach((tierId) => {
  //           // Assign tokens that haven't been used yet
  //           const idsForTier = tokenIds
  //             .slice(index, index + tokensPerTier)
  //             .filter((id) => !assignedTokens.has(id));

  //           // Mark these tokens as assigned to ensure uniqueness
  //           idsForTier.forEach((id) => assignedTokens.add(id));

  //           const attributes = generateAttributes(idsForTier.length); // Generate attributes for each ID in the current tier

  //           // configs.push(
  //           //   `Pool: ${poolName} (ID: ${poolId})\nTierId: ${tierId}\nIds: ${JSON.stringify(idsForTier)}\nAttributes: ${JSON.stringify(attributes)}`,
  //           // );

  //           configs.push(
  //             `
  //               _poolId: ${poolId} \n
  //               _tierId: ${tierId} \n
  //               _Ids: ${JSON.stringify(idsForTier)} \n
  //               _attributes: ${JSON.stringify(attributes)}
  //             `,
  //           );

  //           // configs2.push({
  //           //   _poolId: poolId,
  //           //   _tierId: tierId,
  //           //   _Ids: idsForTier,
  //           //   _attributes: attributes,
  //           // });

  //           index += tokensPerTier; // Increment index to move to the next batch of tokenIds
  //         });
  //       });

  //       // Output the result to the console
  //       console.log(`Configs:\n${configs.join('\n\n')}`);
  //       // groupByPoolAndTier(configs2);
  //     },
  //     header: true, // Assuming the CSV has a header
  //   });
  // };

  const validateCsvData = (data) => {
    const validationErrors = [];
    data.forEach((row, index) => {
      nav.args.forEach((arg) => {
        if (!row.hasOwnProperty(arg.name) || row[arg.name] === '') {
          validationErrors.push(`Row ${index + 1} is missing ${arg.name}`);
        }
      });
    });
    setErrors(validationErrors);
  };

  const generateCsvTemplate = () => {
    const header = nav.args.map((arg) => arg.name).join(',');
    const blob = new Blob([header], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${nav.navName}_template.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const executeContractMethod = async () => {
    setIsLoading(true);
    try {
      let args = [];
      for (const row of csvData) {
        args.push(nav.args.map((arg) => row[arg.name]));
      }
      console.log('OwnerDap args', args);
      setResponse(await nav.method({ ...nav, args: args }));

      // toast.success(`Method ${methodName} executed successfully`);
    } catch (error: any) {
      toast.error(`Error executing ${nav.navName}: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="csv">
      <h2>{nav.navName}</h2>
      {/* Render the response if available */}
      {response !== undefined && (
        <div>
          {typeof response === 'boolean' ? (
            response ? (
              <i
                className="fa fa-thumbs-up"
                aria-hidden="true"
                style={{
                  color: 'green',
                  fontSize: '4rem',
                }}
              ></i>
            ) : (
              <i
                className="fa fa-thumbs-down"
                aria-hidden="true"
                style={{
                  color: 'red',
                  fontSize: '4rem',
                }}
              ></i>
            )
          ) : (
            <span style={{ fontSize: '2rem' }}>Response: {response}</span>
          )}
        </div>
      )}
      <div className="template-section">
        <h3>Expected Inputs:</h3>
        <ul>
          {nav.args.map((arg, index) => (
            <li key={index}>
              <strong>{arg.name}</strong> ({arg.type})
            </li>
          ))}
        </ul>
        <button onClick={generateCsvTemplate}>Download CSV Template</button>
      </div>

      <input type="file" accept=".csv" onChange={handleFileUpload} />
      {errors.length > 0 && (
        <div className="error-messages">
          {errors.map((error, index) => (
            <p key={index} className="error-text">
              {error}
            </p>
          ))}
        </div>
      )}
      <div className="csv-grid">
        {csvData && (
          <table>
            <thead>
              <tr>
                {nav.args.map((arg, index) => (
                  <th key={index}>{arg.name}</th>
                ))}
                <th key="Import">
                  {' '}
                  {/* <button
                    onClick={() => executeContractMethod()}
                    disabled={isLoading}
                  >
                    Import Tier Data
                  </button> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {csvData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {nav.args.map((arg, index) => (
                    <td
                      key={index}
                      className={row[arg.name] === '' ? 'missing' : ''}
                    >
                      {row[arg.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <button
        onClick={executeContractMethod}
        disabled={isLoading || errors.length > 0}
      >
        Execute All from Table
      </button>
    </div>
  );
};

const ControlImport1 = ({ methodName, methodArgs }) => {
  const [groupedData, setGroupedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (results) => {
        const parsedData = results.data;
        const grouped = groupDataByTierId(parsedData);
        setGroupedData(grouped);
      },
      header: true,
    });
  };

  const groupDataByTierId = (data) => {
    const grouped = {};
    data.forEach((row) => {
      const tierId = row._tierId;
      if (!grouped[tierId]) {
        grouped[tierId] = { _ids: [], _attributes: [] };
      }
      grouped[tierId]._ids.push(row._ids);
      grouped[tierId]._attributes.push(
        row._attributes ? JSON.parse(row._attributes) : [],
      );
    });
    return grouped;
  };

  const executeContractMethod = async (tierId) => {
    setIsLoading(true);
    try {
      console.log('methodName', methodName);
      console.log('methodName', methodName);
      // const contract = new SmartContractHelper();
      // const { _ids, _attributes } = groupedData[tierId];
      // await contract[methodName](tierId, _ids, _attributes);
      toast.success(
        `Method ${methodName} executed successfully for Tier ${tierId}`,
      );
    } catch (error: any) {
      toast.error(
        `Error executing ${methodName} for Tier ${tierId}: ${error.message}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="csv">
      <h2>{methodName}</h2>

      <div className="template-section">
        <h3>Expected Inputs:</h3>
        <ul>
          {methodArgs.map((arg, index) => (
            <li key={index}>
              <strong>{arg.name}</strong> ({arg.type})
            </li>
          ))}
        </ul>
      </div>

      <input type="file" accept=".csv" onChange={handleFileUpload} />

      {Object.keys(groupedData).length > 0 && (
        <div className="grouped-data-table">
          <h3>Grouped Data</h3>
          {Object.keys(groupedData).map((tierId) => (
            <div key={tierId} className="tier-group">
              <h4>Tier ID: {tierId}</h4>
              <table>
                <thead>
                  <tr>
                    <th>_tierId</th>
                    <th>_ids</th>
                    <th>_attributes</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedData[tierId]._ids.map((id, index) => (
                    <tr key={index}>
                      <td>{tierId}</td>
                      <td>{id}</td>
                      <td>
                        {JSON.stringify(groupedData[tierId]._attributes[index])}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                onClick={() => executeContractMethod(tierId)}
                disabled={isLoading}
              >
                Import Tier {tierId} Data
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

type ControlFormProps = {
  nav: Nav;
};

const ControlForm: React.FC<ControlFormProps> = ({ nav }) => {
  const [response, setResponse] = useState<boolean | string | number>(null);

  const [simpleArgs, setSimpleArgs] = useState<any[]>(
    Array(nav.args.length).fill(''),
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Reset and initialize simpleArgs based on the nav's args
    const initialArgs = nav.args.map((arg) =>
      arg.type === 'checkbox' ? false : '',
    );
    setSimpleArgs(initialArgs);
    console.log('Initialized simpleArgs for:', nav.navName, initialArgs);
  }, [nav]);

  const handleInputChange = (index: number, e: any) => {
    const updatedArgs = [...simpleArgs];
    const argType = nav.args[index].type;

    if (argType === 'checkbox') {
      updatedArgs[index] = e.target.checked;
    } else {
      updatedArgs[index] = e.target.value;
    }
    setSimpleArgs(updatedArgs);
  };

  const executeContractMethod = async () => {
    setIsLoading(true);
    try {
      setResponse(await nav.method({ ...nav, args: simpleArgs }));
      // toast.success(`Method ${nav.navName.toString()} executed successfully`);
    } catch (error: any) {
      toast.error(
        `Error executing ${nav.navName.toString()}: ${error.message}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="form">
      <h2>{String(nav.navName)}</h2>
      {/* Render the response if available */}
      {response !== undefined && (
        <div>
          {typeof response === 'boolean' ? (
            response ? (
              <i
                className="fa fa-thumbs-up"
                aria-hidden="true"
                style={{
                  color: 'green',
                  fontSize: '4rem',
                }}
              ></i>
            ) : (
              <i
                className="fa fa-thumbs-down"
                aria-hidden="true"
                style={{
                  color: 'red',
                  fontSize: '4rem',
                }}
              ></i>
            )
          ) : (
            <span style={{ fontSize: '2rem' }}>Response: {response}</span>
          )}
        </div>
      )}

      <div>
        {nav.args.map((arg, index) => (
          <div key={index} className="input-container">
            {arg.type === 'checkbox' ? (
              <>
                <input
                  type={arg.type}
                  onChange={(e) => handleInputChange(index, e)}
                />
                <label>{arg.name}</label>{' '}
                {/* Render the name next to the checkbox */}
              </>
            ) : (
              <input
                type={arg.type}
                placeholder={arg.name}
                onChange={(e) => handleInputChange(index, e)}
              />
            )}
          </div>
        ))}
      </div>
      <button onClick={executeContractMethod} disabled={isLoading}>
        Execute {String(nav.navName)}
      </button>
    </div>
  );
};
export default OwnerDapp;
