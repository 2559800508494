import { Boost, NFT } from 'types/NFT/Minter';

export const NFTS: NFT[] = [
  {
    tokenId: 4,
    ipfs: {
      name: 'BOXING GENESIS',
      description: '',
      image:
        'https://ipfs.io/ipfs/QmSNCWK1h7s1HFQpnvvtGK5aLfyo5WqsgAw9dRtCtat71n',
      external_url: 'https://example.com/items/3',
      attributes: [
        {
          trait_type: 'Rarity',
          value: 'Bronze',
        },
        {
          trait_type: 'Height',
          value: 150,
        },
      ],
    },
    tier: 99,
    a: null,
    b: null,
    c: null,
    collection: 2,
  },

  {
    tokenId: 0,
    ipfs: {
      name: 'Mike Tyson',
      description: 'Description for NFT Item 2',
      image:
        'https://ipfs.io/ipfs/Qmct4zLMAJBMxwEtEwN5NzqaadjctbSFCqdAbDgXe19fXU',

      external_url: 'https://example.com/items/2',
      attributes: [
        {
          trait_type: 'Type',
          value: 'Silver',
        },
        {
          trait_type: 'Speed',
          value: 'Fast',
        },
        {
          trait_type: 'Opponent',
          value: 'Lou Savarese',
        },
      ],
    },
    tier: 10,
    a: 88,
    b: 33,
    c: 25,
    collection: 1,
  },
  {
    tokenId: 1,
    ipfs: {
      name: 'LENNOX LEWIS',
      description: 'Description for NFT Item 1',
      image:
        'https://ipfs.io/ipfs/bafybeiac57bdeh23m7nhoqmql5denfhbl2duh5d5tepcrjme3ced3kfsnq',
      external_url: 'https://example.com/items/1',
      attributes: [
        {
          trait_type: 'Age',
          value: 2,
        },
        {
          trait_type: 'Size',
          value: 'Large',
        },
        {
          trait_type: 'Opponent',
          value: 'FRANCOIS BOTHA',
        },
        {
          trait_type: 'isBooster',
          value: false,
        },
      ],
    },
    tier: 21,
    a: 88,
    b: 33,
    c: 25,
    collection: 1,
  },
  {
    tokenId: 2,
    ipfs: {
      name: 'TONY BELLEW',
      description: 'Description for NFT Item 1',
      image:
        'https://ipfs.io/ipfs/bafybeidejwoxcauu544dvwu2rebvtajymlm6q5te47bmjdiz7cg6wjmtm4',
      external_url: 'https://example.com/items/1',
      attributes: [
        {
          trait_type: 'Age',
          value: 2,
        },
        {
          trait_type: 'Size',
          value: 'Large',
        },
        {
          trait_type: 'isBooster',
          value: false,
        },
      ],
    },
    tier: 32,
    a: 69,
    b: 29,
    c: 25,
    collection: 1,
  },
  {
    tokenId: 3,
    ipfs: {
      name: 'MANNY PACQUIAO',
      description: 'Description for NFT Item 1',
      image:
        'https://ipfs.io/ipfs/bafybeigwbtoredyq5obu522ku6uyefyvffg6tbdnfy6doedp7iw4god5zm',
      external_url: 'https://example.com/items/1',
      attributes: [
        {
          trait_type: 'Age',
          value: 2,
        },
        {
          trait_type: 'Size',
          value: 'Large',
        },
        {
          trait_type: 'Opponent',
          value: 'RICKY HATTON',
        },
        {
          trait_type: 'isBooster',
          value: false,
        },
      ],
    },
    tier: 40,
    a: 8,
    b: 8,
    c: 8,
    collection: 1,
  },
];

export const BoostTokens: Boost[] = [
  {
    tokenId: 0,
    targetAttribute: 0, // attribute A (Win/Loss %)
    boostPoint: 5, // Increase Win/Loss by 5 points
  },
  {
    tokenId: 1,
    targetAttribute: 1, // attribute B (Championships)
    boostPoint: 3, // Increase Championships by 3 points
  },
  {
    tokenId: 2,
    targetAttribute: 2, // attribute C (Win Streak)
    boostPoint: 4, // Increase Win Streak by 4 points
  },
  {
    tokenId: 3,
    targetAttribute: 3, // random attribute
    boostPoint: 6, // Randomly boost an attribute by 6 points
  },
  {
    tokenId: 3,
    targetAttribute: 3, // random attribute
    boostPoint: 6, // Randomly boost an attribute by 6 points
  },
  {
    tokenId: 4,
    targetAttribute: 0, // attribute A
    boostPoint: 7,
  },
  {
    tokenId: 5,
    targetAttribute: 1, // attribute B
    boostPoint: 2,
  },
  {
    tokenId: 99,
    targetAttribute: 2, // attribute C
    boostPoint: 10,
  },
  {
    tokenId: 6,
    targetAttribute: 3, // random attribute
    boostPoint: 1,
  },
  {
    tokenId: 7,
    targetAttribute: 0, // attribute A
    boostPoint: 8,
  },
  {
    tokenId: 8,
    targetAttribute: 1, // attribute B
    boostPoint: 5,
  },
];
