import {
  ethers,
  keccak256,
  getBytes,
  AbiCoder,
  Overrides,
  BigNumberish,
  Signer,
} from 'ethers';
import abi from 'contracts/Minter.json';
import axios from 'axios';
import { willSponsor } from './Paymaster/utils';
import { UserOperation } from 'permissionless';
import { baseSepolia } from 'viem/chains';
import {
  Boost,
  IMinterContract,
  MetaDataInfo,
  MinterContractAddress,
  NFT,
  NFTAttributes,
} from 'types/NFT/Minter';

// Define MinterException in a separate file or at the top of your current file
export class MinterException extends Error {
  constructor(public code: string, public message: string) {
    super(message);
    this.name = 'MinterException';
    Object.setPrototypeOf(this, MinterException.prototype);
  }
}

async function fetchMetaDataFromIPFS(ipfsUrl: string): Promise<MetaDataInfo> {
  try {
    const response = await axios.get(ipfsUrl);
    const metadata = response.data;
    return metadata;
  } catch (error) {
    console.error('Error fetching image from IPFS:', error);
    throw error;
  }
}

export class MinterHelper {
  private contract: IMinterContract;
  public signer: ethers.Signer;
  private writeContracts?: any; // Adjust the type based on your implementation

  constructor(writeContracts?: any) {
    this.writeContracts = writeContracts;
  }

  private async initializeContract(): Promise<void> {
    if (this.contract && this.signer) {
      // Contract and signer are already initialized
      return;
    }

    if (typeof window.ethereum !== 'undefined') {
      //await window.ethereum.enable();
      const provider = new ethers.BrowserProvider(window.ethereum);
      this.signer = await provider.getSigner();
      this.contract = new ethers.Contract(
        MinterContractAddress,
        abi,
        this.signer,
      ) as IMinterContract;
    } else {
      throw new Error('Ethereum wallet is not connected');
    }
  }

  private async ensureContractReady(): Promise<void> {
    if (!this.contract || !this.signer) {
      await this.initializeContract();
    }
  }

  // Paymaster Integration
  private async canSponsor(
    method: string,
    params: any[],
    types: string[],
    overrides?: Overrides,
  ): Promise<boolean> {
    // Implement your Paymaster logic here
    // This is a placeholder function

    // You need to provide actual implementation based on your Paymaster setup

    await this.ensureContractReady();

    const sender = await this.signer.getAddress();

    const iface = new ethers.Interface(abi);
    const callData: `0x${string}` = iface.encodeFunctionData(
      method,
      params,
    ) as `0x${string}`;

    const paymasterAddress = '0xYourPaymasterAddress'; // Replace with your Paymaster contract address
    const abiCoder = new AbiCoder();
    const extraData = abiCoder.encode(types, params);
    const paymasterAndData: `0x${string}` =
      `${paymasterAddress}${extraData.slice(2)}` as `0x${string}`;

    const messageHash = keccak256(callData);
    const signature = await this.signer.signMessage(getBytes(messageHash));

    const userOp: UserOperation<'v0.6'> = {
      sender: sender as `0x${string}`,
      nonce: BigInt(1), // Replace with actual nonce retrieval logic
      initCode: '0x',
      callData,
      callGasLimit: BigInt(100000), // Estimate appropriately
      verificationGasLimit: BigInt(21000),
      preVerificationGas: BigInt(21000),
      maxFeePerGas: BigInt(20000000000),
      maxPriorityFeePerGas: BigInt(1000000000),
      paymasterAndData,
      signature: signature as `0x${string}`,
    };

    const canSponsor = await willSponsor({
      chainId: baseSepolia.id,
      entrypoint: '0xYourEntryPointAddress', // Replace with your actual entry point address
      userOp,
    });

    return canSponsor;
  }

  async isOwner(): Promise<boolean> {
    await this.ensureContractReady();
    try {
      const owner = await this.contract.owner();
      const signer = await this.signer.getAddress();
      console.log(`owner: ${owner} \n signerAddre: ${signer}`);
      return owner === signer;
    } catch (error) {
      console.error('Error checking owner:', error);
      return false;
    }
  }

  // Public Variables
  async baseURI(): Promise<string> {
    try {
      await this.ensureContractReady();

      const baseUri = await this.contract.baseURI();

      return baseUri;
    } catch (error) {
      console.error('Error fetching baseUri:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      throw error;
    }
  }

  async getBaseURIUsingCall(): Promise<string> {
    await this.ensureContractReady();
    try {
      const data = this.contract.interface.encodeFunctionData('baseURI');
      const result = await this.signer.provider.call({
        to: MinterContractAddress,
        data: data,
      });
      const decoded = this.contract.interface.decodeFunctionResult(
        'baseURI',
        result,
      );
      console.log(`Decoded base URI: ${decoded[0]}`);
      return decoded[0];
    } catch (error) {
      console.error('Error performing eth_call:', error);
      throw error;
    }
  }

  async baseExtension(): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.baseExtension();
  }

  // Public Mappings (accessed via functions)

  // Removed revealed(tokenId) as it's not in the new interface

  async isRevealOpen(): Promise<boolean> {
    try {
      await this.ensureContractReady();
      return await this.contract.canReveal();
    } catch (error) {
      console.log('Failed to check Reveal status', error);
    }
    return false;
  }

  async SetReveal(reveal: boolean, overrides?: Overrides): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }
    const tx = await this.contract.setReveal(
      reveal,
      overrides,
    );

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('minter Reveal status set successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;

  }
  async getCurrentCollection(): Promise<number> {
    await this.ensureContractReady();
    return Number(await this.contract.getCurrentCollection());
  }
  async attributeA(tokenId: BigNumberish): Promise<number> {
    await this.ensureContractReady();
    return Number(await this.contract.attributeA(tokenId));
  }

  async attributeB(tokenId: BigNumberish): Promise<number> {
    await this.ensureContractReady();
    return Number(await this.contract.attributeB(tokenId));
  }

  async attributeC(tokenId: BigNumberish): Promise<number> {
    await this.ensureContractReady();
    return Number(await this.contract.attributeC(tokenId));
  }

  async defaultTokenURI(tokenId: BigNumberish): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.defaultTokenURI(tokenId);
  }

  async tiers(tokenId: BigNumberish): Promise<number> {
    await this.ensureContractReady();
    return await this.contract.tiers(tokenId);
  }

  async collectionType(tokenId: BigNumberish): Promise<number> {
    await this.ensureContractReady();
    return Number(await this.contract.collectionType(tokenId));
  }

  // Updated method name to match the interface
  async isBoostToken(tokenId: BigNumberish): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.isBoostToken(tokenId);
  }

  async lockedTokens(tokenId: BigNumberish): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.lockedTokens(tokenId);
  }

  async tokenLockers(tokenId: BigNumberish): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.tokenLockers(tokenId);
  }

  // Functions
  async setDistributorContractAddress(
    _distributorContractAddress: string,
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.setDistributorContractAddress(
      _distributorContractAddress,
      overrides,
    );

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Distributor set successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async getNFTTier(_tokenId: BigNumberish): Promise<number> {
    await this.ensureContractReady();
    return Number(await this.contract.getNFTTier(_tokenId));
  }

  async setBoostToken(
    tokenId: BigNumberish,
    boostAttribute: BigNumberish,
    boostPoint: BigNumberish,
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.setBoostToken(
      tokenId,
      boostAttribute,
      boostPoint,
      overrides,
    );

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('BoostToken set successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setBoostTokens(
    tokenIds: BigNumberish[],
    boostAttributes: BigNumberish[],
    boostPoints: BigNumberish[],
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();

    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.setBoostTokens(
      tokenIds,
      boostAttributes,
      boostPoints,
      overrides,
    );

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('BoostTokens set successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async removeBoostToken(
    tokenId: BigNumberish,
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.removeBoostToken(tokenId, overrides);

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async removeBoostTokens(
    tokenIds: BigNumberish[],
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.removeBoostTokens(tokenIds, overrides);

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setCollectionBaseURI(
    collectionId: BigNumberish,
    _collectionbaseURI: string,
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.setCollectionBaseURI(
      collectionId,
      _collectionbaseURI,
      overrides,
    );
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async tokenURI(tokenId: BigNumberish): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.tokenURI(tokenId);
  }

  async reveal(
    tokenId: BigNumberish,
    overrides?: Overrides,
    capabilities?: any,
  ): Promise<boolean> {
    try {
      await this.ensureContractReady();
      //check canReveal status

      const canReveal = await this.contract.canReveal();

      if(!canReveal){
        throw new MinterException("REVEAL_PAUSED", "Contract reveal stage is paused");
      }
      if (!overrides) {
        overrides = {
          gasLimit: 30000000,
        };
      }

      let tx;
      if (
        capabilities &&
        (await this.canSponsor('reveal', [tokenId], ['uint256'], overrides))
      ) {
        // Implement Paymaster logic
        tx = await this.writeContracts({
          contracts: [
            {
              address: this.contract.address,
              abi: abi,
              functionName: 'reveal',
              args: [tokenId],
              overrides,
            },
          ],
          capabilities,
        });
      } else {
        tx = await this.contract.reveal(tokenId, overrides);
      }

      const receipt = await (tx as any).wait();
      console.log('receipt:', receipt);
      if (receipt.status === 1) {
        console.log('Tokens added successfully');
        return true;
      }
    } catch (error: any) {
      if (error?.reason) {
        console.error('Transaction failed, Reason:', error.reason);
      } else if (error?.data?.message) {
        console.error('Transaction failed, Message:', error.data.message);
      } else {
        console.error('Transaction failed:', error);
      }
    }

    return false;
  }

  async setBaseURI(
    _newBaseURI: string,
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.setBaseURI(_newBaseURI, overrides);
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async setBaseExtension(
    _newBaseExtension: string,
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.setBaseExtension(
      _newBaseExtension,
      overrides,
    );
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async mintToken(
    to: string,
    tierToPurchase: BigNumberish,
    tokenId: BigNumberish,
    _defaultUriHash: string,
    overrides?: Overrides,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    const tx = await this.contract.mintToken(
      to,
      tierToPurchase,
      tokenId,
      _defaultUriHash,
      overrides,
    );
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  // New Method: Assign Available Tier
  async addTokensToPoolByTier(
    poolId: number,
    tierId: number,
    ids: number[],
    attributes: number[][],
    overrides?: Overrides,
  ): Promise<boolean> {
    try {
      console.log('firing:', { poolId, tierId, ids, attributes });
      await this.ensureContractReady();

      if (!overrides) {
        overrides = {
          gasLimit: 30000000,
        };
      }

      if (ids.length !== attributes.length) {
        throw new Error('Length of IDs and attributes arrays must match');
      }

      const tx = await this.contract.addTokensToPoolByTier(
        poolId,
        tierId,
        ids,
        attributes,
        overrides,
      );

      const receipt = await (tx as any).wait();
      if (receipt.status === 1) {
        console.log('Tokens added successfully');
        return true;
      }

      console.error('Transaction failed');
      return false;
    } catch (error) {
      console.log(`error: ${error}`);
    }
  }

  async walletOfOwner(owner: string): Promise<number[]> {
    await this.ensureContractReady();
    const tokens: BigNumberish[] = await this.contract.walletOfOwner(owner);
    return tokens.map((token) => Number(token));
  }

  async tokenExists(tokenId: BigNumberish): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.tokenExists(tokenId);
  }

  async ownerOfToken(tokenId: BigNumberish): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.ownerOfToken(tokenId);
  }

  async isApprovedOrOwner(
    spender: string,
    tokenId: BigNumberish,
  ): Promise<boolean> {
    await this.ensureContractReady();
    return await this.contract.isApprovedOrOwner(spender, tokenId);
  }

  async lockNFT(
    tokenId: BigNumberish,
    overrides?: Overrides,
    capabilities?: any,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    let tx;
    if (
      capabilities &&
      (await this.canSponsor('lockNFT', [tokenId], ['uint256'], overrides))
    ) {
      // Implement Paymaster logic
      tx = await this.writeContracts({
        contracts: [
          {
            address: this.contract.address,
            abi: abi,
            functionName: 'lockNFT',
            args: [tokenId],
            overrides,
          },
        ],
        capabilities,
      });
    } else {
      tx = await this.contract.lockNFT(tokenId, overrides);
    }

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Token Locked successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async unlockNFT(
    tokenId: BigNumberish,
    overrides?: Overrides,
    capabilities?: any,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    let tx;
    if (
      capabilities &&
      (await this.canSponsor('unlockNFT', [tokenId], ['uint256'], overrides))
    ) {
      // Implement Paymaster logic
      tx = await this.writeContracts({
        contracts: [
          {
            address: this.contract.address,
            abi: abi,
            functionName: 'unlockNFT',
            args: [tokenId],
            overrides,
          },
        ],
        capabilities,
      });
    } else {
      tx = await this.contract.unlockNFT(tokenId, overrides);
    }

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async unlockBoostToDistributor(
    tokenId: BigNumberish,
    overrides?: Overrides,
    capabilities?: any,
  ): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }
    let tx;
    if (
      capabilities &&
      (await this.canSponsor(
        'unlockBoostToDistributor',
        [tokenId],
        ['uint256'],
        overrides,
      ))
    ) {
      // Implement Paymaster logic
      tx = await this.writeContracts({
        contracts: [
          {
            address: this.contract.address,
            abi: abi,
            functionName: 'unlockBoostToDistributor',
            args: [tokenId],
            overrides,
          },
        ],
        capabilities,
      });
    } else {
      tx = await this.contract.unlockBoostToDistributor(tokenId, overrides);
    }
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async withdraw(overrides?: Overrides, capabilities?: any): Promise<boolean> {
    await this.ensureContractReady();
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    let tx;
    if (
      capabilities &&
      (await this.canSponsor('withdraw', [], [], overrides))
    ) {
      // Implement Paymaster logic
      tx = await this.writeContracts({
        contracts: [
          {
            address: this.contract.address,
            abi: abi,
            functionName: 'withdraw',
            args: [],
            overrides,
          },
        ],
        capabilities,
      });
    } else {
      tx = await this.contract.withdraw(overrides);
    }

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Withdrew successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  // Helper to fetch token metadata from IPFS
  async fetchMetaDataFromIPFS(ipfsHash: string): Promise<MetaDataInfo> {
    try {
      const response = await axios.get(`https://ipfs.io/ipfs/${ipfsHash}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching metadata from IPFS:', error);
      throw error;
    }
  }

  // Fetch user's own tokens
  async getMyTokens(): Promise<NFT[]> {
    await this.ensureContractReady();
    const owner = await this.signer.getAddress();
    return this.getUserTokens(owner);
  }
  async getMyUnRevealedTokens(): Promise<NFT[]> {
    await this.ensureContractReady();
    const owner = await this.signer.getAddress();
    const tokens = await this.getUserTokens(owner);
    console.log('UserTokens:', tokens);
    if (tokens && tokens.length > 0) {
      // Create an array of promises to check if each token is revealed
      const tokenPromises = tokens.map(async (t) => {
        const isRevealed = await this.contract.revealed(t.tokenId);
        return !isRevealed ? t : null; // Return the token if it's not revealed, otherwise return null
      });

      // Await all promises concurrently
      const unRevealedTokensArray = await Promise.all(tokenPromises);

      // Filter out any null values (tokens that were revealed)
      return unRevealedTokensArray.filter((t) => t !== null);
    }
    return [];
  }

  async getMyPlayableTokens(): Promise<NFT[]> {
    await this.ensureContractReady();
    const owner = await this.signer.getAddress();
    const tokens = await this.getUserTokens(owner);

    if (!tokens) return [];

    const playableTokens: NFT[] = [];
    for (const token of tokens) {
      try {
        const isboost = await this.contract.isBoostToken(token.tokenId);
        const isRevealed = await this.contract.revealed(token.tokenId);
        if (isRevealed && !isboost) {
          playableTokens.push(token);
        }
      } catch (error) {
        console.error(
          `Failed to check revealed status for token ${token.tokenId}: ${error}`,
        );
      }
    }

    return playableTokens;
  }

  async getMyBoostTokens(): Promise<Boost[]> {
    await this.ensureContractReady();
    const owner = await this.signer.getAddress();
    const tokens = await this.getUserTokens(owner);

    if (!tokens) return [];

    const boostTokens: Boost[] = [];
    for (const token of tokens) {
      try {
        const isboost = await this.contract.isBoostToken(token.tokenId);
        if (isboost) {
          const boostToken = await this.contract.getBooster(token.tokenId);
          if (boostToken) boostTokens.push(boostToken);
        }
      } catch (error) {
        console.error(
          `Failed to check boost status for token ${token.tokenId}: ${error}`,
        );
      }
    }

    return boostTokens;
  }

  // Fetch tokens for a specific user
  async getUserTokens(user: string): Promise<NFT[]> {
    await this.ensureContractReady();
    const userTokenIds = await this.contract.walletOfOwner(user);

    if (!userTokenIds || userTokenIds.length === 0) return [];
    const nfts: NFT[] = [];
    for (const tokenId of userTokenIds) {
      const tokenUri = await this.tokenURI(Number(tokenId));
      const metadata = await fetchMetaDataFromIPFS(tokenUri);

      const isRevealed = await this.contract.revealed(tokenId);
      if (metadata) {
        const attributes = await this.contract.getNFTAttributes(tokenId);

        const nft = {
          tokenId: Number(tokenId),
          ipfs:
            isRevealed && metadata.attributes
              ? metadata
              : {
                  image: tokenUri,
                  name: null,
                },
          tier: Number(attributes.tier),
          collection: Number(attributes.collectionType),
          a: Number(attributes.attributeA),
          b: Number(attributes.attributeB),
          c: Number(attributes.attributeC),
        };
        nfts.push(nft);
      }
    }
    return nfts;
  }

  async getUserToken(tokenId: number): Promise<NFT> {
    await this.ensureContractReady();

    const tokenUri = await this.tokenURI(Number(tokenId));
    const metadata = await fetchMetaDataFromIPFS(tokenUri);

    const isRevealed = await this.contract.revealed(tokenId);
    if (metadata) {
      const attributes = await this.contract.getNFTAttributes(tokenId);

      return {
        tokenId: Number(tokenId),
        ipfs:
          isRevealed && metadata.attributes
            ? metadata
            : {
                image: tokenUri,
                name: null,
              },
        tier: Number(attributes.tier),
        collection: Number(attributes.collectionType),
        a: Number(attributes.attributeA),
        b: Number(attributes.attributeB),
        c: Number(attributes.attributeC),
      };
    }
    return null;
  }

  async getSigner(): Promise<Signer> {
    return this.signer;
  }
  // If there are events you want to listen to, you can use the contract's 'on' method
  // Example:
  // on(event: string, listener: Listener): void {
  //   this.contract.on(event, listener);
  // }

  // Since the Contract class already provides the 'on' method, you can use it directly
  // Usage:
  // this.contract.on('EventName', (args) => { /* handle event */ });
}
