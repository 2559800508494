import Footer from 'components/Footer/Footer';
// import ScrollToTop from "components/ScrollToTop";
import About from 'Views/About/About';
import AboutBaseTokens from 'Views/About/AboutBaseTokens';
import AboutCoin from 'Views/About/AboutCoin';
// import Dapp from 'Views/Dapp/Dapp';
// import ExploreNFTS from 'Views/Explore/ExploreNFTS';
import Faq from 'Views/FAQ/Faq';
import Home from 'Views/Home/Home';
import Preview from 'Views/Preview/Preview';
import PreviewToken from 'Views/Preview/PreviewToken';
import Roadmap from 'Views/RoadMap/Roadmap';
import Team from 'Views/Team/Team';

const LandingPage = () => {
  return (
    <div>
      {/* <Suspense fallback={<Loading />}> */}
      <Home />
      <AboutCoin />
      {/* <AboutBaseTokens /> */}
      <About />
      <Roadmap />
      <Preview />
      <Team />
      <Faq />
      <Footer />
      {/* <ScrollToTop /> */}
      {/* </Suspense> */}
    </div>
  );
};

export default LandingPage;
