import { Contract, ethers, BigNumberish, ContractTransaction } from 'ethers';

export const MinterContractAddress =
  '0x90E30E8198C57C9cdbF2A7a9c5E6264c99A1791D';

export interface NFT {
  tokenId?: number;
  ipfs: MetaDataInfo;
  tier: number;
  a: number;
  b: number;
  c: number;
  collection: number;
  disintegrate?: boolean;
  vortex?: boolean;
  hidden?: boolean;
}

export type MetaDataInfo = {
  name: string;
  description?: string;
  image: string;
  external_url?: string;
  attributes?: Attributes[];
};

interface Attributes {
  trait_type: string;
  value: MetaDataAttributeValueProps;
}

export type MetaDataAttributeValueProps = string | number | boolean | null;

export interface NFTData {
  tokenId?: number;
  uriHash: string;
  tier: number;
  attributeA: number;
  attributeB: number;
  attributeC: number;
  collection: number;
  available?: boolean;
}

export interface NFTAttributes {
  tier: BigNumberish;
  attributeA: BigNumberish;
  attributeB: BigNumberish;
  attributeC: BigNumberish;
  collectionType: BigNumberish;
}

export interface Boost {
  tokenId: BigNumberish;
  targetAttribute: BigNumberish; // 0 for attributeA, 1 for attributeB, 2 for attributeC, 3 for random
  boostPoint: BigNumberish;
}

export type IMinterContract = Contract & {
  // ERC721 Standard Functions
  owner(): Promise<string>;
  balanceOf(owner: string): Promise<BigNumberish>;
  ownerOf(tokenId: BigNumberish): Promise<string>;
  safeTransferFrom(
    from: string,
    to: string,
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  safeTransferFrom(
    from: string,
    to: string,
    tokenId: BigNumberish,
    data: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  transferFrom(
    from: string,
    to: string,
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  approve(
    to: string,
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  setApprovalForAll(
    operator: string,
    approved: boolean,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  getApproved(tokenId: BigNumberish): Promise<string>;
  isApprovedForAll(owner: string, operator: string): Promise<boolean>;
  name(): Promise<string>;
  symbol(): Promise<string>;
  tokenURI(tokenId: BigNumberish): Promise<string>;
  canReveal(): Promise<boolean>;
  setReveal(reveal: boolean, overrides?: ethers.Overrides): Promise<ContractTransaction>;
  getCurrentCollection(): Promise<BigNumberish>;
  totalSupply(): Promise<BigNumberish>;
  tokenByIndex(index: BigNumberish): Promise<BigNumberish>;
  tokenOfOwnerByIndex(
    owner: string,
    index: BigNumberish,
  ): Promise<BigNumberish>;
  supportsInterface(interfaceId: string): Promise<boolean>;

  // Custom Functions
  setDistributorContractAddress(
    _distributorContractAddress: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  getNFTTier(_tokenId: BigNumberish): Promise<number>;
  getNFTCollectionType(_tokenId: BigNumberish): Promise<number>;
  addTokensToPoolByTier(
    _poolId: BigNumberish,
    _tierId: number,
    _Ids: BigNumberish[],
    _attributes: BigNumberish[][],
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  unsetPoolTier(
    _poolId: BigNumberish,
    _tierId: number,
    _tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  getNFTAttributes(tokenId: BigNumberish): Promise<NFTAttributes>;
  getBooster(tokenId: BigNumberish): Promise<Boost>;
  setBoostToken(
    tokenId: BigNumberish,
    boostAttribute: BigNumberish,
    boostPoint: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  setBoostTokens(
    tokenIds: BigNumberish[],
    boostAttributes: BigNumberish[],
    boostPoints: BigNumberish[],
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  removeBoostTokens(
    tokenIds: BigNumberish[],
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  removeBoostToken(
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  setCollectionBaseURI(
    collectionId: BigNumberish,
    _collectionbaseURI: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  reveal(
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  setBaseURI(
    _newBaseURI: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  setBaseExtension(
    _newBaseExtension: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  mintToken(
    to: string,
    tierToPurchase: BigNumberish,
    tokenId: BigNumberish,
    _defaultUriHash: string,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  walletOfOwner(_owner: string): Promise<BigNumberish[]>;
  tokenExists(tokenId: BigNumberish): Promise<boolean>;
  ownerOfToken(tokenId: BigNumberish): Promise<string>;
  isApprovedOrOwner(spender: string, tokenId: BigNumberish): Promise<boolean>;
  lockNFT(
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  unlockNFT(
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  unlockBoostToDistributor(
    tokenId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;
  withdraw(overrides?: ethers.Overrides): Promise<ContractTransaction>;

  // Public Variables (Getters)
  baseURI(overrides?: ethers.Overrides): Promise<string>;
  baseExtension(): Promise<string>;
  tiers(tokenId: BigNumberish): Promise<number>;
  attributeA(tokenId: BigNumberish): Promise<BigNumberish>;
  attributeB(tokenId: BigNumberish): Promise<BigNumberish>;
  attributeC(tokenId: BigNumberish): Promise<BigNumberish>;
  collectionType(tokenId: BigNumberish): Promise<BigNumberish>;
  defaultTokenURI(tokenId: BigNumberish): Promise<string>;
  distributor(): Promise<string>; // Returns the address of the IDistributor contract
  distributorContractAddress(): Promise<string>;
  isBoostToken(tokenId: BigNumberish): Promise<boolean>;
  lockedTokens(tokenId: BigNumberish): Promise<boolean>;
  tokenLockers(tokenId: BigNumberish): Promise<string>;
  revealed(tokenId: BigNumberish): Promise<boolean>;
  // Events
  on(
    event: 'Approval',
    listener: (owner: string, approved: string, tokenId: BigNumberish) => void,
  ): IMinterContract;
  on(
    event: 'ApprovalForAll',
    listener: (owner: string, operator: string, approved: boolean) => void,
  ): IMinterContract;
  on(
    event: 'OwnershipTransferred',
    listener: (previousOwner: string, newOwner: string) => void,
  ): IMinterContract;
  on(
    event: 'TokenLocked',
    listener: (owner: string, tokenId: BigNumberish) => void,
  ): IMinterContract;
  on(
    event: 'TokenRevealed',
    listener: (owner: string, tokenId: BigNumberish) => void,
  ): IMinterContract;
  on(
    event: 'TokenUnlocked',
    listener: (owner: string, tokenId: BigNumberish) => void,
  ): IMinterContract;
  on(
    event: 'Transfer',
    listener: (from: string, to: string, tokenId: BigNumberish) => void,
  ): IMinterContract;

  // Additional Methods (if any)
  // Add any other methods, variables, or events as needed.
};
