import {
  RaffleContractAddress,
  IRaffleContract,
  State,
} from 'types/Raffle/Raffle';
import raffle from 'contracts/Raffle.json';
import { ethers, Overrides, TransactionResponse } from 'ethers';

export type RaffleStateProps = {
  raffleId: number;
  size: number;
  bought: number;
  maxWinners: number;
  price: number;
  participants: string[];
};

export class RaffleHelper {
  private contract: IRaffleContract;
  public signer: ethers.JsonRpcSigner;

  private async initializeContract(): Promise<void> {
    if (this.contract && this.signer) {
      // Contract and signer are already initialized
      return;
    }

    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.BrowserProvider(window.ethereum);
      this.signer = await provider.getSigner();
      this.contract = new ethers.Contract(
        RaffleContractAddress,
        raffle,
        this.signer,
      ) as IRaffleContract;
    } else {
      throw new Error('Ethereum wallet is not connected');
    }
  }

  private async ensureContractReady(): Promise<void> {
    if (!this.contract || !this.signer) {
      await this.initializeContract();
    }
  }
  
  async buyRaffle(raffleId: number): Promise<ethers.TransactionResponse> {
    await this.ensureContractReady();
    const gasPrice = ethers.parseUnits('20', 'gwei'); // or any gas price you want to set

    const fee = (await this.contract.getRaffleState(raffleId)).price;
    const fee1 = ethers.formatUnits(fee, 'gwei');
    console.log('Parsed Fee => Gwei:', ethers.formatUnits(fee, 'gwei'));
    console.log('Parsed Fee => Num:', Number(fee));
    console.log('Parsed Fee => Eth:', ethers.formatEther(fee));
    console.log('FEE:', ethers.formatEther(fee));

    const overrides = {
      // value: ethers.parseUnits(fee.toString(), "gwei"),
      value: fee,
      gasPrice: gasPrice,
    };
    console.log('override fees:', overrides);

    const transaction = (await this.contract.buyRaffle(
      raffleId,
      overrides,
    )) as TransactionResponse;

    console.log('transaction', transaction);

    const receipt = await transaction.wait();
    const confirmations = await receipt.confirmations();
    console.log('Transaction:', transaction);
    console.log('receipt:', receipt);
    console.log('confirmations:', confirmations);

    return transaction;
  }

  async claimNFT(overrides?: Overrides): Promise<boolean> {
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    await this.ensureContractReady();
    const tx = await this.contract.claimNFT();
    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Claim set successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async getRaffleState(id: number): Promise<RaffleStateProps> {
    await this.ensureContractReady();

    const state: State = await this.contract.getRaffleState(id);

    let participants: string[] = [];

    for (let i = 0; i < Number(state.size); i++) {
      const participant = await this.contract.raffles(id, i);

      if (participant !== '0x0000000000000000000000000000000000000000') {
        participants.push(participant);
      }
    }

    return {
      raffleId: Number(state.raffleId),
      bought: Number(state.bought), // state.bought should be a BigNumber
      size: Number(state.size), // state.size should be a BigNumber
      price: Number(ethers.formatUnits(state.price, 'gwei')), // formatting price in gwei
      participants: participants, // assuming participants is a list or a simple value
      maxWinners: Number(state.maxWinners), // state.maxWinners should be a BigNumber
    };
  }

  async getAllRaffles(): Promise<RaffleStateProps[]> {
    try {
      await this.ensureContractReady();

      const numberOfRaffles = await this.contract.numberOfRaffles();

      let raffles: RaffleStateProps[] = [];

      for (let i = 0; i < Number(numberOfRaffles); i++) {
        const raffle = await this.getRaffleState(i);

        if (raffle) {
          raffles.push(raffle);
        }
      }

      console.log('#Raffles:', raffles);

      return raffles;
    } catch (error) {
      console.log('Failed to get Raffles:', error);
    }
    return [];
  }
}

export default RaffleHelper;
