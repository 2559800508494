import abi from 'contracts/Battle.json';
import { ethers, BigNumberish, Overrides } from 'ethers';
import {
  BattleContractAddress,
  Entrant,
  GameDetails,
  IBattleContract,
} from 'types/NFT/Battle';
import { MinterHelper } from './MinterContractHelper';
import { error } from 'console';

class BattleHelper {
  private contract: IBattleContract;
  public signer: ethers.Signer;

  // // State Properties
  // Entrant: Entrant[] = [];

  // nftcontract: string;
  // nft_queue: Entrant[] = [];
  // crypto_queue: Entrant[] = [];

  // nft_q_positions: Record<string, number> = {};
  // crypto_q_positions: Record<string, number> = {};

  //currIndex: number = 0;

  private async initializeContract(): Promise<void> {
    if (this.contract && this.signer) {
      // Contract and signer are already initialized
      return;
    }

    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.BrowserProvider(window.ethereum);
      this.signer = await provider.getSigner();
      this.contract = new ethers.Contract(
        BattleContractAddress,
        abi,
        this.signer,
      ) as unknown as IBattleContract;
    } else {
      throw new Error('Ethereum wallet is not connected');
    }
  }

  private async ensureContractReady(): Promise<void> {
    if (!this.contract || !this.signer) {
      await this.initializeContract();
    }
  }

  async createGameMode(
    participantSize: BigNumberish,
    gameFee: BigNumberish,
    cardsPerPlayer: BigNumberish,
    canUseBoost: boolean,
    maxBoostersPerCard: BigNumberish,
    gameIPFS: string,
    attributeGuessOption: boolean,
    guessBonus: BigNumberish,
    tier: BigNumberish,
    pvc: boolean,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
  ): Promise<boolean> {
    try {
      await this.ensureContractReady();
      if (!overrides) {
        overrides = {
          gasLimit: 30000000,
        };
      }

      const tx = await this.contract.createGameMode(
        participantSize,
        gameFee,
        cardsPerPlayer,
        canUseBoost,
        maxBoostersPerCard,
        gameIPFS,
        attributeGuessOption,
        guessBonus,
        tier,
        pvc,
        overrides,
      );

      console.log(`tx: ${tx}`);
      const receipt = await (tx as any).wait();
      if (receipt.status === 1) {
        console.log('Game added successfully');
        return true;
      }

      console.error('Transaction failed');
      return false;
    } catch (error) {
      console.log(`Failed to createGameMode \n Error: ${error}`);
    }
    return false;
  }

  async updateGameMode(
    gameId: BigNumberish,
    participantSize: BigNumberish,
    gameFee: BigNumberish,
    cardsPerPlayer: BigNumberish,
    canUseBoost: boolean,
    maxBoostersPerCard: BigNumberish,
    gameIPFS: string,
    attributeGuessOption: BigNumberish,
    guessBonus: BigNumberish,
    tier: BigNumberish,
    pvc: boolean,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
  ): Promise<boolean> {
    try {
      await this.ensureContractReady();
      if (!overrides) {
        overrides = {
          gasLimit: 30000000,
        };
      }

      const tx = await this.contract.updateGameMode(
        gameId,
        participantSize,
        gameFee,
        cardsPerPlayer,
        canUseBoost,
        maxBoostersPerCard,
        gameIPFS,
        attributeGuessOption,
        guessBonus,
        tier,
        pvc,
        overrides,
      );

      const receipt = await (tx as any).wait();
      if (receipt.status === 1) {
        console.log('Tokens added successfully');
        return true;
      }

      return false;
    } catch (error) {
      console.log(`Failed to updateGameMode \n Error: ${error}`);
    }
    return false;
  }

  // Enter NFT Game Method
  async enterComputerGame(
    gameId: BigNumberish,
    tokenIds: BigNumberish[],
    boostTokens: BigNumberish[][],
    guessedAttribute: BigNumberish,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
  ): Promise<boolean> {
    await this.ensureContractReady();

    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
        value: ethers.parseEther(
          (await this.contract.gameModes(gameId)).gameFee.toString(),
        ),
      };
    }

    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    const tx = await this.contract.enterComputerGame(
      gameId,
      tokenIds,
      boostTokens,
      guessedAttribute,
      overrides,
    );

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Distributor set successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  // Enter Crypto Game Method
  async enterGame(
    gameId: BigNumberish,
    tokenIds: BigNumberish[],
    boostTokens: BigNumberish[][],
    guessedAttribute: BigNumberish,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
  ): Promise<boolean> {
    try {
      await this.ensureContractReady();

      if (!overrides) {
        const gameDetails = await this.contract.gameModes(gameId);
        overrides = {
          gasLimit: 30000000,
          value: gameDetails.gameFee,
        };
      }

      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const from = accounts[0];

      const tx = await this.contract.enterGame(
        gameId,
        tokenIds,
        boostTokens,
        guessedAttribute,
        overrides,
      );

      const receipt = await (tx as any).wait();
      if (receipt.status === 1) {
        console.log('Distributor set successfully');
        return true;
      }
    } catch (error) {
      console.error('Error while entering the game:', error);
    }

    console.error('Transaction failed');
    return false;
  }

  // Claim NFTs Method
  async claimNFTs(
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
  ): Promise<boolean> {
    if (!overrides) {
      overrides = {
        gasLimit: 30000000,
      };
    }

    await this.ensureContractReady();

    const tx = await this.contract.claimNFTs(overrides);

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Distributor set successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  // Change NFT Contract Address
  async changeNFTContract(newContractAddress: string): Promise<boolean> {
    await this.ensureContractReady();

    const tx = await this.contract.setMinterContract(newContractAddress);

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  async changeNFTDistroContract(newContractAddress: string): Promise<boolean> {
    await this.ensureContractReady();

    const tx = await this.contract.setDistributorContract(newContractAddress);

    const receipt = await (tx as any).wait();
    if (receipt.status === 1) {
      console.log('Tokens added successfully');
      return true;
    }

    console.error('Transaction failed');
    return false;
  }

  // Get NFT Queue
  async getNftQueue(gameId: BigNumberish): Promise<Entrant[]> {
    await this.ensureContractReady();

    return await this.contract.gameQueues(gameId);

    // const queueCount = Number(await this.contract.gameQueueCounts(gameId));
    // let queue: Entrant[] = [];

    // for (let i = 0; i < queueCount; i++) {
    //   const entrant = await this.contract.gameQueues(gameId);
    //   queue.push(entrant);
    // }

    // return queue;
  }

  // Get Leaderboard
  async getLeaderBoard(): Promise<{ address: string; wins: BigNumberish }[]> {
    await this.ensureContractReady();

    // Assuming you have a list of player addresses tracked in the smart contract
    const players: string[] = []; // Retrieve the list of players

    let leaderboard: { address: string; wins: BigNumberish }[] = [];
    for (const player of players) {
      const wins = await this.contract.winsPerPlayer(player);
      leaderboard.push({ address: player, wins });
    }

    // Sorting players by wins in descending order (optional)
    leaderboard.sort((a, b) => (b.wins as any) - (a.wins as any));

    return leaderboard;
  }

  async getDistributor(): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.distributor();
  }

  async getMinter(): Promise<string> {
    await this.ensureContractReady();
    return await this.contract.distributor();
  }

  async getTotalGameNumber(): Promise<Number> {
    await this.ensureContractReady();
    return Number(await this.contract.nextGameId());
  }

  // Utility: Get Game Details
  async getGameDetails(gameId: BigNumberish): Promise<GameDetails> {
    await this.ensureContractReady();
    const gameDetails = await this.contract.gameModes(gameId);

    // if (gameDetails) {
    //   return {
    //     gameId: Number(gameDetails.gameId),
    //     participantSize: Number(gameDetails.participantSize),
    //     gameFee: `${gameDetails.gameFee}`,
    //     cardsPerPlayer: Number(gameDetails.cardsPerPlayer),
    //     canUseBoost: gameDetails.canUseBoost,
    //     maxBoostersPerCard: Number(gameDetails.maxBoostersPerCard),
    //     gameIPFS: gameDetails.gameIPFS,
    //     attributeGuessOption: gameDetails.attributeGuessOption,
    //     guessBonus: Number(gameDetails.guessBonus),
    //     tier: Number(gameDetails.tier),
    //   };
    // }
    return gameDetails;
  }

  // async playerExists(player: string): Promise<boolean> {
  //   await this.ensureContractReady();
  //   return await this.contract.playerExists(player);
  // }

  async withdrawFromGame(
    gameId: BigNumberish,
    overrides?: Overrides & {
      value?: ethers.BigNumberish;
      gasLimit?: ethers.BigNumberish;
      gasPrice?: ethers.BigNumberish;
    },
  ): Promise<boolean> {
    try {
      await this.ensureContractReady();
      if (!overrides) {
        overrides = {
          gasLimit: 30000000,
        };
      }

      const tx = await this.contract.withdrawFromGame(
        gameId,
        overrides,
      );

      console.log(`tx: ${tx}`);
      const receipt = await (tx as any).wait();
      if (receipt.status === 1) {
        console.log('Game withdrawn successfully');
        return true;
      }

      console.error('Transaction failed');
      return false;
    } catch (error) {
      console.log(`Failed to withdraw from Game \n Error: ${error}`);
    }
    return false;
  }

  async getNumberOfPlayers(gameId: BigNumberish): Promise<number> {
    await this.ensureContractReady();
    return Number(await this.contract.players(gameId));
  }

  async getAllGameDetails(): Promise<GameDetails[]> {
    await this.ensureContractReady();

    try {
      const numberOfGames = Number(await this.contract.nextGameId());

      if (!numberOfGames || numberOfGames === 0) {
        return [];
      }

      const minter = new MinterHelper();

      const defaultipfs = await minter.baseURI();

      if (!defaultipfs) throw new Error('failed to fetch default URI');

      let gameDetails: GameDetails[] = [];
      for (let i = 1; i <= numberOfGames; i++) {
        try {
          console.log(`fetching Games i: ${i}`);
          const gameDetail = await this.getGameDetails(i);
          console.log('gameDetail:', gameDetail);
          const extractedGameDetail: GameDetails = {
            gameId: gameDetail[0],
            participantSize: gameDetail[1],
            gameFee: gameDetail[2],
            cardsPerPlayer: gameDetail[3],
            canUseBoost: gameDetail[4],
            maxBoostersPerCard: gameDetail[5],
            gameIPFS: gameDetail[6],
            attributeGuessOption: gameDetail[7],
            guessBonus: gameDetail[8],
            tier: gameDetail[9],
            PVC: gameDetail[10],
          };

          if (extractedGameDetail) {
            // You can then apply the IPFS URL and push it to the array
            extractedGameDetail.gameIPFS = `${defaultipfs}${extractedGameDetail.gameIPFS}`;
            console.log('extractedGameDetail:', extractedGameDetail);

            gameDetails.push(extractedGameDetail);
          }
        } catch (err) {
          console.warn(`Error fetching details for game ID ${i}: ${err}`);
        }
      }

      return gameDetails;
    } catch (error) {
      console.log(`Error Fetching GameDetails, \n Error: ${error}`);
    }
    return [];
  }

  // Utility: Get Player Wins
  async getPlayerWins(playerAddress: string): Promise<BigNumberish> {
    await this.ensureContractReady();
    return await this.contract.winsPerPlayer(playerAddress);
  }
}

export default BattleHelper;
