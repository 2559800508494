import { BigNumberish, ContractTransaction, ethers } from 'ethers';

export const BattleContractAddress =
  '0x7027e4D98D6624aA8C1f2b2846a12764Ef5cE53B';

export interface GameDetails {
  gameId: BigNumberish;
  participantSize: BigNumberish;
  gameFee: BigNumberish;
  cardsPerPlayer: Number;
  canUseBoost: boolean;
  maxBoostersPerCard: Number;
  gameIPFS: string;
  attributeGuessOption: boolean;
  guessBonus: Number;
  tier: Number;
  PVC: boolean;
}

export interface Entrant {
  player: string;
  tokenIds: BigNumberish[];
  boosts: BigNumberish[][]; // Each NFT can have multiple boosts
  bet: BigNumberish;
  guessedAttribute: BigNumberish;
}

export interface Battle {
  winner: string;
  loser: string;
  winningTokenId: BigNumberish;
  losingTokenId: BigNumberish;
  attributeUsed: BigNumberish; // The attribute used for the battle (A/B/C)
}
export interface IBattleContract {
  claimNFTs(overrides?: ethers.Overrides): Promise<ContractTransaction>;

  // Functions
  createGameMode(
    participantSize: BigNumberish,
    gameFee: BigNumberish,
    cardsPerPlayer: BigNumberish,
    canUseBoost: boolean,
    maxBoostersPerCard: BigNumberish,
    gameIPFS: string,
    attributeGuessOption: boolean,
    guessBonus: BigNumberish,
    tier: BigNumberish,
    pve: boolean,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;

  updateGameMode(
    gameId: BigNumberish,
    participantSize: BigNumberish,
    gameFee: BigNumberish,
    cardsPerPlayer: BigNumberish,
    canUseBoost: boolean,
    maxBoostersPerCard: BigNumberish,
    gameIPFS: string,
    attributeGuessOption: BigNumberish,
    guessBonus: BigNumberish,
    tier: BigNumberish,
    pve: boolean,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;

  enterComputerGame(
    gameId: BigNumberish,
    playerTokenIds: BigNumberish[],
    playerBoostTokens: BigNumberish[][],
    attributeGuess: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;

  enterGame(
    gameId: BigNumberish,
    tokenIds: BigNumberish[],
    boostTokens: BigNumberish[][],
    guessedAttribute: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;

  setDistributorContract(newDistributor: string): Promise<ContractTransaction>;

  setMinterContract(newMinter: string): Promise<ContractTransaction>;

  // Variables (Getters)
  battles(gameId: BigNumberish): Promise<Battle[]>;

  distributor(): Promise<string>;

  gameModes(gameId: BigNumberish): Promise<GameDetails>;

  gameQueueCounts(gameId: BigNumberish): Promise<BigNumberish>;

  gameQueues(gameId: BigNumberish): Promise<Entrant[]>;

  minter(): Promise<string>;

  nextGameId(): Promise<BigNumberish>;

  owner(): Promise<string>;

  withdrawFromGame(
    gameId: BigNumberish,
    overrides?: ethers.Overrides,
  ): Promise<ContractTransaction>;

  // playerExists(player: string): Promise<boolean>; // Getter for the `playerExists` mapping

  players(index: BigNumberish): Promise<string>; // Getter for the `players` array

  winsPerPlayer(player: string): Promise<BigNumberish>;

  // Events
  on(
    event: 'GameCreated',
    listener: (gameId: BigNumberish, gameIPFS: string) => void,
  ): void;
  on(
    event: 'GameUpdated',
    listener: (gameId: BigNumberish, gameIPFS: string) => void,
  ): void;
  on(
    event: 'PlayerEnteredGame',
    listener: (gameId: BigNumberish, player: string) => void,
  ): void;
  on(event: 'GameStarted', listener: (gameId: BigNumberish) => void): void;
  on(
    event: 'BattleCompleted',
    listener: (
      gameId: BigNumberish,
      winner: string,
      loser: string,
      winningTokenId: BigNumberish,
      losingTokenId: BigNumberish,
    ) => void,
  ): void;
  on(
    event: 'PlayerWithdrawnFromGame',
    listener: (gameId: BigNumberish, sender: string) => void,
  ): void;
}
