import React, { useEffect, useState } from 'react';
import './EnterPage.scss';
import logo from 'assets/logos/P2PLogo.png';
import OnChainOffChainSelection from './OnChainOffChainSelection';
import ChooseGameType from './ChooseGameType';
import GameSelection from './GameSelection';
import CardSelection, { SelectedGameCard } from './CardSelection';
import BattleHelper from 'Helpers/BattleHelper';
import { GameDetails } from 'types/NFT/Battle';
import { MinterHelper } from 'Helpers/MinterContractHelper';
import { Boost, NFT } from 'types/NFT/Minter';
import { BoostTokens, NFTS } from 'components/Card/P2PCard/NFTs';
import toast from 'react-hot-toast';

export enum GameType {
  pvp = 'Player Vs Player',
  pvc = 'Player Vs computer',
}

export enum Mode {
  onChain = 'Online Contract Mode',
  offChain = 'Ofline Mode',
}

const EnterPage: React.FC = () => {
  enum Page {
    enter = 'enter',
    onChainOffChain = 'onChainOffChain',
    chooseGameType = 'chooseGameType',
    gameSelection = 'gameSelection',
    cardSelection = 'cardSelection',
  }

  // State to control the different views
  const [currentView, setCurrentView] = useState<{
    mode?: Mode;
    page: Page;
    gameType?: GameType;
    gameDetails?: GameDetails;
  }>({
    page: Page.enter,
  });

  const [gameDetails, setGameDetails] = useState<GameDetails[]>([]);
  const [playerCards, setPlayerCards] = useState<NFT[]>([]);
  const [playerBoostCards, setPlayerBoostCards] = useState<Boost[]>([]);

  // Dummy cards data to be passed to CardSelection component
  // Dummy cards data to be passed to CardSelection component

  const fetchGameModes = async () => {
    try {
      const contract = new BattleHelper();
      const gameModesFromContract = await contract.getAllGameDetails();

      setGameDetails(gameModesFromContract);
    } catch (error) {
      console.error('Error fetching game modes from contract:', error);
    }
  };

  const fetchPlayerCards = async () => {
    try {
      const contract = new MinterHelper();
      const userTokens = await contract.getMyPlayableTokens();

      setPlayerCards(userTokens);

      if (userTokens.length === 0 || !userTokens) {
        setPlayerCards(NFTS);
      }
    } catch (error) {
      console.error('Error fetching game modes from contract:', error);
    }
  };

  const fetchPlayerBoostCards = async () => {
    try {
      const contract = new MinterHelper();
      const userTokens = await contract.getMyBoostTokens();

      setPlayerBoostCards(userTokens);
      if (userTokens.length === 0 || !userTokens) {
        setPlayerBoostCards(BoostTokens);
      }
    } catch (error) {
      console.error('Error fetching game modes from contract:', error);
    }
  };

  const handlePlay = async (gameCards: SelectedGameCard[]) => {
    // Ensure there is a valid game in the current view
    if (!currentView.gameDetails) {
      console.error('Game details are not available.');
      return;
    }

    const contract = new BattleHelper();

    // Ensure the number of selected cards matches the required number of cards per player
    if (Number(currentView.gameDetails.cardsPerPlayer) !== gameCards.length) {
      toast.error(
        'Please select the correct number of cards to play the game.',
      );
      return;
    }

    try {
      // Extract card IDs and booster tokens from the selected game cards
      const tokenIds = gameCards.map((c) => c.card.id);
      const boostTokens = gameCards.map((c) => c.boosters || []);
      const guessedAttribute = gameCards[0].guess ?? 0; // Default guess if not set

      // Log for debugging
      console.log('Attempting to enter game with the following parameters:', {
        gameId: currentView.gameDetails.gameId,
        tokenIds,
        boostTokens,
        guessedAttribute,
      });

      // Execute the enter game contract function
      const playTransaction = await contract.enterGame(
        currentView.gameDetails.gameId,
        tokenIds,
        boostTokens,
        guessedAttribute,
      );

      if (playTransaction) {
        toast.success('Successfully entered the game!');
        console.log('Transaction details:', playTransaction);

        // Remove used cards and boosters from playerCards and playerBoostCards
        setPlayerCards((prevPlayerCards) =>
          prevPlayerCards.filter((card) => !tokenIds.includes(card.tokenId)),
        );

        setPlayerBoostCards((prevPlayerBoostCards) =>
          prevPlayerBoostCards.filter(
            (boost) => !boostTokens.flat().includes(Number(boost.tokenId)),
          ),
        );
      }
    } catch (error) {
      console.error('Error while entering the game:', error);
      toast.error('Failed to enter the game. Please try again.');
    }
  };

  useEffect(() => {
    switch (currentView.mode) {
      case Mode.onChain:
        console.log('im on chain');
        (async () => {
          await fetchGameModes();
          await fetchPlayerCards();
          await fetchPlayerBoostCards();
        })();
        break;
      default:
        break;
    }
  }, [currentView]);

  // Render Different Sections Based on State
  return (
    <div className="enter-game-app">
      {/* Enter Page Section */}
      {currentView.page === 'enter' && (
        <div className="enter-page">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <h1 className="title">P2P ART GAMING</h1>
          <button
            className="enter-button"
            onClick={() => setCurrentView({ page: Page.onChainOffChain })}
          >
            ENTER GAME
          </button>
        </div>
      )}

      {/* OnChain/OffChain Selection Section */}
      {currentView.page === 'onChainOffChain' && (
        <OnChainOffChainSelection
          onModeSelect={(mode) =>
            setCurrentView({ mode, page: Page.chooseGameType })
          }
          onBack={() => setCurrentView({ page: Page.enter })}
        />
      )}

      {/* Choose Game Type Section */}
      {currentView.page === 'chooseGameType' && (
        <ChooseGameType
          onGameTypeSelect={(gameType) =>
            setCurrentView({
              ...currentView,
              page: Page.gameSelection,
              gameType,
            })
          }
          onBack={() => setCurrentView({ page: Page.onChainOffChain })}
        />
      )}

      {/* Game Selection Section */}
      {currentView.page === 'gameSelection' && (
        <GameSelection
          onLogoClick={() => setCurrentView({ page: Page.enter })}
          onGameSelect={(gameDetails: GameDetails) =>
            setCurrentView({
              ...currentView,
              page: Page.cardSelection,
              gameDetails,
            })
          }
          gameDetails={gameDetails}
        />
      )}

      {/* Card Selection Section */}
      {currentView.page === 'cardSelection' && (
        <CardSelection
          nftCards={playerCards}
          nftBoostCards={playerBoostCards}
          gameMode={currentView.gameDetails} // Pass the game details to card selection
          onCardSelect={handlePlay}
          onBack={() => setCurrentView({ page: Page.gameSelection })}
        />
      )}
    </div>
  );
};

export default EnterPage;
