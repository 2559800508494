import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import '../node_modules/normalize.css/normalize.css';
import { BrowserRouter } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';
import { config } from 'Helpers/Paymaster/config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import other providers like alchemyProvider, infuraProvider if needed

// ReactDOM.render(
//   <React.StrictMode>
//     <WagmiProvider config={paymasterClient}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </WagmiProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
