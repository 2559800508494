import { Container, Section } from './styledTags';
import Tier4 from 'assets/Tierage/tier4.png';
import Slider, { DataItem } from 'components/Carousel/Slider';
// import addvert from "assets/Video/Addvert.mp4";
import Coin from 'assets/Game/P2PCoin.gif';
import ContractAddress from './components/ContractAddress';

const addvert = require('../../assets/Video/Coin.mp4');

const AboutCoin = () => {
  //carousol slider data test
  const data: DataItem[] = [
    {
      name: 'P2P Coin',
      information: {
        Created: '',
        Dimensions: '',
        Resolution: '',
        ColorSpace: '',
        ColorProfile: '',
      },
      description: 'CHBAgT33oXjxYeUvts3EDd4xMYAAt47PLyy921V3pump',
      descriptionRedenderFrag: ContractAddress,
      facts: [
        {
          header: `Buy Now`,
          topFact: 'Raydium',
          bottomFact: '->',
          link: 'https://raydium.io/swap/?outputMint=B6XSurUUX3rtMS2YcScyXjRRVGEWLPYE5Y99UyS74994&inputMint=sol',
        },
        {
          header: `Go To`,
          topFact: 'Dex Screener',
          bottomFact: '->',
          link: 'https://dexscreener.com/solana/enm3ezmlyyhb62h58bzf6gcbw8thhuxlzx6djrkam3pk',
        },
      ],
      photo: Coin,
      video: addvert,
      slider: Tier4,
      renderFrag: null,
    },
  ];

  return (
    <Section id="about">
      <Container>
        {/* <Box>
          <Suspense fallback={<Loading />}>
            <Carousel />
          </Suspense>
        </Box> */}
        {/* <SelectionPage {...selections} /> */}
        <Slider data={data} sliderClass="timecomp" contentClass="fullscreen" />
        {/* <Carousel/> */}
      </Container>
    </Section>
  );
};

export default AboutCoin;
