import React, { useState, useEffect, useRef, useCallback } from 'react';
import './exploreP2PCards.scss';
import { Card, mapNFT } from 'Views/Dapp/Components/Game/CardSelection';
import { MinterHelper } from 'Helpers/MinterContractHelper';
import P2PCard from 'components/Card/P2PCard/P2PCard';
import Search from 'components/Card/P2PCard/Search';
import { Boost, NFT } from 'types/NFT/Minter';
import { BoostTokens, NFTS } from 'components/Card/P2PCard/NFTs';
import { groupBoosters } from 'Views/Dapp/Components/Game/BoosterSelection';
import P2PBooster, {
  getBoostType,
} from 'components/Card/P2PBooster/P2PBooster';

const ExploreP2PCards: React.FC = () => {
  // const [cards, setCards] = useState<Card[]>([]);
  const [filteredCards, setFilteredCards] = useState<Card[]>([]);
  const [filteredBoosters, setFilteredBoosters] =
    useState<Boost[]>(BoostTokens);
  const [NFTs, setNFTs] = useState<NFT[]>(NFTS);
  const [boosters, setBoosters] = useState<Boost[]>(BoostTokens);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);

  const [showCards, setShowCards] = useState(true);
  const [showBoosters, setShowBoosters] = useState(true);

  const previousPositionRef = useRef({ x: 0, y: 0 });

  const modalRef = useRef<HTMLDivElement | null>(null);
  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const rotatableCardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Mock data to populate cards
    (async () => {
      setLoading(true);
      try {
        const contract = new MinterHelper();
        //const nfts = await contract.getMyPlayableTokens();
        const nfts = NFTS;
        const nftBoosters = await contract.getMyBoostTokens();

        if (nftBoosters && nftBoosters.length > 0) {
          setBoosters(nftBoosters);
        }
        if (nfts && nfts.length > 0) {
          setNFTs(nfts);
          const parsedCards = mapNFT(nfts);
          setFilteredCards(parsedCards);
          // setCards(parsedCards);
        }
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setLoading(false);
      }
    })();

    if (NFTs.length > 0) setLoading(false);
  }, []);

  const toggleCardsVisibility = () => setShowCards((prev) => !prev);
  const toggleBoostersVisibility = () => setShowBoosters((prev) => !prev);

  const toggleHighlight = (event: React.MouseEvent<HTMLTableCellElement>) => {
    const target = event.currentTarget;
    target.classList.toggle('highlighted');
  };

  const openModal = (card: Card) => {
    setSelectedCard(card);
    if (modalRef.current) {
      modalRef.current.style.display = 'block';
    }
    lockScroll(); // Prevent background scroll
  };

  const closeModal = () => {
    setSelectedCard(null);
    if (modalRef.current) {
      modalRef.current.style.display = 'none';
    }
    unlockScroll(); // Restore background scroll
  };

  const lockScroll = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    cardContainerRef.current.style.overflowY = 'hidden';
  };

  const unlockScroll = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.overflow = 'auto';
    cardContainerRef.current.style.overflowY = 'auto';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  const handleFilter = (nfts: (NFT | Boost)[]) => {
    setFilteredCards(mapNFT(nfts as NFT[]));
  };

  const startDrag = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setIsDragging(true);
    const clientX =
      'touches' in event ? event.touches[0].clientX : event.clientX;
    const clientY =
      'touches' in event ? event.touches[0].clientY : event.clientY;
    //setPreviousPosition({ x: clientX, y: clientY });
    previousPositionRef.current = { x: clientX, y: clientY };
  };

  const handleDragging = (event: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;

    requestAnimationFrame(() => {
      const clientX =
        'touches' in event ? event.touches[0].clientX : event.clientX;
      const clientY =
        'touches' in event ? event.touches[0].clientY : event.clientY;

      const deltaX = clientX - previousPositionRef.current.x;
      const deltaY = clientY - previousPositionRef.current.y;

      setRotation((prevRotation) => ({
        x: prevRotation.x - deltaY * 0.3,
        y: prevRotation.y + deltaX * 0.3,
      }));

      previousPositionRef.current = { x: clientX, y: clientY };
    });
  };
  const endDrag = () => {
    setIsDragging(false);
  };

  return (
    <div id="ExploreP2PCards">
      {loading ? (
        <div className="loader">
          <i className="fas fa-spinner fa-spin"></i> Loading NFTs...
        </div>
      ) : (
        <>
          {/* Search Bar */}
          <Search nfts={NFTs} onNFTFiltered={handleFilter} />
          {/* Toggle Visibility Buttons */}
          <div className="visibility-toggle-buttons">
            <button onClick={toggleCardsVisibility} className="toggle-button">
              {showCards ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
              <span>Cards</span>
              {/* Count Badge */}
              <span className="item-count">{filteredCards.length}</span>
            </button>
            <button
              onClick={toggleBoostersVisibility}
              className="toggle-button"
            >
              {showBoosters ? (
                <i className="fa-solid fa-eye-slash"></i>
              ) : (
                <i className="fa-solid fa-eye"></i>
              )}
              <span>Boosters</span>
              {/* Count Badge */}
              <span className="item-count">{filteredBoosters.length}</span>
            </button>
          </div>
          <div className="perspective-container" ref={cardContainerRef}>
            {/* Conditionally Render Card Collection */}
            {showCards && (
              <div className="card-collection">
                {filteredCards.map((card) => (
                  <div
                    key={card.id}
                    className="card-container"
                    onClick={() => openModal(card)}
                  >
                    <P2PCard card={card} />
                  </div>
                ))}
              </div>
            )}

            {/* Booster Selection Section */}
            {showBoosters && (
              <section className="booster-selection-section">
                <h2 className="section-header">
                  <i className="fas fa-rocket me-2"></i> AVAILABLE BOOSTERS
                </h2>
                <div className="booster-deck">
                  {Object.entries(groupBoosters(boosters)).map(
                    ([groupKey, { boost, quantity }]) => {
                      const boostType = getBoostType(
                        Number(boost.targetAttribute),
                      );
                      return (
                        <P2PBooster
                          key={groupKey}
                          booster={boost}
                          isSelected={false}
                          availableQuantity={quantity}
                          onClick={() => console.log(`boost: ${boost}`)}
                        />
                      );
                    },
                  )}
                </div>
              </section>
            )}
          </div>
        </>
      )}

      {/* Modal for Full Screen Card Display */}
      <div id="modal" ref={modalRef} onClick={closeModal}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <input
            className="close"
            type="button"
            value="Exit"
            onClick={closeModal}
          />

          {selectedCard && (
            <div
              className="rotatable-card-container P2PCard"
              onMouseDown={startDrag}
              onMouseMove={handleDragging}
              onMouseUp={endDrag}
              onMouseLeave={endDrag}
              onTouchStart={startDrag}
              onTouchMove={handleDragging}
              onTouchEnd={endDrag}
            >
              <div
                className="rotatable-card"
                ref={rotatableCardRef}
                style={{
                  transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
                }}
              >
                <div
                  className={`p2pcard TIER${selectedCard.tier.replace(/\D/g, '')[0]}`}
                >
                  {/* Front Side of Card */}
                  <div className="card-face card-front">
                    <div className="image-name-container">
                      <div className="image-container">
                        <img src={selectedCard.imageUrl} alt="Fighter Image" />
                      </div>
                      <div className="name-container">
                        <div className="fighter-name">{selectedCard.title}</div>
                        {selectedCard.opponent && (
                          <>
                            {' '}
                            <div className="vs">VS</div>
                            <div className="opponent-name">
                              {selectedCard.opponent}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="stats-table">
                      <table>
                        <tbody>
                          <tr>
                            <td className="stat-box" onClick={toggleHighlight}>
                              Win/Loss %
                            </td>
                            <td className="stat-box" onClick={toggleHighlight}>
                              {selectedCard.stats.A}
                            </td>
                          </tr>
                          <tr>
                            <td className="stat-box" onClick={toggleHighlight}>
                              Championships
                            </td>
                            <td className="stat-box" onClick={toggleHighlight}>
                              {selectedCard.stats.B}
                            </td>
                          </tr>
                          <tr>
                            <td className="stat-box" onClick={toggleHighlight}>
                              Win Streak
                            </td>
                            <td className="stat-box" onClick={toggleHighlight}>
                              {selectedCard.stats.C}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
                      <div className={`rarity ${selectedCard.rarity}`}>
                        {selectedCard.rarity}
                      </div>
                      <div
                        className={`tier ${selectedCard.tier.replace(/\s/g, '')}`}
                      >
                        {selectedCard.tier}
                      </div>
                    </div>
                  </div>
                  {/* Back Side of Card */}
                  <div className="card-face card-back">
                    <div className="back-content"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExploreP2PCards;

// [
//   {
//     id: 1,
//     imageUrl:
//       'https://ipfs.io/ipfs/QmQjS5iMKZN95HuUsSJ39QsVXKwQa4zNSkVr42EC2aDgtu',
//     title: 'Anthony Joshua',
//     opponent: 'Wladimir Klitschko',
//     stats: {
//       A: 85,
//       B: 5,
//       C: 7,
//     },
//     rarity: 'ULTRA RARE',
//     tier: 'TIER 1',
//   },
//   {
//     id: 2,
//     imageUrl:
//       'https://ipfs.io/ipfs/Qmct4zLMAJBMxwEtEwN5NzqaadjctbSFCqdAbDgXe19fXU',
//     title: 'Mike Tyson',
//     opponent: 'Lou Savarese',
//     stats: {
//       A: 90,
//       B: 8,
//       C: 12,
//     },
//     rarity: 'VERY RARE',
//     tier: 'TIER 2',
//   },
//   {
//     id: 3,
//     imageUrl:
//       'https://ipfs.io/ipfs/QmRgNq5Sk8VkNcD9ZmfxEPjTGJv7AB7zoxLYVdyACjKZ5g',
//     title: 'Lennox Lewis',
//     opponent: 'Francois Botha',
//     stats: {
//       A: 82,
//       B: 6,
//       C: 9,
//     },
//     rarity: 'STANDARD RARE',
//     tier: 'TIER 3',
//   },
//   {
//     id: 4,
//     imageUrl:
//       'https://ipfs.io/ipfs/QmSNCWK1h7s1HFQpnvvtGK5aLfyo5WqsgAw9dRtCtat71n',
//     title: 'MUHAMMAD ALI',
//     opponent: 'Boxing Genesis',
//     stats: {
//       A: 82,
//       B: 6,
//       C: 9,
//     },
//     rarity: 'COMMON',
//     tier: 'TIER 3',
//   },
//   {
//     id: 5,
//     imageUrl:
//       'https://ipfs.io/ipfs/QmSNCWK1h7s1HFQpnvvtGK5aLfyo5WqsgAw9dRtCtat71n',
//     title: 'MUHAMMAD ALI',
//     opponent: 'Boxing Genesis',
//     stats: {
//       A: 82,
//       B: 6,
//       C: 9,
//     },
//     rarity: 'STANDARD RARE',
//     tier: 'TIER 4',
//   },
// ]
